import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		activeLayers: Select.unhab.layers.getMapLayersStateEnhanced(
			state,
			ownProps.mapKey,
		),
		activeLayerNames: Select.unhab.layers.getMapActiveLayerNames(
			ownProps.mapKey,
		),
		activeAppView: Select.views.getActive(state)?.data?.nameInternal,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
