import {
	createStore,
	combineReducers,
	applyMiddleware,
	compose,
	thunk,
	reduxBatch,
	logger,
	activeMetadataActions,
} from '@gisatcz/ptr-state';
import {createBrowserHistory, createMemoryHistory} from 'history';
import {init as initApp} from '../app';
import router from './router/reducers';
import xCubeLayers from './xCubeLayers/reducers';
import xCubeFeatures from './xCubeFeatures/reducers';
import xCubeTimeSeries from './xCubeTimeSeries/reducers';
import configuration from './unhab/configuration/reducers';
import datasets from './unhab/datasets/reducers';
import datasetSets from './unhab/datasetSets/reducers';
import features from './unhab/features/reducers';
import admStructure from './unhab/admStructure/reducers';
import benchmarkComparisonTypes from './unhab/benchmarkComparisonTypes/reducers';
import {
	createRequestCounter,
	createAsyncMiddleware,
	isServer,
} from '@gisatcz/ptr-core';

// base types
import {baseStores} from '@gisatcz/ptr-state';

export const history = isServer
	? createMemoryHistory()
	: createBrowserHistory();

function createMiddleware(requestCounter) {
	const enhancedThunk = thunk.withExtraArgument(activeMetadataActions);

	const middlewares = [
		createAsyncMiddleware(requestCounter),
		enhancedThunk,
		process.env.NODE_ENV === 'development' && !isServer && logger,
	];

	return applyMiddleware(...middlewares.filter(v => v !== false));
}

function createReducer() {
	const reducers = combineReducers({
		configuration,
		datasets,
		datasetSets,
		features,
		admStructure,
		benchmarkComparisonTypes,
	});

	return combineReducers({
		...baseStores,
		router,
		unhab: reducers,
		xCubeLayers,
		xCubeFeatures,
		xCubeTimeSeries,
	});
}

const composeEnhancers =
	(typeof window !== 'undefined' &&
		window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({})) ||
	compose;

function createEnhancer(requestCounter) {
	return composeEnhancers(
		reduxBatch,
		createMiddleware(requestCounter),
		reduxBatch,
		createMiddleware(requestCounter, true),
		reduxBatch,
	);
}

/**
 * Returns object with keys `store`, `readyP`.
 * - `readyP` - Promise that resolves once the app is initialized (helpful with SSR).
 * - `store` - Redux store.
 */
function createAppStore(options) {
	const isPreloaded = !isServer && window.__PRELOADED_STATE__ != null;
	const initialState = isPreloaded ? window.__PRELOADED_STATE__ : {};
	if (isPreloaded) {
		delete window.__PRELOADED_STATE__;
	}

	const requestCounter = createRequestCounter();
	const store = createStore(
		createReducer(),
		initialState,
		createEnhancer(requestCounter),
	);

	const absPath =
		options?.absPath ??
		window.location.protocol +
			'//' +
			window.location.host +
			process.env.PUBLIC_URL;

	initApp(store, {
		absPath,
		isPreloaded,
		currentUrl: options?.currentUrl,
		navHandler: options?.navHandler,
	});

	return {
		store: store,
		requestCounter: requestCounter,
	};
}

export default createAppStore;
