import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';

import Presentation from './presentation';

const mapStateToProps = state => {
	const mapSetKey = Select.maps.getActiveSetKey(state);

	return {
		activeMapKey: Select.maps.getMapSetActiveMapKey(state, mapSetKey),
		mapSetMapKeys: Select.maps.getMapSetMapKeys(state, mapSetKey),
		// layersState: Select.maps.getLayersStateByMapKey(state, ownProps.mapKey),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		removeMap: mapKey => {
			dispatch(Action.maps.removeMap(mapKey));
		},
		removeAllLayers: mapKey => {
			dispatch(Action.maps.removeAllMapLayers(mapKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
