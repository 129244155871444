import PropTypes from 'prop-types';
import LayerSettingsItem from '../LayerSettingsItem';
import './style.scss';

const RemoveLayerControl = ({removeLayer}) => {
	return (
		<LayerSettingsItem
			title="Remove layer"
			key="remove"
			onClick={removeLayer}
		></LayerSettingsItem>
	);
};

RemoveLayerControl.propTypes = {
	removeLayer: PropTypes.func,
};

export default RemoveLayerControl;
