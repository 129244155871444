import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';
import SelectedFeatureLabel from './SelectedFeatureLabel';

const mapStateToProps = state => {
	return {
		selectedFeatureKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSelectedFeatureKeysChange: () =>
			dispatch(Action.unhab.features.useSelectedFeatures()),
	};
};

const BenchmarkHeaderAreas = ({
	selectedFeatureKeys,
	onSelectedFeatureKeysChange,
}) => {
	useEffect(() => {
		onSelectedFeatureKeysChange();
	}, [selectedFeatureKeys]);

	return selectedFeatureKeys?.map((featureKey, index) => {
		return (
			<>
				<SelectedFeatureLabel featureKey={featureKey} large />
				{index < selectedFeatureKeys.length - 1 && <div className="divider" />}
			</>
		);
	});
};

BenchmarkHeaderAreas.propTypes = {
	features: PropTypes.array,
	selectionData: PropTypes.object,
	selectedFeatureKeys: PropTypes.array,
	onSelectedFeatureKeysChange: PropTypes.func,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BenchmarkHeaderAreas);
