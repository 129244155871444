import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';
import MapLegendValueItem from './MapLegendValueItem';

const MapLegendScale = ({values, colors, image, expanded}) => {
	const classes = classNames('ptr-MapLegendScale', {
		'is-expanded': expanded,
	});

	let imageUrl = null;
	if (image) {
		imageUrl = `data:image/png;base64, ${image}`;
	}

	return (
		<div className={classes}>
			{expanded ? (
				<>
					{colors.map((color, index) => (
						<MapLegendValueItem
							key={color}
							color={color}
							label={values[index]}
						/>
					))}
				</>
			) : (
				<>
					<span>{values[0]}</span>
					{image ? (
						<img src={imageUrl} />
					) : (
						<div
							style={{
								backgroundImage: `linear-gradient(90deg, ${
									colors ? colors.join(',') : ''
								})`,
							}}
						/>
					)}
					<span>{values[values.length - 1]}</span>
				</>
			)}
		</div>
	);
};

MapLegendScale.propTypes = {
	values: PropTypes.array,
	colors: PropTypes.array,
	image: PropTypes.string,
	expanded: PropTypes.bool,
};

export default MapLegendScale;
