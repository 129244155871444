import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';

const mapStateToProps = state => {
	return {
		activeComparisonTypeKey:
			Select.unhab.benchmarkComparisonTypes.getActiveKey(state),
		comparisonTypes: Select.unhab.benchmarkComparisonTypes.getAll(state),
		countries: Select.unhab.admStructure.getFeaturedCountries(state),
		datasets: Select.unhab.datasets.getAll(state),
		datasetSets: Select.unhab.datasetSets.getAll(state),
		activeSelectionKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
		activeDatasetKeys: Select.unhab.datasets.getActiveKeys(state),
		activeDatasetSetKey: Select.unhab.datasetSets.getActiveKey(state),
		activePeriodPeriod: Select.periods.getActive(state)?.data?.period,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.unhab.admStructure.ensureAreas());
			dispatch(Action.unhab.datasets.add());
			dispatch(Action.unhab.datasetSets.add());
		},
		onSave: (
			comparisonTypeKey,
			areaKeys,
			datasetKeys,
			datasetSetKey,
			period,
		) => {
			dispatch(
				Action.unhab.benchmark.set(
					comparisonTypeKey,
					areaKeys,
					datasetKeys,
					datasetSetKey,
					period,
				),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
