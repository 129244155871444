export default {
	axis: {
		domain: {
			line: {
				stroke: 'var(--base20)',
				strokeWidth: 2,
			},
		},
		ticks: {
			line: {
				stroke: 'var(--base20)',
			},
			text: {
				fill: 'var(--base40)',
				fontSize: 11,
			},
		},
		legend: {
			text: {
				fontWeight: 'bold',
				fill: 'var(--base50)',
			},
		},
	},
	grid: {
		line: {
			stroke: 'var(--base15)',
		},
	},
	crosshair: {
		line: {
			stroke: 'var(--base40)',
		},
	},
};
