import {
	auSelectionKey,
	unhabIndicatorLayersRadioGroupKey,
} from '../../constants/app';

import styles from '../../../data/transformed/styles/merged.json';

const getStyle = styleKey => {
	const style = styles[styleKey];
	if (style) {
		return style;
	} else {
		console.warn(`No style found for styleKey ${styleKey}`);
	}
};

export default {
	// URBANIZATION AREA EVOLUTION
	WSFE_LC_tot_rel: {
		nameDisplay: 'unused_Urbanization area evolution, total increase',
		key: 'WSFE_LC_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d7629a-7d3f-11ee-b962-0242ac120002',
				absolute: '79d763c6-7d3f-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('WSFE_LC_tot_rel'),
		},
	},
	GHS_BUILT_LC_tot_rel: {
		nameDisplay: 'unused_Urbanization area evolution, total increase',
		key: 'GHS_BUILT_LC_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d75e58-7d3f-11ee-b962-0242ac120002',
				absolute: '79d7615a-7d3f-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_BUILT_LC_tot_rel'),
		},
	},
	WSFE_LC_avg_rel: {
		nameDisplay: 'unused_Urbanization area evolution, average increase',
		key: 'WSFE_LC_avg_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d7674a-7d3f-11ee-b962-0242ac120002',
				absolute: '79d76862-7d3f-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('WSFE_LC_avg_rel'),
		},
	},
	GHS_BUILT_LC_avg_rel: {
		nameDisplay: 'unused_Urbanization area evolution, average increase',
		key: 'GHS_BUILT_LC_avg_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d764f2-7d3f-11ee-b962-0242ac120002',
				absolute: '79d7661e-7d3f-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_BUILT_LC_avg_rel'),
		},
	},
	WSFE_LC_min_rel: {
		nameDisplay: 'Urbanization area evolution, minimum increase',
		key: 'WSFE_LC_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d76f56-7d3f-11ee-b962-0242ac120002',
				absolute: '79d77064-7d3f-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('WSFE_LC_min_rel'),
		},
	},
	GHS_BUILT_LC_min_rel: {
		nameDisplay: 'Urbanization area evolution, minimum increase',
		key: 'GHS_BUILT_LC_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d76d08-7d3f-11ee-b962-0242ac120002',
				absolute: '79d76e3e-7d3f-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_BUILT_LC_min_rel'),
		},
	},
	WSFE_LC_max_rel: {
		nameDisplay: 'Urbanization area evolution, maximum increase',
		key: 'WSFE_LC_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d773b6-7d3f-11ee-b962-0242ac120002',
				absolute: '79d777f8-7d3f-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('WSFE_LC_max_rel'),
		},
	},
	GHS_BUILT_LC_max_rel: {
		nameDisplay: 'Urbanization area evolution, maximum increase',
		key: 'GHS_BUILT_LC_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '79d77190-7d3f-11ee-b962-0242ac120002',
				absolute: '79d77294-7d3f-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_BUILT_LC_max_rel'),
		},
	},
	// URBAN AREA
	WSFE_UE_min_rel: {
		nameDisplay: 'Total area of the urban extent share, minimum',
		key: 'WSFE_UE_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '2f6aa60c-7e22-11ee-b962-0242ac120002',
				absolute: '2f6aa6e8-7e22-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('WSFE_UE_min_rel'),
		},
	},
	WSF2019_tot_rel: {
		nameDisplay: 'Wsf 2019',
		key: 'WSF2019_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: 'b0c2f72d-3977-46a9-bad4-983e7af73e2e',
				absolute: 'be396866-7981-4c2c-a90a-f9b23ce9e158',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('WSF2019_tot_rel'),
		},
	},
	GHS_BUILT_UE_min_rel: {
		nameDisplay: 'Total area of the urban extent share, minimum',
		key: 'GHS_BUILT_UE_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '2f6aa260-7e22-11ee-b962-0242ac120002',
				absolute: '2f6aa508-7e22-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_BUILT_UE_min_rel'),
		},
	},
	WSFE_UE_max_rel: {
		nameDisplay: 'Total area of the urban extent share, maximum',
		key: 'WSFE_UE_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '2f6aac2e-7e22-11ee-b962-0242ac120002',
				absolute: '2f6aaf4e-7e22-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('WSFE_UE_max_rel'),
		},
	},

	GHS_BUILT_UE_max_rel: {
		nameDisplay: 'Total area of the urban extent share, maximum',
		key: 'GHS_BUILT_UE_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '2f6aa7ce-7e22-11ee-b962-0242ac120002',
				absolute: '2f6aa8be-7e22-11ee-b962-0242ac120002',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_BUILT_UE_max_rel'),
		},
	},
	// next layers

	WSFE_LCR: {
		nameDisplay: 'Land Consumption Rate for WSFEvolution',
		key: 'WSFE_LCR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('WSFE_LCR'),
		},
	},

	GHS_BUILT_LCR: {
		nameDisplay: 'Land Consumption Rate for GHS-BUILT-S',
		key: 'GHS_BUILT_LCR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_BUILT_LCR'),
		},
	},
	// GHS POPULATION GRID
	GHS_POP_PC_tot_rel: {
		nameDisplay: 'Population evolution, total increase',
		key: 'GHS_POP_PC_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: '07c38b31-9fdb-463e-b917-dbbafaa01f7a',
				absolute: '79d7615a-7d3f-11ee-b962-0242ac120002',
			},
			selectable: true,
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_POP_PC_tot_rel'),
		},
	},
	GHS_POP_PC_avg_rel: {
		nameDisplay: 'Population evolution, average increase',
		key: 'GHS_POP_PC_avg_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'ff258f32-5a90-4b51-a760-17a6d6e2b704',
				absolute: 'cbd8cc55-5a1c-4492-892b-86e87ed63872',
			},
			selectable: true,
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_POP_PC_avg_rel'),
		},
	},
	GHS_POP_PC_min_rel: {
		nameDisplay: 'Population evolution, min increase',
		key: 'GHS_POP_PC_min_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'e1e76504-3a30-48fb-936b-1830557ad812',
				absolute: '6da76d87-977e-4b50-9bfa-3190c28f8a20',
			},
			selectable: true,
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_POP_PC_min_rel'),
		},
	},
	GHS_POP_PC_max_rel: {
		nameDisplay: 'Population evolution, max increase',
		key: 'GHS_POP_PC_max_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			attributes: {
				relative: 'd5e6c9c3-78a0-454d-9d0f-0cdb83060e9a',
				absolute: '08630acd-7ecf-4e90-bcaf-b0af90ca52e4',
			},
			selectable: true,
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_POP_PC_max_rel'),
		},
	},
	GHS_POP_PGR: {
		nameDisplay: 'Population Growth Rate for GHS-POP',
		key: 'GHS_POP_PGR',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('GHS_POP_PGR'),
		},
	},
	WSF2019POP3D_UE_tot_rel: {
		nameDisplay: 'Wsf 2019 urban area',
		key: 'WSF2019POP3D_UE_tot_rel',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			attributes: {
				relative: '528b0767-669d-465a-a2b7-484fb0da5309',
				absolute: 'ae9e3200-5d4c-4802-ac66-d27110c7f1d2',
			},
			selected: {
				[auSelectionKey]: {},
			},
			style: getStyle('WSF2019POP3D_UE_tot_rel'),
		},
	},
	WSF2019POP3D_LCPC: {
		nameDisplay: 'Land Consumption per capita',
		key: 'WSF2019POP3D_LCPC',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		radioGroup: unhabIndicatorLayersRadioGroupKey,
		options: {
			selectable: true,
			selected: {
				[auSelectionKey]: {},
			},
			attributes: {
				relative: '86fc0cb4-cd4d-4286-bd12-68d4db1969c5',
			},
			style: getStyle('WSF2019POP3D_LCPC'),
		},
	},
};
