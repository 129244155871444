import PropTypes from 'prop-types';
import './style.scss';
import classnames from 'classnames';
import {Button} from '@gisatcz/ptr-atoms';
import FormItemLabel from '../Form/FormItemLabel';
import FormItem from '../Form/FormItem';

export const SidePanelHeader = ({children}) => {
	return <div className="ptr-SidePanelHeader">{children}</div>;
};

SidePanelHeader.propTypes = {
	children: PropTypes.node,
};

export const SidePanelHeaderItem = ({children, title}) => {
	return (
		<FormItem>
			<FormItemLabel>{title}</FormItemLabel>
			{children}
		</FormItem>
	);
};

SidePanelHeaderItem.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};

export const SidePanelBody = ({children}) => {
	return <div className="ptr-SidePanelBody">{children}</div>;
};

SidePanelBody.propTypes = {
	children: PropTypes.node,
};

const SidePanel = ({className, children, open = false, setOpen}) => {
	const classes = classnames('ptr-SidePanel', {}, className);

	return (
		<div className={classes} style={!open ? {width: 0, minWidth: 0} : null}>
			<Button
				className="ptr-SidePanel-control"
				onClick={() => setOpen(!open)}
				icon={!open ? 'ri-chevron-left' : 'ri-chevron-right'}
				side="right"
				floating
			/>
			<div className="ptr-SidePanel-content">{children}</div>
		</div>
	);
};

SidePanel.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	open: PropTypes.bool,
	setOpen: PropTypes.func,
};

export default SidePanel;
