export default {
	title: 'Average increase',
	subtitle: 'per year',
	configuration: {
		attributes: [
			'cbd8cc55-5a1c-4492-892b-86e87ed63872', // Population evolution average, absolute
			'ff258f32-5a90-4b51-a760-17a6d6e2b704',
		],
		type: 'avgYearlyIncrease',
	},
};
