import classnames from 'classnames';
import PropTypes from 'prop-types';
import MapComponentsGroup from '../MapComponentsGroup';
import MapAttribution from '../MapAttribution';
import Scale from '../Scale';
import ZoomControls from '../ZoomControls';
import BaseLayerControl from '../BaseLayerControl';
import DataLayerControl from '../DataLayersControl';
import FocusControl from '../FocusControl';
import './style.scss';
import MapLegendControl from '../MapLegendControl';
import AddPointControl from '../AddPointControl';
import SearchPlaceControl from '../SearchPlaceControl';

const getToolsByView = (view, position, mapKey) => {
	switch (view) {
		case 'explore':
		case 'report':
			return (
				<>
					<DataLayerControl horizontalPosition={position} mapKey={mapKey} />
					<BaseLayerControl horizontalPosition={position} mapKey={mapKey} />
					<FocusControl horizontalPosition={position} mapKey={mapKey} />
					<SearchPlaceControl mapKey={mapKey} />
				</>
			);
		case 'benchmark':
			return (
				<>
					<DataLayerControl horizontalPosition={position} mapKey={mapKey} />
					<BaseLayerControl horizontalPosition={position} mapKey={mapKey} />
				</>
			);
		case 'xcube':
			return (
				<>
					<DataLayerControl horizontalPosition={position} mapKey={mapKey} />
					<BaseLayerControl horizontalPosition={position} mapKey={mapKey} />
					<AddPointControl />
				</>
			);
		default:
			return null;
	}
};

const MapTools = ({
	activeAppView,
	position,
	view,
	viewLimits,
	mapKey,
	updateView,
	viewport,
}) => {
	const wrapperClasses = classnames('unhab-MapControlsWrapper', position, {});
	const mapToolsClasses = classnames('unhab-MapTools', position, {});
	const mapLegendClasses = classnames('unhab-MapLegendGroup', {
		bottom: true,
		left: position === 'right',
		right: position === 'left',
	});
	return (
		<MapComponentsGroup className={wrapperClasses}>
			<MapComponentsGroup className={mapToolsClasses}>
				{getToolsByView(activeAppView, position, mapKey)}
			</MapComponentsGroup>
			<MapComponentsGroup className="unhab-MapInfoElements">
				<MapComponentsGroup className="unhab-AttributionScaleContainer">
					<Scale
						{...{
							view,
							mapHeight: viewport?.height,
							mapWidth: viewport?.width,
						}}
					/>
					<MapAttribution mapKey={mapKey} />
				</MapComponentsGroup>
			</MapComponentsGroup>
			<MapComponentsGroup className={mapLegendClasses}>
				<MapLegendControl
					horizontalPosition={position === 'right' ? 'left' : 'right'}
					mapKey={mapKey}
					verticalPosition="bottom"
				/>
			</MapComponentsGroup>
			<MapComponentsGroup className="unhab-MapControls">
				<ZoomControls
					{...{
						view,
						viewLimits,
						mapKey,
						mapHeight: viewport?.height,
						mapWidth: viewport?.width,
						updateView,
					}}
				/>
			</MapComponentsGroup>
		</MapComponentsGroup>
	);
};

MapTools.propTypes = {
	activeAppView: PropTypes.string,
	mapSetKey: PropTypes.string,
	position: PropTypes.string,
	view: PropTypes.object,
	viewLimits: PropTypes.object,
	viewport: PropTypes.object,
	mapKey: PropTypes.string,
	updateView: PropTypes.func,
};

export default MapTools;
