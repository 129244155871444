import Link from '../../common/Link';
import {getRouter} from '../../../router';
import './style.scss';

const HomePage = () => {
	const router = getRouter();

	return (
		<div>
			UNHAB main page
			<Link ignoreHistory={true} router={router} name={'app'}>
				Go to map explorer
			</Link>
		</div>
	);
};

export default HomePage;
