import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';

import Presentation from './presentation';

import './style.scss';

const mapStateToProps = (state, ownProps) => {
	return {
		mapsCount:
			(Select.maps.getMapSetMapKeys(state, ownProps.mapSetKey) || []).length ||
			0,
		mode: Select.components.get(state, 'Maps', 'mode'),
	};
};
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onActiveOptionChange: value => {
			dispatch(Action.unhab.setMapsCount(ownProps.mapSetKey, value));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
