import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';

import Select from '../../../../../state/Select';

const mapStateToProps = state => {
	return {
		globalDatasets: Select.unhab.layers.getGlobalIndicatorLayers(state),
		localDatasets: Select.unhab.layers.getLocalIndicatorLayers(state),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
