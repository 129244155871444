import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {useRef, useState, cloneElement} from 'react';
import classnames from 'classnames';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

import './style.scss';

const MapConfigurationControl = ({
	detail,
	horizontalPosition,
	verticalPosition,
	icon,
	name,
	children,
	closeContentOnFocusOut = true,
}) => {
	const wrapperEl = useRef(null);
	const [open, setOpen] = useState(false);
	const [detailIsExpanded, setDetailIsExpanded] = useState(false);
	const classes = classnames('ptr-MapConfigurationControl', {
		'is-right': horizontalPosition === 'right',
		'is-left': horizontalPosition === 'left',
		'is-bottom': verticalPosition === 'bottom',
	});
	const contentClasses = classnames('ptr-MapConfigurationControl-content', {
		'is-open': open,
		'show-top': verticalPosition === 'bottom',
	});

	useOnClickOutside(
		wrapperEl,
		() => {
			setOpen(false);
		},
		!closeContentOnFocusOut,
	);

	return (
		<div className={classes} ref={wrapperEl}>
			<div className="ptr-MapConfigurationControl-header">
				<Button
					small
					floating
					icon={icon}
					primary={open}
					onClick={() => setOpen(!open)}
				>
					{name}
				</Button>
				{detail ? (
					<div
						className="ptr-MapConfigurationControl-detail"
						onClick={() => {
							setDetailIsExpanded(!detailIsExpanded);
						}}
						style={detail?.length > 50 ? {cursor: 'pointer'} : {cursor: 'auto'}}
					>
						{detail?.length > 50 && !detailIsExpanded
							? detail?.slice(0, 50) + '...'
							: detail}
					</div>
				) : null}
			</div>
			<div className={contentClasses}>
				{open ? cloneElement(children) : null}
			</div>
		</div>
	);
};

MapConfigurationControl.propTypes = {
	detail: PropTypes.node,
	horizontalPosition: PropTypes.string,
	verticalPosition: PropTypes.string,
	icon: PropTypes.string,
	name: PropTypes.string,
	children: PropTypes.node,
	closeContentOnFocusOut: PropTypes.bool,
};

export default MapConfigurationControl;
