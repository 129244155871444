import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';

const mapStateToProps = state => {
	return {
		active: Select.components.get(state, 'App', 'darkMode'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onModeChange: darkModeActive => {
			dispatch(Action.components.set('App', 'darkMode', darkModeActive));
			dispatch(Action.unhab.layers.setAllMapsBackgroundLayerByMode());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
