// import PropTypes from 'prop-types';
import Header from '../../common/Header';
import AppContent from '../../common/AppContent';
import Map from '../../common/Map';

import './style.scss';

const Report = () => {
	return (
		<>
			<Header />
			<AppContent>
				<Map />
			</AppContent>
		</>
	);
};

Report.propTypes = {};

export default Report;
