import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../state/Select';
import Action from '../../../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		hidden: Select.unhab.layers.isLayerLegendHidden(
			state,
			ownProps.mapKey,
			ownProps.layerKey,
			ownProps.layerTemplateKey,
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: hidden => {
			dispatch(
				Action.unhab.layers.setLayerLegendHidden(
					ownProps.mapKey,
					ownProps.layerKey,
					ownProps.layerTemplateKey,
					hidden,
				),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
