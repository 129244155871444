import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import {statisticsGroupsAttributeSets} from '../../../../../constants/app';

const mapStateToProps = (state, ownProps) => {
	return {
		sections:
			Select.unhab.datasets.filterGivenAttributeSetsByDatasetKeyAttributes(
				state,
				statisticsGroupsAttributeSets,
				ownProps.datasetKey,
			),
	};
};

const mapDispatchToPropsFactory = () => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory,
)(Presentation);
