import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		data: Select.unhab.indicators.getIndicatorData(
			state,
			ownProps.configuration,
		),
		obsolete: true,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
