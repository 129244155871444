import {
	Select as CommonSelect,
	Action as CommonAction,
} from '@gisatcz/ptr-state';
import Select from '../../Select';

const getCogLayerKey = (prevLayerKey, opacity) => {
	const prevOpacity = `${prevLayerKey.split(`_opacity`)?.[1]}`;
	const layerKey = `${prevLayerKey.split(`_opacity`)?.[0]}`;
	if (prevOpacity === opacity || opacity === 100) {
		return layerKey;
	} else {
		return `${layerKey}_opacity${opacity}`;
	}
};

const setLayerOpacity = (mapKey, layerKey, layerTemplateKey, opacity) => {
	return (dispatch, getState) => {
		const layers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		if (layers) {
			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			// TODO temporary solution since COGs do not rerenders on props change and need to change key to correctly rerender
			const updatedLayers = layers?.map(layer => {
				if (
					(layer?.layerTemplateKey &&
						layerTemplateKey &&
						layer?.layerTemplateKey === layerTemplateKey) ||
					(layer?.key && layerKey && layer?.key === layerKey)
				) {
					const newLayerKey = isLayerCog(getState(), layer)
						? getCogLayerKey(layer.key, opacity)
						: layer.key;
					return {
						...layer,
						key: newLayerKey,
						options: {
							...layer.options,
							alpha: opacity,
						},
						opacity: opacity / 100,
					};
				} else {
					return layer;
				}
			});

			dispatch(CommonAction.maps.addMapLayers(mapKey, updatedLayers));
		}
	};
};

const setLayerLegendHidden = (mapKey, layerKey, layerTemplateKey, hide) => {
	return (dispatch, getState) => {
		// TODO setup this function in ptr-state
		// dispatch(CommonAction.maps.setMapLayerParameter(mapKey, layerKey, layerTemplateKey, 'hideLegend', hide));

		const layers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		if (layers) {
			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			const updatedLayers = layers?.map(layer => {
				if (
					(layer?.layerTemplateKey &&
						layerTemplateKey &&
						layer?.layerTemplateKey === layerTemplateKey) ||
					(layer?.key && layerKey && layer?.key === layerKey)
				) {
					return {
						...layer,
						hideLegend: hide,
					};
				} else {
					return layer;
				}
			});

			dispatch(CommonAction.maps.addMapLayers(mapKey, updatedLayers));
		}
	};
};

const sortLayers = (mapKey, fromIndex, toIndex) => {
	return (dispatch, getState) => {
		const currentLayers = CommonSelect.maps.getMapLayersStateByMapKey(
			getState(),
			mapKey,
		);

		if (currentLayers?.length) {
			const sortedLayers = [...currentLayers].reverse();
			const item = sortedLayers.splice(fromIndex, 1)[0];
			sortedLayers.splice(toIndex, 0, item);

			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
			dispatch(CommonAction.maps.addMapLayers(mapKey, sortedLayers.reverse()));
		}
	};
};

const setAllMapsBackgroundLayerByMode = () => {
	return (dispatch, getState) => {
		const mapsInUse = CommonSelect.maps.getAllMapsInUse(getState());
		if (mapsInUse?.length) {
			const layer = Select.unhab.layers.getDefaultBackgroundLayerByAppMode(
				getState(),
			);

			if (layer) {
				mapsInUse.forEach(mapKey => {
					dispatch(CommonAction.maps.setMapBackgroundLayer(mapKey, layer));
				});
			}
		}
	};
};

// TODO temporary helpers, remove after fix in ptr-maps/ptr-state
function isLayerCog(state, layerState) {
	let isCog = false;
	const dataSources = CommonSelect.data.spatialDataSources.getIndexed(state, {
		layerTemplateKey: layerState?.layerTemplateKey,
	});
	if (dataSources) {
		isCog = dataSources.some(obj => obj.data?.type === 'cogBitmap');
	}

	return isCog;
}

export default {
	setLayerOpacity,
	setLayerLegendHidden,
	setAllMapsBackgroundLayerByMode,
	sortLayers,
};
