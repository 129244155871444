import PropTypes from 'prop-types';
import IndicatorLayerTooltip from './IndicatorLayerTooltip';
import indicatorLayers from '../../../../data/layers/indicatorLayers';

const VectorMapTooltip = ({layer, mapKey}) => {
	//filter only indicator layers
	const indicatorsLayerKeys = Object.values(indicatorLayers).map(l => l.key);
	const layerKey = layer?.layer?.props?.layerKey;
	if (indicatorsLayerKeys.includes(layerKey)) {
		const attributeKey =
			layer?.layer?.props.styleForDeck.attributeStyles[0].attributeKey;
		const attributeValue = layer?.object?.properties[attributeKey];
		const {ADM0_CODE, ADM1_CODE, ...rest} = layer.object.properties;
		return (
			<IndicatorLayerTooltip
				mapKey={mapKey}
				featureKey={ADM1_CODE ? `${ADM0_CODE}_${ADM1_CODE}` : ADM0_CODE}
				layerKey={layerKey}
				attributeKey={attributeKey}
				attributeValue={attributeValue}
				{...rest}
			/>
		);
	} else {
		return null;
	}
};

VectorMapTooltip.propTypes = {
	layer: PropTypes.object,
	mapKey: PropTypes.string,
};

export default VectorMapTooltip;
