import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	let name;
	if (ownProps.data?.options?.attributes?.relative) {
		name = Select.unhab.layers.getLayerName(ownProps.data);
	} else if (ownProps?.data?.nameDisplay) {
		name = ownProps?.data?.nameDisplay;
	} else {
		const layerTemplate =
			Select.layerTemplates.getByKey(state, ownProps.data?.layerTemplateKey) ||
			ownProps.data?.metadata?.layerTemplate;
		name = layerTemplate?.data?.nameDisplay;
	}
	return {
		name,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
