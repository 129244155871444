export default {
	title: 'Average increase',
	subtitle: 'per year',
	configuration: {
		attributes: [
			'79d76862-7d3f-11ee-b962-0242ac120002',
			'79d7674a-7d3f-11ee-b962-0242ac120002',
			'79d7661e-7d3f-11ee-b962-0242ac120002',
			'79d764f2-7d3f-11ee-b962-0242ac120002',
		],
		type: 'avgYearlyIncrease',
	},
};
