import PropTypes from 'prop-types';
import {useState, cloneElement} from 'react';
import {Icon} from '@gisatcz/ptr-atoms';
import DataLayerControlSettings from '../DataLayerControlSettings';

import LayerSettings from '../LayerSettings';
import LayerOpacityControl from '../LayerSettings/LayerOpacityControl';
import HideLegendControl from '../LayerSettings/HideLegendControl';
import RemoveLayerControl from '../LayerSettings/RemoveLayerControl';

import './style.scss';

const SortLayersItem = ({name, data, mapKey, SortLayerComponent}) => {
	const [settingsOpen, setSettingsOpen] = useState(false);
	return (
		<li className="unhab-SortLayersItem">
			<div className="unhab-DataLayerControl-header">
				<a>
					<Icon icon="ri-drag-indicator" />
				</a>
				<span>{name}</span>
				<div className="unhab-DataLayerControl-tools">
					<DataLayerControlSettings
						onClick={setSettingsOpen}
						active={settingsOpen}
					/>
				</div>
			</div>
			{settingsOpen ? (
				<LayerSettings>
					{SortLayerComponent
						? cloneElement(<SortLayerComponent />, {layer: data, mapKey})
						: null}
					<LayerOpacityControl data={data} mapKey={mapKey} />
					<HideLegendControl
						layerKey={data?.key}
						layerTemplateKey={data?.layerTemplateKey}
						mapKey={mapKey}
					/>
					<RemoveLayerControl layerKey={data?.key} mapKey={mapKey} />
				</LayerSettings>
			) : null}
		</li>
	);
};

SortLayersItem.propTypes = {
	name: PropTypes.string,
	data: PropTypes.object,
	SortLayerComponent: PropTypes.node,
	mapKey: PropTypes.string,
};

export default SortLayersItem;
