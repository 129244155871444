export default {
	title: 'Urban area',
	subtitle: '2019',
	configuration: {
		attributes: [
			'be396866-7981-4c2c-a90a-f9b23ce9e158', // Urban area, total, absolute
			'b0c2f72d-3977-46a9-bad4-983e7af73e2e', // Urban area, total, relative
			'ae9e3200-5d4c-4802-ac66-d27110c7f1d2', // Urban area, total, absolute (WSF 2019 next gen)
			'528b0767-669d-465a-a2b7-484fb0da5309', // Urban area, total, relative (WSF 2019 next gen)
		],
	},
};
