import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

export const BoxTitle = ({children, className}) => {
	const classNames = classnames('ptr-BoxTitle', {}, className);

	return <div className={classNames}>{children}</div>;
};

BoxTitle.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export const BoxSubtitle = ({children, className}) => {
	const classNames = classnames('ptr-BoxSubtitle', {}, className);

	return <div className={classNames}>{children}</div>;
};

BoxSubtitle.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

const Box = ({children, className}) => {
	const classNames = classnames('ptr-Box', {}, className);

	return <div className={classNames}>{children}</div>;
};

Box.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default Box;
