export default {
	title: 'Population growth rate',
	configuration: {
		valuesFormat: {
			multiplier: 100,
		},
		attributes: ['fedcb00a-92dc-4a03-acf0-a63040373b9f'],
		type: 'growthRate',
	},
};
