export default {
	title: 'Land Consumption per capita',
	configuration: {
		attributes: [
			'bdb973bf-d693-4a1d-a64a-d71ce5f7c571', // GHS population
			'86fc0cb4-cd4d-4286-bd12-68d4db1969c5', // WSF 2019 next gen
		],
		type: 'lastValuesRatio',
	},
};
