export default [
	// URBANIZATION AREA EVOLUTION
	{
		key: '010db6b8-d06f-4c39-86a8-91328c03afb6',
		data: {
			nameInternal: 'GHS_POP_LCPC',
			nameDisplay: 'Land Consumption per capita',
			type: 'text',
			unit: '%',
			configuration: {
				columnName: 'GHS_POP_LCPC',
			},
		},
	},
	{
		key: 'ca15b319-d332-49ca-870c-b9240bd340a2',
		data: {
			nameInternal: 'WSFE_LCR',
			nameDisplay: 'Land Consumption Rate (WSF Evolution)',
			unit: '%',
			configuration: {
				columnName: 'WSFE_LCR',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},
	{
		key: 'c09bac02-f276-4ea4-a5f6-e090a8313454',
		data: {
			nameInternal: 'GHS_BUILT_LCR',
			nameDisplay: 'Land Consumption Rate (GHS Built-up data)',
			unit: '%',
			configuration: {
				columnName: 'GHS_BUILT_LCR',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},
	{
		key: '79d75e58-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_BUILT_LC_tot_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},
	{
		key: '79d7615a-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total increase absolute (GHS Built-up data)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'GHS_BUILT_LC_tot_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},
	{
		key: '79d7629a-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'WSFE_LC_tot_rel',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},
	{
		key: '79d763c6-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Total increase  absolute (WSF Evolution)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'WSFE_LC_tot_abs',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},
	{
		key: '79d764f2-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Average increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_BUILT_LC_avg_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},

	{
		key: '79d7661e-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Average increase absolute (GHS Built-up data)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'GHS_BUILT_LC_avg_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},
	{
		key: '79d7674a-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Average increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'WSFE_LC_avg_rel',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},

	{
		key: '79d76862-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Average increase absolute (WSF Evolution)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'WSFE_LC_avg_abs',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},
	{
		key: '79d76d08-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Min. increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_BUILT_LC_min_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},

	{
		key: '79d76e3e-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Min. increase absolute (GHS Built-up)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'GHS_BUILT_LC_min_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},
	{
		key: '79d76f56-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Min. increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'WSFE_LC_min_rel',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},

	{
		key: '79d77064-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Min. increase absolute (WSF Evolution)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'WSFE_LC_min_abs',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},
	{
		key: '79d77190-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Max. increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_BUILT_LC_max_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},

	{
		key: '79d77294-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Max. increase absolute (GHS Built-up)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'GHS_BUILT_LC_max_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},
	{
		key: '79d773b6-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Max. increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'WSFE_LC_max_rel',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},

	{
		key: '79d777f8-7d3f-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Max. increase absolute (WSF Evolution)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'WSFE_LC_max_abs',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},
	// URBAN AREA
	{
		key: '2f6aa260-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Minimum',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_BUILT_UE_min_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	{
		key: '2f6aa508-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Minimum absolute (DLR)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'GHS_BUILT_UE_min_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},
	{
		key: '2f6aa60c-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Minimum',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'WSFE_UE_min_rel',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	{
		key: '2f6aa6e8-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Minimum absolute (JRC)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'WSFE_UE_min_abs',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},
	{
		key: '2f6aa7ce-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Maximum',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_BUILT_UE_max_rel',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	{
		key: '2f6aa8be-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Maximum absolute (DLR)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'GHS_BUILT_UE_max_abs',
				sourceColumnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},
	{
		key: '2f6aac2e-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Maximum',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'WSFE_UE_max_rel',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
				normalizationColumnName: 'Shape_Area_km2',
			},
		},
	},
	{
		key: '2f6aaf4e-7e22-11ee-b962-0242ac120002',
		data: {
			nameDisplay: 'Maximum absolute (JRC)',
			unit: 'sqkm',
			type: 'absolute',
			configuration: {
				columnName: 'WSFE_UE_max_abs',
				sourceColumnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},
	{
		key: 'be396866-7981-4c2c-a90a-f9b23ce9e158',
		data: {
			nameDisplay: 'Urban area (WSF 2019) - absolute',
			unit: 'sqkm',
			configuration: {
				columnName: 'WSF2019_tot_abs',
			},
		},
	},
	{
		key: 'b0c2f72d-3977-46a9-bad4-983e7af73e2e',
		data: {
			nameDisplay: 'Urban area (WSF 2019) - relative',
			unit: '%',
			configuration: {
				columnName: 'WSF2019_tot_rel',
			},
		},
	},
	{
		key: '08a819f3-d71b-4753-8929-65f67107dd22',
		data: {
			nameDisplay: 'Land consumption progress (WSF evolution)',
			unit: 'sqkm',
			configuration: {
				columnNameTemplate: 'WSFE_LC_{XXXX}',
			},
		},
	},
	{
		key: 'a0cd644a-9e43-4820-aa2d-7a9efa6e0f6f',
		data: {
			nameDisplay: 'Land consumption progress (GHS Built-up data)',
			unit: 'sqkm',
			configuration: {
				columnNameTemplate: 'GHS_BUILT_LC_{XXXX}',
			},
		},
	},
	{
		key: '363d9e21-d608-462b-b021-d78b86e56b39',
		data: {
			nameDisplay: 'Population progress (GHS Population data)',
			unit: 'inh.',
			configuration: {
				columnNameTemplate: 'GHS_POP_PC_{XXXX}',
			},
		},
	},
	{
		key: '4e05b390-0e33-41e1-949d-2d51616a4af7',
		data: {
			nameDisplay: 'Area',
			unit: 'sqkm',
			configuration: {
				columnName: 'Shape_Area_km2',
			},
		},
	},
	// D5 GHS population grid 1975-2030
	{
		key: 'ab4fe885-65db-4843-b43b-3c16693a9ac7',
		data: {
			nameDisplay: 'Total increase',
			unit: 'inh.',
			type: 'absolute',
			configuration: {
				columnName: 'GHS_POP_PC_tot_abs',
				sourceColumnNameTemplate: 'GHS_POP_PC_{XXXX}',
			},
		},
	},
	{
		key: '07c38b31-9fdb-463e-b917-dbbafaa01f7a',
		data: {
			nameDisplay: 'Total increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_POP_PC_tot_rel',
				sourceColumnNameTemplate: 'GHS_POP_PC_{XXXX}',
			},
		},
	},
	{
		key: 'ff258f32-5a90-4b51-a760-17a6d6e2b704',
		data: {
			nameDisplay: 'Average increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_POP_PC_avg_rel',
				sourceColumnNameTemplate: 'GHS_POP_PC_{XXXX}',
			},
		},
	},
	{
		key: 'cbd8cc55-5a1c-4492-892b-86e87ed63872',
		data: {
			nameDisplay: 'Average increase absolute',
			unit: 'inh.',
			type: 'absolute',
			configuration: {
				columnName: 'GHS_POP_PC_avg_abs',
				sourceColumnNameTemplate: 'GHS_POP_PC_{XXXX}',
			},
		},
	},
	{
		key: 'e1e76504-3a30-48fb-936b-1830557ad812',
		data: {
			nameDisplay: 'Min. increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_POP_PC_min_rel',
				sourceColumnNameTemplate: 'GHS_POP_PC_{XXXX}',
			},
		},
	},
	{
		key: '6da76d87-977e-4b50-9bfa-3190c28f8a20',
		data: {
			nameDisplay: 'Min. increase absolute',
			unit: 'inh.',
			type: 'absolute',
			configuration: {
				columnName: 'GHS_POP_PC_min_abs',
				sourceColumnNameTemplate: 'GHS_POP_PC_{XXXX}',
			},
		},
	},
	{
		key: 'd5e6c9c3-78a0-454d-9d0f-0cdb83060e9a',
		data: {
			nameDisplay: 'Max. increase',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_POP_PC_max_rel',
				sourceColumnNameTemplate: 'GHS_POP_PC_{XXXX}',
			},
		},
	},
	{
		key: '08630acd-7ecf-4e90-bcaf-b0af90ca52e4',
		data: {
			nameDisplay: 'Max. increase absolute',
			unit: 'inh.',
			type: 'absolute',
			configuration: {
				columnName: 'GHS_POP_PC_max_abs',
				sourceColumnNameTemplate: 'GHS_POP_PC_{XXXX}',
			},
		},
	},
	{
		key: 'fedcb00a-92dc-4a03-acf0-a63040373b9f',
		data: {
			nameDisplay: 'Population Growth Rate',
			unit: '%',
			type: 'relative',
			configuration: {
				columnName: 'GHS_POP_PGR',
				sourceColumnNameTemplate: 'GHS_POP_PC_{XXXX}',
			},
		},
	},
	{
		key: '3483d7cc-1cde-4a98-b09a-7dddc0ee16f4',
		data: {
			nameDisplay: 'Land Consumption Rate/Population Growth Rate',
			configuration: {
				columnName: 'GHS_LCRPGR',
				sourceColumnNameTemplates: ['GHS_BUILT_LC_{XXXX}', 'GHS_POP_PC_{XXXX}'],
			},
		},
	},
	{
		key: 'bdb973bf-d693-4a1d-a64a-d71ce5f7c571',
		data: {
			nameDisplay: 'Land Consumption per capita',
			unit: 'sqm/inh.',
			configuration: {
				columnName: 'GHS_LCPC',
				sourceColumnNameTemplates: ['GHS_BUILT_LC_{XXXX}', 'GHS_POP_PC_{XXXX}'],
			},
		},
	},

	// WSF2019 next gen (4 countries)
	{
		key: 'ae9e3200-5d4c-4802-ac66-d27110c7f1d2',
		data: {
			nameDisplay: 'Urban area (WSF 2019 next gen) - absolute',
			unit: 'sqkm',
			configuration: {
				columnName: 'WSF2019POP3D_UE_tot_abs',
			},
		},
	},
	{
		key: '528b0767-669d-465a-a2b7-484fb0da5309',
		data: {
			nameDisplay: 'Urban area (WSF 2019 next gen) - relative',
			unit: '%',
			configuration: {
				columnName: 'WSF2019POP3D_UE_tot_rel',
			},
		},
	},
	{
		key: '76b858f6-a289-4fa2-916a-7fb0bbe13f25',
		data: {
			nameDisplay: 'Total population (2019)',
			unit: 'inh.',
			configuration: {
				columnName: 'WSF2019POP3D_POP_tot',
			},
		},
	},
	{
		key: '86fc0cb4-cd4d-4286-bd12-68d4db1969c5',
		data: {
			nameDisplay: 'Land Consumption per capita',
			unit: 'sqm/inh.',
			configuration: {
				columnName: 'WSF2019POP3D_LCPC',
			},
		},
	},
];
