import ActionTypes from '../../../constants/ActionTypes';
import {commonReducers} from '@gisatcz/ptr-state';

const INITIAL_STATE = {
	activeKeys: ['wsfEvolution'],
	byKey: {},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNHAB.DATASETS.ADD:
			return commonReducers.add(state, action);
		case ActionTypes.UNHAB.DATASETS.SET_ACTIVE_KEYS:
			return commonReducers.setActiveMultiple(state, action);
		default:
			return state;
	}
};
