import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../state/Action';
import Select from '../../../state/Select';

const mapStateToProps = state => {
	return {
		activeSelectionKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
		activeKeys: Select.unhab.datasets.getActiveKeys(state),
		models: Select.unhab.datasets.getAll(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.unhab.datasets.add());
		},
		setActiveKeys: keys => {
			dispatch(Action.unhab.datasets.setActiveKeys(keys));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
