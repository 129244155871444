import PropTypes from 'prop-types';
import ReactSlider from 'react-slider';
import classnames from 'classnames';
import './style.scss';

const PeriodSelector = ({
	defaultPeriod,
	activePeriod,
	setActivePeriod,
	narrow,
}) => {
	const onPeriodChange = period => {
		setActivePeriod(period.join('/'));
	};

	const [min, max] = defaultPeriod.split('/');
	const [start, stop] = activePeriod.split('/');

	const classes = classnames('unhab-PeriodSelector', {
		'is-narrow': narrow,
	});

	return (
		<div className={classes}>
			<span>{min}</span>
			<ReactSlider
				min={Number(min)}
				max={Number(max)}
				minDistance={1}
				pearling
				value={[Number(start), Number(stop)]}
				onChange={onPeriodChange}
				className="unhab-PeriodSelector-slider"
				thumbClassName="unhab-PeriodSelector-thumb"
				trackClassName="unhab-PeriodSelector-track"
				renderThumb={(props, state) => (
					<div {...props}>
						<div title={state.valueNow}>{`${state.valueNow
							?.toString()
							.substring(2, 4)}`}</div>
					</div>
				)}
			/>
			<span>{max}</span>
		</div>
	);
};

PeriodSelector.propTypes = {
	narrow: PropTypes.bool,
	defaultPeriod: PropTypes.string,
	activePeriod: PropTypes.string,
	setActivePeriod: PropTypes.func,
};

export default PeriodSelector;
