import './style.scss';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {Button} from '@gisatcz/ptr-atoms';
import AddSelectedAreaControl from '../AddSelectedAreaControl';
import SelectedFeatureControl from './SelectedFeatureControl';

const SelectedFeaturesControl = ({
	selectionData,
	onSetKeys,
	FeatureLabel,
	showAddButton,
}) => {
	const [areaSelectControlOpen, setAreaSelectControlOpen] = useState(false);
	const featureKeys = selectionData?.featureKeysFilter?.keys;
	const onFeaturesRemove = featureKey => {
		if (!featureKey) {
			onSetKeys([]);
		} else if (featureKeys?.length) {
			const updatedKeys = featureKeys.filter(key => key !== featureKey);
			onSetKeys(updatedKeys);
		}
	};

	return (
		<>
			<div className="unhab-SelectedFeaturesControl">
				{(featureKeys?.length > 0 &&
					featureKeys?.map(featureKey => (
						<SelectedFeatureControl
							key={featureKey}
							featureKey={featureKey}
							onRemove={onFeaturesRemove}
							FeatureLabel={FeatureLabel}
						/>
					))) ||
					(showAddButton ? (
						<div className="unhab-SelectedFeaturesControl-note">
							Click on area in map or{' '}
							<Button
								small
								className={'unhab-SelectedFeaturesControl-addButton'}
								invisible
								primary
								icon="ri-add"
								onClick={() => setAreaSelectControlOpen(true)}
							>
								Add area
							</Button>{' '}
							from list.
						</div>
					) : null)}
				{featureKeys?.length > 1 ? (
					<Button
						small
						invisible
						icon="ri-close"
						onClick={() => onFeaturesRemove(null)}
					>
						Clear all
					</Button>
				) : null}
				{showAddButton && featureKeys?.length > 0 ? (
					<Button
						small
						invisible
						primary
						disabled={areaSelectControlOpen}
						icon="ri-add"
						onClick={() => setAreaSelectControlOpen(true)}
					>
						Add area
					</Button>
				) : null}
			</div>
			<AddSelectedAreaControl
				isOpen={areaSelectControlOpen}
				onClose={() => setAreaSelectControlOpen(false)}
			/>
		</>
	);
};

SelectedFeaturesControl.propTypes = {
	FeatureLabel: PropTypes.object,
	selectionData: PropTypes.object,
	onSetKeys: PropTypes.func,
	zoomToFeature: PropTypes.func,
	showAddButton: PropTypes.bool,
};

export default SelectedFeaturesControl;
