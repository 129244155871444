import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {createElement} from 'react';
import './style.scss';

const SelectedFeatureControl = ({featureKey, onRemove, FeatureLabel}) => {
	return (
		<div className="unhab-SelectedFeatureControl">
			{FeatureLabel && createElement(FeatureLabel, {featureKey})}
			<div className="unhab-SelectedFeatureControl-tools">
				<Button
					title={'Remove area'}
					small
					invisible
					icon="ri-close"
					onClick={evt => {
						onRemove(featureKey), evt.stopPropagation();
					}}
				/>
			</div>
		</div>
	);
};

SelectedFeatureControl.propTypes = {
	featureKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	FeatureLabel: PropTypes.object,
	onRemove: PropTypes.func,
};

export default SelectedFeatureControl;
