export default {
	title: 'Total area of urban extent - minimum',
	configuration: {
		attributes: [
			'2f6aa6e8-7e22-11ee-b962-0242ac120002',
			'2f6aa60c-7e22-11ee-b962-0242ac120002',
			'2f6aa508-7e22-11ee-b962-0242ac120002',
			'2f6aa260-7e22-11ee-b962-0242ac120002',
		],
		type: 'minimum',
	},
};
