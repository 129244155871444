import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import './style.scss';

const PointControl = ({name, onRemove}) => {
	return (
		<div className="unhab-XCubePointControl">
			<div className="unhab-XCubePointControl-name">
				<div className="unhab-XCubePointControl-title">{name}</div>
			</div>
			<div className="unhab-XCubePointControl-tools">
				<Button small invisible icon="delete" onClick={onRemove} />
			</div>
		</div>
	);
};

PointControl.propTypes = {
	name: PropTypes.string,
	onRemove: PropTypes.func,
};

export default PointControl;
