export default {
	title: 'Maximal yearly increase',
	configuration: {
		attributes: [
			'79d777f8-7d3f-11ee-b962-0242ac120002',
			'79d773b6-7d3f-11ee-b962-0242ac120002',
			'79d77294-7d3f-11ee-b962-0242ac120002',
			'79d77190-7d3f-11ee-b962-0242ac120002',
		],
		type: 'maxYearlyIncrease',
	},
};
