import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';
import {
	auSelectionKey,
	benchmarkSelectionKey,
	defaultViewKey,
	benchmarkViewKey,
	limassolAreasSelectionKey,
	xCubeViewKey,
} from '../../../constants/app';
import Action from '../../../state/Action';
import Presentation from './presentation';

const mapStateToProps = state => {
	const viewKey =
		Select.router.getViewKey(state) || Select.views.getActiveKey(state);
	return {
		viewKey,
		view: Select.views.getByKey(state, viewKey),
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			onViewKeyChanged: viewKey => {
				dispatch(Action.unhab.applyAndSetActive(viewKey, Action));
				if (viewKey === xCubeViewKey) {
					dispatch(Action.selections.setActiveKey(limassolAreasSelectionKey));
				} else if (viewKey === benchmarkViewKey) {
					dispatch(Action.selections.setActiveKey(benchmarkSelectionKey));
				} else {
					dispatch(Action.selections.setActiveKey(auSelectionKey));
				}
			},
			setDefaultViewKey: () => {
				//set viewkey to url
				dispatch(Action.router.updateAppUrl('app', {viewKey: defaultViewKey}));
			},
			onMount: () => {},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
