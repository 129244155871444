import PropTypes from 'prop-types';
import {isEqual as _isEqual} from 'lodash';
import {useEffect, useState} from 'react';
import {Button, Icon} from '@gisatcz/ptr-atoms';
import Form from '../../../common/Form/Form';
import FormItem from '../../../common/Form/FormItem';
import FormItemLabel from '../../../common/Form/FormItemLabel';
import FormFooter from '../../../common/Form/FormFooter';
import ComparisonTypeSelector from './ComparisonTypeSelector';
import AreaPicker from '../../../common/AreaPicker';
import DatasetSelect from '../../../common/DatasetSelect/presentation';
import DatasetSetSelector from './DatasetSetSelector';
import PeriodSelector from '../../../common/PeriodSelector/presentation';
import './style.scss';
import {defaultPeriod} from '../../../../constants/app';

const BenchmarkConfiguration = ({
	activeComparisonTypeKey,
	activeDatasetKeys,
	activeDatasetSetKey,
	activePeriodPeriod,
	comparisonTypes,
	countries,
	datasets,
	datasetSets,
	activeSelectionKeys,
	onMount,
	onWindowClose,
	onSave,
}) => {
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [activeComparisonType, setComparisonType] = useState(
		activeComparisonTypeKey,
	);
	const [activeDatasets, setDatasets] = useState(activeDatasetKeys);
	const [activeDatasetSet, setDatasetSet] = useState(activeDatasetSetKey);
	const [activePeriod, setPeriod] = useState(activePeriodPeriod);
	const [selectedCountry1, setSelectedCountry1] = useState(null);
	const [selectedRegion1, setSelectedRegion1] = useState(null);
	const [selectedCountry2, setSelectedCountry2] = useState(null);
	const [selectedRegion2, setSelectedRegion2] = useState(null);

	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	useEffect(() => {
		const [area1, area2] = activeSelectionKeys;
		const [country1, region1] = area1?.split?.('_') || [area1];
		const [country2, region2] = area2?.split?.('_') || [area2];
		if (country1) {
			setSelectedCountry1({value: country1});
		}
		if (region1) {
			setSelectedRegion1({value: area1});
		}
		if (country2) {
			setSelectedCountry2({value: country2});
		}
		if (region2) {
			setSelectedRegion2({value: area2});
		}
	}, [activeSelectionKeys]);

	// Form validation
	useEffect(() => {
		let disabled = false;
		const [area1, area2] = activeSelectionKeys;
		const [savedCountry1, savedRegion1] = area1?.split?.('_') || [area1];
		const [savedCountry2, savedRegion2] = area2?.split?.('_') || [area2];

		console.log(
			'###',
			area1,
			area2,
			savedRegion1,
			savedRegion2,
			selectedRegion1,
			selectedRegion2,
		);
		if (
			activeComparisonType === activeComparisonTypeKey &&
			activeDatasetSetKey === activeDatasetSet &&
			_isEqual(activeDatasetKeys, activeDatasets) &&
			selectedCountry1?.value === savedCountry1 &&
			selectedCountry2?.value === savedCountry2 &&
			((savedRegion1 && selectedRegion1?.value === area1) ||
				(!savedRegion1 && !selectedRegion1)) &&
			((savedRegion2 && selectedRegion2?.value === area2) ||
				(!savedRegion2 && !selectedRegion2)) &&
			activePeriodPeriod === activePeriod
		) {
			disabled = true;
		}

		// Check if areas are selected correctly
		if (activeComparisonType === 'areas') {
			if (!selectedCountry1 || !selectedCountry2) {
				disabled = true;
			}
		} else {
			if (!selectedCountry1) {
				disabled = true;
			}
		}
		setSaveDisabled(disabled);
	}, [
		activeComparisonType,
		selectedCountry1,
		selectedCountry2,
		selectedRegion1,
		selectedRegion2,
		activeDatasets,
		activeDatasetSet,
		activePeriod,
		activeSelectionKeys,
		activeComparisonTypeKey,
		activeDatasetKeys,
		activeDatasetSetKey,
		activePeriodPeriod,
	]);

	// Set active dataset based on comparison type
	useEffect(() => {
		if (activeComparisonType === 'areas') {
			if (!activeDatasets) {
				setDatasets([datasets[0].key]);
			}
			if (activeDatasets?.length > 1) {
				setDatasets([activeDatasets[0]]);
			}
		} else {
			if (!activeDatasetSet) {
				setDatasetSet(datasetSets[0].key);
			}
		}
	}, [activeComparisonType, activeDatasetKeys, activeDatasetSetKey]);

	// Get selected areas
	const getAreasToSave = () => {
		let areasToSave = [];
		if (activeComparisonType === 'areas') {
			if (selectedRegion1) {
				areasToSave.push(selectedRegion1.value);
			} else if (selectedCountry1) {
				areasToSave.push(selectedCountry1.value);
			}
			if (selectedRegion2) {
				areasToSave.push(selectedRegion2.value);
			} else if (selectedCountry2) {
				areasToSave.push(selectedCountry2.value);
			}
		} else {
			if (selectedRegion1) {
				areasToSave.push(selectedRegion1.value);
			} else if (selectedCountry1) {
				areasToSave.push(selectedCountry1.value);
			}
		}
		return areasToSave;
	};
	const onBenchmarkSave = () => {
		const areasToSave = getAreasToSave();
		onSave(
			activeComparisonType,
			areasToSave,
			activeDatasets,
			activeDatasetSet,
			activePeriod,
		);
		onWindowClose();
	};

	return (
		<div className="unhab-BenchmarkConfiguration">
			<h2 className="unhab-BenchmarkConfiguration-title">
				Benchmark configuration
			</h2>
			<div className="unhab-BenchmarkConfiguration-content">
				<Form>
					<FormItem>
						<FormItemLabel>Comparison type</FormItemLabel>
						<ComparisonTypeSelector
							onChange={setComparisonType}
							models={comparisonTypes}
							activeKey={activeComparisonType}
						/>
					</FormItem>
					<FormItem>
						<FormItemLabel>
							{activeComparisonType === 'areas' ? 'Areas' : 'Area'}
						</FormItemLabel>
						<div className="unhab-BenchmarkConfiguration-areas">
							<div className="unhab-BenchmarkConfiguration-area">
								<Form small>
									<AreaPicker
										large
										countries={countries}
										selectedCountry={selectedCountry1}
										onCountryChange={setSelectedCountry1}
										selectedRegion={selectedRegion1}
										onRegionChange={setSelectedRegion1}
									/>
								</Form>
							</div>
							{activeComparisonType === 'areas' && (
								<div className="unhab-BenchmarkConfiguration-area">
									<Form small>
										<AreaPicker
											large
											countries={countries}
											selectedCountry={selectedCountry2}
											onCountryChange={setSelectedCountry2}
											selectedRegion={selectedRegion2}
											onRegionChange={setSelectedRegion2}
										/>
									</Form>
								</div>
							)}
						</div>
					</FormItem>
					<FormItem>
						{activeComparisonType === 'datasets' ? (
							<>
								<FormItemLabel>Datasets</FormItemLabel>
								<DatasetSetSelector
									models={datasetSets}
									activeKey={activeDatasetSet}
									onChange={setDatasetSet}
								/>
							</>
						) : (
							<>
								<FormItemLabel>Dataset</FormItemLabel>
								<DatasetSelect
									large
									forceSingle
									activeKeys={activeDatasets}
									models={datasets}
									setActiveKeys={setDatasets}
								/>
							</>
						)}
					</FormItem>
					<FormItem>
						<FormItemLabel>Period</FormItemLabel>
						<PeriodSelector
							defaultPeriod={defaultPeriod}
							activePeriod={activePeriod}
							setActivePeriod={setPeriod}
						/>
					</FormItem>
					<FormFooter>
						<Button onClick={onBenchmarkSave} primary disabled={saveDisabled}>
							Save & show
							<Icon icon="ri-arrow-right" />
						</Button>
					</FormFooter>
				</Form>
			</div>
		</div>
	);
};

BenchmarkConfiguration.propTypes = {
	activeComparisonTypeKey: PropTypes.string,
	activeDatasetKeys: PropTypes.array,
	activeDatasetSetKey: PropTypes.string,
	activeSelectionKeys: PropTypes.array,
	activePeriodPeriod: PropTypes.string,
	comparisonTypes: PropTypes.array,
	countries: PropTypes.object,
	datasets: PropTypes.array,
	datasetSets: PropTypes.array,
	onMount: PropTypes.func,
	onSave: PropTypes.func,
	onWindowClose: PropTypes.func,
	setActiveComparisonTypeKey: PropTypes.func,
	setActiveSelectionKeys: PropTypes.func,
};

export default BenchmarkConfiguration;
