import PropTypes from 'prop-types';
import {Fragment} from 'react';
import {
	ReactCompareSlider,
	ReactCompareSliderHandle,
} from 'react-compare-slider';

import {DeckGlMap, MapSet, PresentationMap} from '@gisatcz/ptr-maps';
import {connects} from '@gisatcz/ptr-state';
import MapWrapper from './MapWrapper';
import CompareMapsControl from './CompareMapsControl';
import LockMapsControl from './LockMapsControl';
import MapTooltip from './MapTooltip';
// import {MAX_MAPS_IN_MAP_SET} from '../../../constants/app';
import './style.scss';
import MapComponentsGroup from './MapComponentsGroup';

const ConnectedMap = connects.Map(PresentationMap);
const ConnectedMapSet = connects.MapSet(MapSet);

const Map = connects.Map(PresentationMap);

const Tooltip = props => {
	return <MapTooltip {...props}></MapTooltip>;
};

Tooltip.propTypes = {
	mapKey: PropTypes.string,
};

const Maps = ({activeMapSet, mode, updateView, unlocked, onLayerClick}) => {
	return (
		<div className="unhab-Map">
			{mode === 'compare' ? (
				<ReactCompareSlider
					onlyHandleDraggable
					handle={
						<>
							<ReactCompareSliderHandle
								buttonStyle={{position: 'absolute', top: 'calc(50% - 30px)'}}
							/>
							<MapComponentsGroup className="unhab-CompareMapsControl-wrapper">
								<CompareMapsControl
									mapSetKey={activeMapSet?.key}
									key={'CompareMapsControl'}
								/>
							</MapComponentsGroup>
						</>
					}
					className="unhab-CompareSlider"
					itemOne={
						<Map
							onLayerClick={onLayerClick}
							wrapper={MapWrapper}
							wrapperProps={{
								updateView,
								mapSetKey: activeMapSet?.key,
							}}
							mapComponent={DeckGlMap}
							stateMapKey={activeMapSet?.maps[0]}
							Tooltip={props => (
								<Tooltip {...props} mapKey={activeMapSet?.maps[0].key} />
							)}
							tooltipProps={{}}
						/>
					}
					itemTwo={
						<Map
							onLayerClick={onLayerClick}
							wrapper={MapWrapper}
							wrapperProps={{
								updateView,
								mapSetKey: activeMapSet?.key,
							}}
							mapComponent={DeckGlMap}
							stateMapKey={activeMapSet?.maps[1]}
							Tooltip={props => (
								<Tooltip {...props} mapKey={activeMapSet?.maps[1].key} />
							)}
							tooltipProps={{}}
						/>
					}
				></ReactCompareSlider>
			) : (
				<ConnectedMapSet
					onLayerClick={onLayerClick}
					stateMapSetKey={activeMapSet?.key}
					mapComponent={DeckGlMap}
					connectedMapComponent={ConnectedMap}
					wrapper={MapWrapper}
					wrapperProps={{
						updateView,
						mapSetKey: activeMapSet?.key,
					}}
					Tooltip={props => <Tooltip {...props} />}
					tooltipProps={
						{
							// 	componentsByLayer,
						}
					}
				>
					{activeMapSet?.maps?.length === 2 ? (
						<Fragment key={'map-compare-tools-wrapper'}>
							<MapComponentsGroup className="unhab-CompareMapsControl-wrapper">
								<CompareMapsControl
									mapSetKey={activeMapSet?.key}
									disabled={unlocked}
								/>
							</MapComponentsGroup>
							<MapComponentsGroup className="unhab-LockMapsControl-wrapper">
								<LockMapsControl mapSetKey={activeMapSet?.key} />
							</MapComponentsGroup>
						</Fragment>
					) : null}
				</ConnectedMapSet>
			)}
		</div>
	);
};

Maps.propTypes = {
	mode: PropTypes.any,
	activeMapSet: PropTypes.object,
	updateView: PropTypes.func,
	unlocked: PropTypes.bool,
	gridArea: PropTypes.string,
	onLayerClick: PropTypes.func,
};

export default Maps;
