import {connect} from '@gisatcz/ptr-state';
import Action from '../../../state/Action';
import Select from '../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = state => {
	const activeSelectionKeys =
		Select.selections.getActive(state)?.data?.featureKeysFilter?.keys;
	return {
		countries: Select.unhab.admStructure.getCountries(state),
		activeSelectionKeys,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.unhab.admStructure.ensureAreas());
		},
		onActiveFeatureChange: nextSelectedFeatureKeys => {
			dispatch(
				Action.selections.setActiveSelectionFeatureKeysFilterKeys(
					nextSelectedFeatureKeys,
				),
			);

			// Set map extent on selected features if nextSelectedFeatureKeys is filled.
			if (nextSelectedFeatureKeys.length > 0) {
				dispatch(
					Action.unhab.admStructure.fitZoomToSelected(
						nextSelectedFeatureKeys.slice(
							0,
							nextSelectedFeatureKeys.length - 1,
						),
						nextSelectedFeatureKeys[nextSelectedFeatureKeys.length - 1],
					),
				);
			}
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
