import {createSelector} from 'reselect';
import moment from 'moment';
import {Select} from '@gisatcz/ptr-state';
import datasetsSelectors from './datasets/selectors';
import {areaNameColumns} from '../../constants/app';

/**
 * Filter attributes by selected datasets
 * @param configuration {Object} Chart configuration
 */
const getFilteredAttributesByActiveDatasets = createSelector(
	[
		Select.attributes.getAllAsObject,
		datasetsSelectors.getActiveModels,
		(state, configuration) => configuration?.attributes,
	],
	(attributes, activeDatasets, componentAttributes) => {
		if (attributes && activeDatasets?.length && componentAttributes?.length) {
			const finalAttributes = [];
			componentAttributes.forEach(componentAttributeKey => {
				// Get parent dataset for given attribute
				const dataset = activeDatasets.find(activeDataset =>
					activeDataset.data?.attributes.some(
						attr => attr.key === componentAttributeKey,
					),
				);
				// Get attribute metadata
				const attribute = attributes[componentAttributeKey];
				if (dataset && attribute) {
					const attributeConfiguration = attribute?.data?.configuration;
					if (attributeConfiguration?.columnName) {
						finalAttributes.push({
							...attributeConfiguration,
							key: attributeConfiguration?.columnName,
							name: dataset.data.nameDisplay,
							color: dataset.data.color,
							period: dataset.data.period,
							unit: attribute?.data?.unit,
							type: attribute?.data?.type,
						});
					} else if (attributeConfiguration?.columnNameTemplate) {
						finalAttributes.push({
							template: attributeConfiguration?.columnNameTemplate,
							name: dataset.data.nameDisplay,
							color: dataset.data.color,
							period: dataset.data.period,
							unit: attribute?.data?.unit,
							type: attribute?.data?.type,
							...attributeConfiguration,
						});
					}
				}
			});

			return finalAttributes;
		} else {
			return null;
		}
	},
);

// helpers
function getAreaName(properties) {
	return properties?.[areaNameColumns[0]] || properties?.[areaNameColumns[1]];
}

/**
 * Get value for normalization
 * @param properties {Object} Feature properties
 * @param normalization {Object} Normalization definition
 * @returns {*|number}
 */
function getNormalizationAttributeValue(properties, normalization) {
	let value = properties?.[normalization?.attribute];
	if (value) {
		if (normalization?.multiplyBy) {
			return value / normalization?.multiplyBy;
		} else {
			return value;
		}
	} else {
		return 1;
	}
}

/**
 * Converts a filtered data object into a time series array with formatted timestamps.
 *
 * @param {Object} filteredDataAsObject - The filtered data object with period keys.
 * @param {number} normValue - value used for normalization
 * @returns {Array} - An array of time series data with formatted timestamps.
 */
function getFormattedTimeSerie(filteredDataAsObject, normValue) {
	if (filteredDataAsObject) {
		const timeSerie = [];

		// Iterate through the keys in the filteredDataAsObject.
		for (const key in filteredDataAsObject) {
			// Create a time series data object for each key.
			timeSerie.push({
				period: key, // The period key from the original data.
				x: moment.utc(key).format(), // Format the timestamp using moment.js.
				y: filteredDataAsObject[key]
					? filteredDataAsObject[key] / normValue
					: filteredDataAsObject[key], // The corresponding data value.
			});
		}

		// Return the time series array.
		return timeSerie;
	} else {
		// Return null if the input object is empty.
		return null;
	}
}

/**
 * Filter data object based on a template and extract keys from the original keys.
 *
 * @param {Object} data - The data object to filter.
 * @param {string} template - The template with a placeholder.
 * @returns {Object} - A filtered data object with extracted keys.
 */
function filterPropertiesByTemplate(data, template) {
	// Initialize an empty object to store the filtered data.
	const filteredData = {};

	// Create a regular expression pattern by replacing the placeholder with (.*).
	const regex = new RegExp(template.replace('{XXXX}', '(\\d+)'));

	// Iterate through the keys in the data object.
	for (const key in data) {
		// Try to match the key against the regex pattern.
		const match = key.match(regex);
		if (match) {
			// Use the captured part of the key as the new key in the filteredData object
			// and assign the corresponding value from the original data.
			filteredData[match[1]] = data[key];
		}
	}

	// Return the filtered data object with extracted keys.
	return filteredData;
}

/**
 * Filter data object based on a template and period.
 *
 * @param {Object} data - The data object to filter.
 * @param {string} template - The template with a placeholder.
 * @param {string} period - The period in YYYY/YYYY format
 * @returns {Array} - Filtered time serie.
 */
function getPropertyValuesByTemplateAndPeriod(data, template, period) {
	// Get filtered properties by template
	const fullSerieAsObject = filterPropertiesByTemplate(data, template);
	if (fullSerieAsObject && period) {
		const [startYear, endYear] = period.split('/');
		if (startYear && endYear) {
			const values = [];
			const years = [];
			for (const key in fullSerieAsObject) {
				const year = parseInt(key);
				if (year >= startYear && year <= endYear) {
					values.push(fullSerieAsObject[key]);
					years.push(year);
				}
			}
			return values?.length ? [values, years] : null;
		}
	} else {
		return null;
	}
}

// helpers --------------------------------------------------

/**
 * Sums the values of an array using a loop.
 *
 * @param {number[]} array - The array of numbers to be summed.
 * @returns {number} - The sum of the array elements.
 */
function sumArray(array) {
	let sum = 0;
	for (let i = 0; i < array.length; i++) {
		sum += array[i];
	}
	return sum;
}

export default {
	getFilteredAttributesByActiveDatasets,

	getAreaName,
	getNormalizationAttributeValue,
	getFormattedTimeSerie,
	filterPropertiesByTemplate,
	getPropertyValuesByTemplateAndPeriod,

	// helpers
	sumArray,
};
