import SidePanel, {
	SidePanelBody,
	SidePanelHeader,
	SidePanelHeaderItem,
} from '../../../common/SidePanel';
import './style.scss';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import SelectedFeaturesControl from '../../../common/SelectedFeaturesControl';
import AnalyticsPanelStatistics from '../../../common/AnalyticsPanelStatistics';
import SelectedFeatureLabel from './SelectedFeatureLabel';
import DatasetSelect from '../../../common/DatasetSelect';
import PeriodSelector from '../../../common/PeriodSelector';
import {defaultPeriod} from '../../../../constants/app';

const AnalyticsPanel = ({
	defaultOpen,
	open,
	setOpen,
	selectedFeatureKeys,
	onSelectedFeatureKeysChange,
}) => {
	useEffect(() => {
		if (setOpen && defaultOpen) {
			setOpen(true);
		}
	}, []);

	useEffect(() => {
		if (!open && selectedFeatureKeys) {
			setOpen(true);
		}
		onSelectedFeatureKeysChange();
	}, [selectedFeatureKeys]);

	return (
		<SidePanel open={open} setOpen={setOpen} className="unhab-AnalyticsPanel">
			<SidePanelHeader>
				<SidePanelHeaderItem title="Areas">
					<SelectedFeaturesControl
						showAddButton={true}
						FeatureLabel={SelectedFeatureLabel}
					/>
				</SidePanelHeaderItem>
				<SidePanelHeaderItem title="Datasets">
					<DatasetSelect />
				</SidePanelHeaderItem>
				<SidePanelHeaderItem title="Period">
					<PeriodSelector defaultPeriod={defaultPeriod} />
				</SidePanelHeaderItem>
			</SidePanelHeader>
			<SidePanelBody>
				<AnalyticsPanelStatistics />
			</SidePanelBody>
		</SidePanel>
	);
};

AnalyticsPanel.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	defaultOpen: PropTypes.bool,
	selectedFeatureKeys: PropTypes.array,
	onSelectedFeatureKeysChange: PropTypes.func,
};

export default AnalyticsPanel;
