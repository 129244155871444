import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';

const componentId = 'AnalyticsPanel';

const mapStateToProps = state => {
	return {
		open: Select.components.get(state, componentId, 'open'),
		selectedFeatureKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setOpen: open => dispatch(Action.components.set(componentId, 'open', open)),
		onSelectedFeatureKeysChange: () =>
			dispatch(Action.unhab.features.useSelectedFeatures()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
