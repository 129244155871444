import PropTypes from 'prop-types';
import SidePanel, {SidePanelBody} from '../../../common/SidePanel';
import './style.scss';
import {useEffect} from 'react';
import AnalyticsPanelStatistics from '../../../common/AnalyticsPanelStatistics';

const BenchmarkSidePanel = props => {
	useEffect(() => {
		if (props.setOpen && props.defaultOpen) {
			props.setOpen(true);
		}
	}, []);

	return (
		<SidePanel {...props} className="unhab-BenchmarkSidePanel">
			<SidePanelBody>
				<AnalyticsPanelStatistics />
			</SidePanelBody>
		</SidePanel>
	);
};

BenchmarkSidePanel.propTypes = {
	setOpen: PropTypes.func,
	defaultOpen: PropTypes.bool,
};

export default BenchmarkSidePanel;
