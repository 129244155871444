import PropTypes from 'prop-types';
import Link from '../Link';
import ViewSelect from './ViewSelect';
import MapLayoutControl from '../Map/MapLayoutControl';
import DarkModeControl from './DarkModeControl';
import {getRouter} from '../../../router';
import Logo from './Logo';
import XCubeHeader from '../../pages/XCube/XCubeHeader';
import BenchmarkHeader from '../../pages/Benchmark/BenchmarkHeader';

import './style.scss';

const getContentByViewKey = (viewKey, mapSetKey) => {
	switch (viewKey) {
		case '6f59d02b-7055-4292-ba4a-939d616f9ea9':
			return (
				<>
					<MapLayoutControl mapSetKey={mapSetKey} />
				</>
			);
		case 'a068c9c2-3dbf-4818-87e6-03418bc467a8':
			return <BenchmarkHeader />;
		default:
			return null;
	}
};

const Header = ({activeViewKey, mapSetKey, isXCubeApp, visatUrl}) => {
	const router = getRouter();
	return (
		<header className={'unhab-Header'}>
			<div className="unhab-Header-nav">
				{isXCubeApp ? (
					<XCubeHeader visatUrl={visatUrl} />
				) : (
					<>
						<Link
							ignoreHistory={true}
							router={router}
							name={'home'}
							paramsFilter={['viewKey']}
						>
							<Logo />
						</Link>
						<ViewSelect />
					</>
				)}
			</div>
			<div className="unhab-Header-content">
				<div className={'unhab-Header-content-left'}>
					{isXCubeApp ? null : getContentByViewKey(activeViewKey, mapSetKey)}
				</div>
				<div className={'unhab-Header-content-right'}>
					<DarkModeControl />
				</div>
			</div>
		</header>
	);
};

Header.propTypes = {
	activeViewKey: PropTypes.string,
	isXCubeApp: PropTypes.bool,
	visatUrl: PropTypes.string,
	mapSetKey: PropTypes.string,
};

export default Header;
