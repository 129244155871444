import PropTypes from 'prop-types';
import ReactDragListView from 'react-drag-listview';
import DataLayersGroup from '../components/DataLayersGroup';
import SortLayersItem from './SortLayersItem';
import './style.scss';

const SortLayersControl = ({
	activeLayers = [],
	onSort,
	mapKey,
	SortLayerComponent,
}) => {
	if (activeLayers?.length > 0) {
		const layers = [...(activeLayers ? activeLayers : [])].reverse();
		return (
			<DataLayersGroup
				title="Selected layers"
				open={true}
				highlighted
				classes="unhab-DataLayersGroup-selected"
			>
				<ReactDragListView
					nodeSelector="li"
					handleSelector="a"
					onDragEnd={onSort}
					lineClassName="unhab-SortLayerControl-line"
				>
					<ol className="unhab-SortLayerControl-list">
						{layers.map((layer, index) => (
							<SortLayersItem
								data={layer}
								key={index}
								mapKey={mapKey}
								SortLayerComponent={SortLayerComponent}
							/>
						))}
					</ol>
				</ReactDragListView>
			</DataLayersGroup>
		);
	}
};

SortLayersControl.propTypes = {
	activeLayers: PropTypes.array,
	onSort: PropTypes.func,
	mapKey: PropTypes.string,
	SortLayerComponent: PropTypes.node,
};

export default SortLayersControl;
