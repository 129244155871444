const ghsPopupationLayerCommonOptions = {
	unit: 'habitants',
	useChannel: 1,
	useHeatMap: true,
	colorScale: [
		'#e7e1ef',
		'#d4b9da',
		'#c994c7',
		'#df65b0',
		'#e7298a',
		'#ce1256',
		'#91003f',
	],
	colorScaleValueRange: [0, 5, 20, 100, 300, 500, 1000, 5000],
	clipLow: 0,
	clipHigh: 50000,
};

export default {
	wsfEvolution2019: {
		key: 'wsfEvolution2019',
		layerTemplateKey: '14541dda-8b6d-4934-b1a7-0d038dfe1321',
		options: {
			useHeatMap: true,
			colorScale: [
				'#D50000',
				'#DD2200',
				'#E54500',
				'#EE6700',
				'#F68900',
				'#FFAD00',
				'#ECBD00',
				'#D9CD00',
				'#C6DE00',
				'#B3EE00',
				'#A1FF00',
				'#80F200',
				'#60E500',
				'#40D800',
				'#30CB00',
				'#00BE00',
				'#00B923',
				'#00B546',
				'#00B069',
				'#00AC8C',
				'#00A8AF',
				'#008AB8',
				'#006CBE',
				'#004ECA',
				'#0030D7',
				'#0012DD',
				'#1910CD',
				'#320ABD',
				'#4B07AD',
				'#64037D',
				'#7E008E',
			],
			colorScaleValueRange: [
				1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996,
				1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008,
				2009, 2010, 2011, 2012, 2013, 2014, 2015,
			],
		},
	},
	wsf2019: {
		key: 'wsf2019',
		layerTemplateKey: '991880cd-795a-41d5-a416-47071f7ccc4e',
		options: {
			customLegend: {
				attributeValues: {
					'Settlement footprint': {
						color: '#ff0000',
						outlineWidth: 2,
					},
				},
			},
			useChannel: 1,
			useColorsBasedOnValues: true,
			colorsBasedOnValues: [[255, '#ff0000']],
		},
	},
	GHSBuiltUp: {
		key: 'GHSBuiltUp',
		layerTemplateKey: '8bbfcc16-5d14-43d7-94e4-c696da9e9a72',
		options: {
			unit: 'm2',
			useChannel: 1,

			useHeatMap: true,
			colorScale: ['#0d0887', '#7e03a8', '#cb4778', '#f89541', '#f0f921'],
			colorScaleValueRange: [1, 10000],
			clipLow: 0,
			clipHigh: 10000,
		},
	},
	GHSPopulation1975: {
		key: 'GHSPopulation1975',
		layerTemplateKey: 'ea44a22b-86fb-4088-9161-b1cbc148b52e',
		options: {...ghsPopupationLayerCommonOptions},
	},
	GHSPopulation1985: {
		key: 'GHSPopulation1985',
		layerTemplateKey: '4cb66ac4-f6f4-4b3a-ac8e-5e0886521a96',
		options: {...ghsPopupationLayerCommonOptions},
	},
	GHSPopulation1995: {
		key: 'GHSPopulation1995',
		layerTemplateKey: 'c84ea6ed-562f-40a7-808e-2e3ade45d552',
		options: {...ghsPopupationLayerCommonOptions},
	},
	GHSPopulation2005: {
		key: 'GHSPopulation2005',
		layerTemplateKey: 'bdb9d964-58e0-4f68-b47b-6fbb9247879b',
		options: {...ghsPopupationLayerCommonOptions},
	},
	GHSPopulation2015: {
		key: 'GHSPopulation2015',
		layerTemplateKey: '0992d168-1ea0-4a9f-a3bd-906a1fcb8927',
		options: {...ghsPopupationLayerCommonOptions},
	},
	GHSPopulation2025: {
		key: 'GHSPopulation2025',
		layerTemplateKey: '4b4a909f-95ed-4e32-94ed-779f4595ea95',
		options: {...ghsPopupationLayerCommonOptions},
	},
};
