import {createRoot} from 'react-dom/client';
import {Provider} from '@gisatcz/ptr-state';

import createStore from './state/Store';
import {App} from './app';

const {store} = createStore();

function renderApp() {
	const container = document.getElementById('root');
	const root = createRoot(container);
	root.render(
		<Provider store={store}>
			<App />
		</Provider>,
	);
}

renderApp();
