import Helmet from 'react-helmet';
import {AnalyticsProvider} from 'use-analytics';

import {connect, connects} from '@gisatcz/ptr-state';
import Action from './state/Action';

// base styles need to be imported before all components
import '@gisatcz/ptr-core/lib/styles/reset.css';
import '@gisatcz/ptr-core/lib/styles/base.scss';
import './styles/index.scss';

import analytics from './utils/analytics';
import {initRouter} from './router';

import {AppContainer} from '@gisatcz/ptr-components';

import {init as initCore} from './core';
import Router from './components/Router';

import {appKey} from './constants/app';
import PropTypes from 'prop-types';
import Select from './state/Select';

const path = process.env.PUBLIC_URL;

function init(Store, {absPath, isPreloaded, currentUrl, navHandler}) {
	/**
	 * Creates router instance that can be used to manipulat urls.
	 *
	 * App handler updates store with current page and it's up to views to react to the change.
	 * In case of url change, redux selector possibly retrieves different layers and passes them
	 * into some the component.
	 *
	 */
	initCore({
		router: initRouter(absPath, currentUrl, Store, isPreloaded, navHandler),
	});

	if (isPreloaded) {
		return;
	}

	Store.dispatch(Action.init(path));
}

const ConnectedAppContainer = connects.AppContainer(AppContainer);

const AppComponent = ({dark, isXCubeApp}) => {
	return (
		<AnalyticsProvider instance={analytics}>
			<Helmet
				defaultTitle={isXCubeApp ? 'CityDataCube Explorer' : 'UTEP | UNHABITAT'}
			/>
			<ConnectedAppContainer appKey={appKey} dark={dark}>
				<Router />
			</ConnectedAppContainer>
		</AnalyticsProvider>
	);
};

AppComponent.propTypes = {
	dark: PropTypes.bool,
	isXCubeApp: PropTypes.bool,
};
const mapStateToProps = state => {
	return {
		dark: Select.components.get(state, 'App', 'darkMode'),
		isXCubeApp: Select.isXCubeApp(),
	};
};

const App = connect(mapStateToProps)(AppComponent);

export {App, init};
