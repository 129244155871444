import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

const mapStateToProps = state => {
	return {
		selectionData: Select.selections.getActive(state)?.data,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onSetKeys: keys => {
			dispatch(Action.selections.setActiveSelectionFeatureKeysFilterKeys(keys));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
