import {connect} from '@gisatcz/ptr-state';
import Presentation from '../../../common/SelectedFeatureLabel';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import {areaNameColumns} from '../../../../constants/app';
import utils from '../../../../utils';

const mapStateToProps = (state, ownProps) => {
	const selectionData = Select.selections.getActive(state)?.data;
	const feature = Select.unhab.features.getByKey(state, ownProps.featureKey);
	const name =
		feature?.data?.[areaNameColumns[0]] ||
		feature?.data?.[areaNameColumns[1]] ||
		ownProps.featureKey;

	const color = utils.getSelectedFeaturePrimaryColor(
		feature?.key,
		selectionData,
	);
	return {
		name,
		color,
		title: 'Zoom to area',
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onClick: () => {
			return dispatch(
				Action.unhab.admStructure.fitActiveMapSetToAdmKey(ownProps.featureKey),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
