import PropTypes from 'prop-types';
import './style.scss';

const FormItemLabel = ({children}) => {
	return <label className="ptr-FormItemLabel">{children}</label>;
};

FormItemLabel.propTypes = {
	children: PropTypes.string.isRequired,
};

export default FormItemLabel;
