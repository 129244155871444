/**
 * For whatever reason the feature key in '123' format must be a number. However, the feature key in '123_123' format must be a string
 * @param key {string|number}
 */
function getKeyFormatted(key) {
	if (typeof key === 'number') {
		return key;
	} else if (typeof key === 'string') {
		const parts = key.split('_');
		if (parts.length === 2) {
			return key;
		} else {
			return Number(parts[0]);
		}
	} else {
		return null;
	}
}

export default {
	getKeyFormatted,
};
