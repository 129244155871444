import './style.scss';
import {Button} from '@gisatcz/ptr-atoms';
import PropTypes from 'prop-types';

const DarkModeControl = ({active, onModeChange}) => {
	return (
		<Button
			primary={active}
			ghost={!active}
			small
			icon="ri-dark-mode"
			onClick={() => onModeChange(!active)}
		>
			Dark mode
		</Button>
	);
};

DarkModeControl.propTypes = {
	active: PropTypes.bool,
	onModeChange: PropTypes.func,
};

export default DarkModeControl;
