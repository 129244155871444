import PropTypes from 'prop-types';
import './style.scss';
import classnames from 'classnames';

const Form = ({children, small}) => {
	const classes = classnames('ptr-Form', {
		'is-small': small,
	});

	return <div className={classes}>{children}</div>;
};

Form.propTypes = {
	small: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export default Form;
