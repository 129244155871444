export default {
	title: 'Minimal yearly increase',
	configuration: {
		attributes: [
			'79d77064-7d3f-11ee-b962-0242ac120002',
			'79d76f56-7d3f-11ee-b962-0242ac120002',
			'79d76e3e-7d3f-11ee-b962-0242ac120002',
			'79d76d08-7d3f-11ee-b962-0242ac120002',
		],
		type: 'minYearlyIncrease',
	},
};
