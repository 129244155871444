import theme from './_theme';

export default {
	type: 'timeProgressChart',
	title: 'Population density growth',
	subtitle: 'Yearly evolution [inh./sqkm]',
	configuration: {
		attributes: ['363d9e21-d608-462b-b021-d78b86e56b39'],
		normalization: {
			attribute: '4e05b390-0e33-41e1-949d-2d51616a4af7',
		},
		hideLegend: false,
		displayTimeFormat: 'YYYY',
		metadata: {
			extendLines: true,
			settings: {
				theme,
				colors: serie => serie.color,
				areaOpacity: 0.6,
				margin: {top: 5, right: 15, bottom: 35, left: 45},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: {
					format: value => value.toLocaleString(),
					tickValues: 4,
				},
				enableGridX: false,
				enableGridY: true,
				gridYValues: 4,
				gridXValues: 6,
				pointSize: 0,
				useMesh: true,
				animate: false,
				motionConfig: 'default',
				legends: [],
				enableSlices: 'x',
			},
		},
		overviewChartMetadata: {
			settings: {
				theme,
				colors: serie => serie.color,
				margin: {top: 5, right: 0, bottom: 5, left: 0},
				xScale: {
					type: 'time',
					format: '%Y-%m-%dT%H:%M:%S%Z',
				},
				yScale: {
					type: 'linear',
					min: 'auto',
				},
				axisTop: null,
				axisRight: null,
				axisLeft: null,
				axisBottom: null,
				enableGridX: false,
				enableGridY: false,
				pointSize: 0,
				useMesh: false,
				animate: false,
			},
		},
	},
};
