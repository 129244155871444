import {createSelector} from 'reselect';
import {Select as CommonSelect, commonSelectors} from '@gisatcz/ptr-state';

const getSubstate = state => state.unhab.features;
const getAllAsObject = state => state.unhab.features.byKey;
const getByKey = commonSelectors.getByKey(getSubstate);
const getByKeys = commonSelectors.getByKeys(getSubstate);

const getSelectedFeatures = createSelector(
	[CommonSelect.selections.getActive, getAllAsObject],
	(activeSelection, allFeatures) => {
		const selectedFeatureKeys = activeSelection?.data?.featureKeysFilter?.keys;
		if (selectedFeatureKeys?.length && allFeatures) {
			const features = [];
			selectedFeatureKeys.forEach(key => {
				const feature = allFeatures?.[key];
				if (feature) {
					features.push({...feature, key});
				}
			});

			return features?.length ? features : null;
		} else {
			return null;
		}
	},
);

export default {
	getSelectedFeatures,
	getByKey,
	getByKeys,
};
