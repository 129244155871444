import {commonActionTypes} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';

const specificActionTypes = utils.deepKeyMirror({
	UNHAB: {
		CONFIGURATION: {
			OPEN: {
				ADD: null,
				REMOVE: null,
			},
			UPDATE: null,
		},
		DATASETS: {
			ADD: null,
			SET_ACTIVE_KEYS: null,
		},
		DATASET_SETS: {
			ADD: null,
			SET_ACTIVE_KEY: null,
		},
		FEATURES: {
			ADD: null,
			UPDATE: null,
		},
		ADMSTRUCTURE: {
			ADD: null,
		},
		BENCHMARK_COMPARISON_TYPES: {
			SET_ACTIVE_KEY: null,
		},
	},
	XCUBE_LAYERS: {
		ADD: null,
		ADD_COLORBARS: null,
	},
	XCUBE_FEATURES: {
		ADD_AS_OBJECT: null,
		INCREMENT_INDEX: null,
		UPDATE: null,
	},
	XCUBE_TIME_SERIES: {
		ADD: null,
	},
});

export default {
	...commonActionTypes,
	...specificActionTypes,
};
