import PropTypes from 'prop-types';
import './style.scss';

/**
 *  Labeled item
 * @param label {string} label
 * @param children {React.node} content
 * @returns {JSX.Element}
 * @constructor
 */
const LabeledItem = ({label, children}) => {
	return (
		<div className="ptr-LabeledItem">
			<label>{label}</label>
			<div className="ptr-LabeledItem-content">{children}</div>
		</div>
	);
};

LabeledItem.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
};

export default LabeledItem;
