import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import ComponentRenderer from '../../ComponentRenderer';

const CompareMapsControl = ({maps, mapMode, setMapMode, disabled}) => {
	return (
		<ComponentRenderer
			component={'compareMaps'}
			configurationGroupKey={'mapSetTools'}
		>
			<Button
				primary={mapMode === 'compare'}
				disabled={maps?.length !== 2 || disabled}
				onClick={() => setMapMode(mapMode === 'compare' ? 'set' : 'compare')}
				icon="ri-compare"
				small
				floating
			>
				Slideover
			</Button>
		</ComponentRenderer>
	);
};

CompareMapsControl.propTypes = {
	maps: PropTypes.array,
	mapMode: PropTypes.string,
	disabled: PropTypes.bool,
	setMapMode: PropTypes.func,
};

export default CompareMapsControl;
