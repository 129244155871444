import {commonSelectors, Select as CommonSelect} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';
import {
	flatten as _flatten,
	intersection as _intersection,
	cloneDeep as _cloneDeep,
} from 'lodash';

const getSubstate = state => state.unhab.datasets;

const getAll = commonSelectors.getAll(getSubstate);
const getAllAsObject = commonSelectors.getAllAsObject(getSubstate);
const getActiveKeys = commonSelectors.getActiveKeys(getSubstate);
const getActiveModels = commonSelectors.getActiveModels(getSubstate);
const getByKey = commonSelectors.getByKey(getSubstate);

const filterGivenAttributeSetsByActiveDatasetsAttributes = createSelector(
	[CommonSelect.attributeSets.getByKeys, getActiveModels],
	(attributeSets, activeDatasets) => {
		if (attributeSets && activeDatasets?.length) {
			const allDatasetsAttributes = _flatten(
				activeDatasets.map(dataset => {
					return dataset.data.attributes.map(a => a.key || a);
				}),
			);
			return attributeSets?.filter(
				attributeSet =>
					_intersection(attributeSet?.data.attributes, allDatasetsAttributes)
						?.length > 0,
			);
		} else {
			return null;
		}
	},
);

const filterGivenAttributeSetsByDatasetKeyAttributes = createSelector(
	[
		CommonSelect.attributeSets.getByKeys,
		getAllAsObject,
		(state, attributeSetsKeys, datasetKey) => datasetKey,
	],
	(attributeSets, datasets, datasetKey) => {
		const dataset = datasets?.[datasetKey];

		if (attributeSets && dataset) {
			const allDatasetsAttributes = dataset.data.attributes.map(
				a => a.key || a,
			);

			const datasetAttributeSets = _cloneDeep(
				attributeSets?.filter(
					attributeSet =>
						_intersection(attributeSet?.data.attributes, allDatasetsAttributes)
							?.length > 0,
				),
			);
			datasetAttributeSets.forEach(datasetAttributeSet => {
				datasetAttributeSet.data.attributes = [
					...(datasetAttributeSet?.data.attributes.filter(attributeKey =>
						dataset.data.attributes.some(
							a => a.key === attributeKey && a.mapLayer,
						),
					) || []),
				];
			});
			return datasetAttributeSets;
		} else {
			return null;
		}
	},
);

export default {
	getSubstate,

	getAll,
	getAllAsObject,
	getActiveKeys,
	getActiveModels,
	getByKey,

	filterGivenAttributeSetsByActiveDatasetsAttributes,
	filterGivenAttributeSetsByDatasetKeyAttributes,
};
