import {createSelector} from 'reselect';
import {createCachedSelector} from 're-reselect';
import {
	Select,
	createRecomputeSelector,
	createRecomputeObserver,
} from '@gisatcz/ptr-state';
import globalDatasetLayers from '../../../data/layers/globalDatasetLayers';
import localDatasetLayers from '../../../data/layers/localDatasetLayers';
import outlinesLayers from '../../../data/layers/outlinesLayers';
import limassolAreas from '../../../data/layers/limassolAreas';
import indicatorLayers from '../../../data/layers/indicatorLayers';
import backgroundLayers from '../../../data/layers/backgroundLayers';
import {
	unhabIndicatorLayersRadioGroupKey,
	mapGlobalDatasets,
	mapLocalDatasets,
	statisticsGroupsAttributeSets,
} from '../../../constants/app';
import featuresSelectors from '../../xCubeFeatures/selectors';
import datasetsSelectors from '../datasets/selectors';

const getAvailableOutlinesLayers = createSelector(
	[
		() => {
			return outlinesLayers;
		},
	],
	outlinesLayers => {
		return Object.values(outlinesLayers) || null;
	},
);

const getGlobalIndicatorLayers = createSelector(
	[datasetsSelectors.getAll],
	datasets => {
		return datasets.filter(ds => mapGlobalDatasets.includes(ds.key));
	},
);

const getGlobalDatasetLayers = createSelector(
	[
		() => {
			return globalDatasetLayers;
		},
	],
	globalDatasetLayers => {
		return Object.values(globalDatasetLayers) || null;
	},
);

const getLocalDatasetLayers = createSelector(
	[
		() => {
			return localDatasetLayers;
		},
	],
	localDatasetLayers => {
		return Object.values(localDatasetLayers) || null;
	},
);

const getLocalIndicatorLayers = createSelector(
	[datasetsSelectors.getAll],
	datasets => {
		return datasets.filter(ds => mapLocalDatasets.includes(ds.key));
	},
);

const getAvailableXCubeAreas = createSelector(
	[featuresSelectors.getAll],
	features => {
		if (features) {
			return [
				{...limassolAreas, options: {...limassolAreas.options, features}},
			];
		} else {
			return [limassolAreas];
		}
	},
);

const getAvailableIndicatorLayers = createSelector(
	[
		() => {
			return indicatorLayers;
		},
	],
	indicatorLayers => {
		return Object.values(indicatorLayers) || null;
	},
);

const getAvailableLayersByAttributeKeys = createSelector(
	[attributes => attributes, getAvailableIndicatorLayers],
	(attributes, indicatorLayers) => {
		if (indicatorLayers && attributes) {
			const indicatorLayersByAttributSetKey = [];
			indicatorLayers.forEach(layer => {
				if (attributes?.includes(layer?.options?.attributes?.relative)) {
					indicatorLayersByAttributSetKey.push(layer);
				}
			});
			return indicatorLayersByAttributSetKey;
		} else {
			return null;
		}
	},
);

const getAttributeSetParentByAttributeKey = createCachedSelector(
	[Select.attributeSets.getAllAsObject, (state, attributeKey) => attributeKey],
	(attributeSets, attributeKey) => {
		statisticsGroupsAttributeSets;
		const parentAttributeSetKey = [...statisticsGroupsAttributeSets].find(
			atributeSetKey => {
				return attributeSets[atributeSetKey].data.attributes.includes(
					attributeKey,
				);
			},
		);
		return attributeSets[parentAttributeSetKey];
	},
)((state, attributeKey) => {
	return attributeKey;
});

const getAttributeKeyObserver = createRecomputeObserver((state, key) => {
	return Select.attributes.getByKey(state, key);
});

const getAttributeSetParentByAttributeKeyObserver = createRecomputeObserver(
	(state, key) => {
		return getAttributeSetParentByAttributeKey(state, key);
	},
);

const getLayerName = createCachedSelector(
	[layerData => layerData],
	layerData => {
		const attributeKey = layerData?.options?.attributes?.relative;
		if (attributeKey) {
			const attribute = getAttributeKeyObserver(attributeKey);
			const parentAttribute =
				getAttributeSetParentByAttributeKeyObserver(attributeKey);
			return `${parentAttribute?.data?.nameDisplay} [${attribute?.data?.nameDisplay}]`;
		} else {
			return (
				layerData?.metadata?.layerTemplate?.data?.nameDisplay ||
				layerData?.nameDisplay
			);
		}
	},
)(layerData => {
	return layerData.key;
});

const getMapLayersStateEnhancedObserver = createRecomputeObserver(
	(state, mapKey) => {
		return getMapLayersStateEnhanced(state, mapKey);
	},
);

const getMapActiveLayerNames = createRecomputeSelector(mapKey => {
	const activeLayers = getMapLayersStateEnhancedObserver(mapKey);
	return activeLayers?.map(getLayerName);
});

const getIndicatorLayerByKey = createRecomputeSelector(indicatorLayerKey => {
	return indicatorLayers[indicatorLayerKey];
});

const getActiveIndicatorLayerByMapKey = createSelector(
	[Select.maps.getLayersStateByMapKey],
	layers => {
		const layer = layers?.find(layer => {
			return layer?.radioGroup === unhabIndicatorLayersRadioGroupKey;
		});
		return layer ? {...layer} : null;
	},
);
const getAttributeByKeyObserver = createRecomputeObserver(
	Select.attributes.getByKey,
);

const getRelativeAttributeByIndicatorLayerKey = createRecomputeSelector(
	indicatorLayerKey => {
		const indicatorLayer = getIndicatorLayerByKey(indicatorLayerKey);
		const relativeAttributeKey = indicatorLayer?.options?.attributes?.relative;
		return getAttributeByKeyObserver(relativeAttributeKey);
	},
);

const getAbsoluteAttributeByIndicatorLayerKey = createRecomputeSelector(
	indicatorLayerKey => {
		const indicatorLayer = getIndicatorLayerByKey(indicatorLayerKey);
		const relativeAttributeKey = indicatorLayer?.options?.attributes?.absolute;
		return getAttributeByKeyObserver(relativeAttributeKey);
	},
);

const getLayerByLayerTemplateOrKey = createSelector(
	[
		Select.maps.getLayersStateByMapKey,
		(state, mapKey, layerKey) => layerKey,
		(state, mapKey, layerKey, layerTemplateKey) => layerTemplateKey,
	],
	(layers, layerKey, layerTemplateKey) => {
		const layer = layers?.find(layer => {
			return (
				(layer?.layerTemplateKey &&
					layerTemplateKey &&
					layer?.layerTemplateKey === layerTemplateKey) ||
				(layer?.key && layerKey && layer?.key === layerKey)
			);
		});

		return layer || null;
	},
);

const getLayerOpacity = createSelector(
	[getLayerByLayerTemplateOrKey],
	layer => {
		return !layer?.opacity && layer?.opacity !== 0 ? 1 : layer?.opacity;
	},
);

const isLayerLegendHidden = createSelector(
	[getLayerByLayerTemplateOrKey],
	layer => {
		return layer?.hideLegend;
	},
);

// enhance map layers state with connected metadata models
const getMapLayersStateEnhanced = createSelector(
	[Select.maps.getMapLayersStateByMapKey, Select.layerTemplates.getAllAsObject],
	(layers, layerTemplates) => {
		if (layers?.length) {
			return layers.map(layer => {
				const layerTemplate =
					layerTemplates?.[layer.layerTemplateKey] ||
					layer?.metadata?.layerTemplate;
				return {
					...layer,
					metadata: {
						layerTemplate,
					},
				};
			});
		} else {
			return null;
		}
	},
);

const getDefaultBackgroundLayerByAppMode = createSelector(
	[state => Select.components.get(state, 'App', 'darkMode')],
	darkModeActive => {
		return Object.values(backgroundLayers).find(l =>
			darkModeActive ? l.defaultDark : l.defaultLight,
		);
	},
);

export default {
	getLayerName,
	getAttributeSetParentByAttributeKey,
	getGlobalDatasetLayers,
	getLocalDatasetLayers,
	getAvailableOutlinesLayers,
	getAvailableIndicatorLayers,
	getAvailableXCubeAreas,
	getIndicatorLayerByKey,
	getGlobalIndicatorLayers,
	getLocalIndicatorLayers,
	getActiveIndicatorLayerByMapKey,
	getAvailableLayersByAttributeKeys,
	getRelativeAttributeByIndicatorLayerKey,
	getAbsoluteAttributeByIndicatorLayerKey,
	getLayerOpacity,
	getMapLayersStateEnhanced,
	getMapActiveLayerNames,
	isLayerLegendHidden,
	getDefaultBackgroundLayerByAppMode,
};
