import PropTypes from 'prop-types';
import Box, {BoxSubtitle, BoxTitle} from '../Box';
import ColorDotLabel from '../ColorDotLabel';

import './style.scss';

/**
 * Render a row in the Indicator Box component.
 *
 * @param {Object} children - The children components to be rendered in the row.
 * @return {JSX.Element} - The JSX element representing the row.
 */
const IndicatorBoxRow = ({children}) => {
	return <div className="unhab-IndicatorBoxRow">{children}</div>;
};

IndicatorBoxRow.propTypes = {
	children: PropTypes.node,
};

/**
 * Renders a component that displays the values inside an indicator box.
 *
 * @param {Object} children - The content to be displayed inside the indicator box.
 * @return {JSX.Element} - The rendered indicator box component.
 */
const IndicatorBoxValues = ({children}) => {
	return <div className="unhab-IndicatorBoxValues">{children}</div>;
};

IndicatorBoxValues.propTypes = {
	children: PropTypes.node,
};

/**
 * Renders a component that displays the value inside an indicator box.
 *
 * @param {number} originalValue - The original value.
 * @param {Object} valueFormat
 * @param {Object} valueFormat.decimals - The number of decimals to be displayed.
 * @param {Object} valueFormat.multiplier - The multiplier to be applied to the value.
 * @param {string} unit - The unit of the value.
 * @param {number} periodToShow - The period connected with the value
 * @return {JSX.Element} - The rendered indicator box component.
 */
const IndicatorBoxValue = ({
	originalValue,
	valueFormat,
	unit,
	periodToShow,
}) => {
	let valueContent = '-';
	if (originalValue || originalValue === 0) {
		const value = originalValue * (valueFormat?.multiplier || 1);
		const wholeNumber = Math.floor(value);
		const decimal = value % 1;
		valueContent = (
			<>
				{wholeNumber.toLocaleString()}
				<span>
					{decimal
						? `.${decimal.toFixed(valueFormat?.decimals || 2).split('.')[1]}`
						: ''}
				</span>
			</>
		);
	}
	return (
		<div className="unhab-IndicatorBoxValue">
			{periodToShow ? (
				<span className="unhab-IndicatorBoxPeriod">{periodToShow}:</span>
			) : null}
			<div>{valueContent}</div>
			{unit ? <span>{unit}</span> : null}
		</div>
	);
};

IndicatorBoxValue.propTypes = {
	originalValue: PropTypes.number,
	valueFormat: PropTypes.object,
	unit: PropTypes.string,
	periodToShow: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

/**
 * Calculates the valid period range based on the provided dataset period and current period.
 *
 * @param {Object} periodInfo - The input object containing datasetPeriod and currentPeriod.
 * @param {string} periodInfo.period - The dataset period in the format "start/end".
 * @param {string} periodInfo.currentPeriod - The current period in the format "start/end".
 * @returns {string|null} - The valid period range in the format "start-end" or null if no dataset period is provided.
 */
const PeriodInfo = ({period, currentPeriod}) => {
	if (period) {
		let [start, end] = period.split('/');
		let content = start && end ? `(${start}-${end})` : '';
		if (currentPeriod && start && end) {
			const [currentStart, currentEnd] = currentPeriod.split('/');
			if (currentStart > start) {
				start = currentStart;
			}
			if (currentEnd < end) {
				end = currentEnd;
			}
			if (currentStart >= end || currentEnd <= start) {
				content = ``;
			} else {
				content = `(${start}-${end})`;
			}
		}
		return <span>{content}</span>;
	} else {
		return null;
	}
};

PeriodInfo.propTypes = {
	period: PropTypes.string,
	currentPeriod: PropTypes.string,
};

/**
 * Renders an IndicatorBox component with the given title.
 *
 * @param {string} title - The title of the IndicatorBox.
 * @param {string} subtitle - The subtitle of the IndicatorBox.
 * @param {object} configuration - The configuration object for the IndicatorBox.
 * @param {Array} data - The data to be displayed in the IndicatorBox.
 * @return {JSX.Element} The rendered IndicatorBox component.
 */
const IndicatorBox = ({title, subtitle, configuration, data}) => {
	return data ? (
		<Box className={`unhab-IndicatorBox`}>
			<BoxTitle>
				{title} {subtitle ? <BoxSubtitle>({subtitle})</BoxSubtitle> : null}
			</BoxTitle>
			<div className="unhab-IndicatorBox-body">
				{data.map(row => {
					return (
						<IndicatorBoxRow key={row.id}>
							<ColorDotLabel color={row.color}>
								{row.name}
								<PeriodInfo {...row} />
							</ColorDotLabel>
							<IndicatorBoxValues>
								{row.data?.map((value, index) => {
									return (
										<IndicatorBoxValue
											key={value.id}
											unit={value.unit}
											originalValue={value.value}
											valueFormat={configuration.valuesFormat}
											periodToShow={index === 0 && value.periodToShow}
										/>
									);
								})}
							</IndicatorBoxValues>
						</IndicatorBoxRow>
					);
				})}
			</div>
		</Box>
	) : null;
};

IndicatorBox.propTypes = {
	data: PropTypes.array,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	configuration: PropTypes.object,
};

export default IndicatorBox;
