import PropTypes from 'prop-types';
import './style.scss';

const MapLegendTitle = ({unit, children}) => {
	return (
		<div className="ptr-MapLegendTitle">
			<div className="ptr-MapLegendTitle-name">{children}</div>
			{unit ? <span className="ptr-MapLegendTitle-unit">[{unit}]</span> : null}
		</div>
	);
};

MapLegendTitle.propTypes = {
	children: PropTypes.node,
	unit: PropTypes.string,
};

export default MapLegendTitle;
