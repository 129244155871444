export default {
	title: 'Total area of urban extent - maximum',
	configuration: {
		attributes: [
			'2f6aaf4e-7e22-11ee-b962-0242ac120002',
			'2f6aac2e-7e22-11ee-b962-0242ac120002',
			'2f6aa8be-7e22-11ee-b962-0242ac120002',
			'2f6aa7ce-7e22-11ee-b962-0242ac120002',
		],
		type: 'maximum',
	},
};
