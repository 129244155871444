import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactSelect, {components} from 'react-select';
import {IconTool} from '@gisatcz/ptr-atoms';
import scssVar from '../../../styles/_variables.scss';

import './style.scss';

const ClearIndicator = props => {
	return (
		<components.IndicatorsContainer
			{...props}
			innerProps={{
				...props.innerProps,
				onMouseDown: e => {
					e.stopPropagation();
					e.preventDefault();
				},
				onTouchEnd: e => {
					e.stopPropagation();
					e.preventDefault();
				},
			}}
		>
			{props.selectProps.isClearable && props.selectProps.value ? (
				<IconTool
					icon="close"
					onClick={() => props.selectProps.onChange(null)}
					small
				/>
			) : null}
		</components.IndicatorsContainer>
	);
};

ClearIndicator.propTypes = {
	innerProps: PropTypes.object,
	selectProps: PropTypes.object,
};

const Select = ({
	models,
	groupedModels,
	activeKey,
	activeKeys,
	className,
	lightweight,
	inverse,
	large,
	portaling,
	onChange,
	isDisabled,
	isClearable,
	isMulti,
	disableColors,
	placeholderText,
	formatGroupLabel,
	formatOptionLabel,
	components = {},
}) => {
	const classes = classnames('ptr-Select', {}, className);
	const borderColor = 'var(--base35)';
	const dot = (color = 'transparent') => ({
		alignItems: 'center',
		display: 'flex',

		':before': {
			backgroundColor: color,
			borderRadius: 10,
			content: '" "',
			display: 'block',
			marginRight: 5,
			height: 10,
			width: 10,
		},
	});

	let options, value;
	if (groupedModels) {
		let allOptions = [];
		options = groupedModels.map(group => {
			const options = group.options.map(v => ({
				...v,
				value: v.key,
				label: v.data.nameDisplay,
				color: v.data.color,
			}));
			allOptions = [...allOptions, ...options];

			return {
				label: group.label,
				options,
			};
		});

		value = activeKeys?.length
			? allOptions.filter(v => activeKeys.includes(v.value))
			: allOptions.find(v => v.value == activeKey);
	} else {
		options = models?.map(v => ({
			...v,
			value: v.key,
			label: v.data.nameDisplay,
			color: v.data.color,
		}));

		value = activeKeys?.length
			? options.filter(v => activeKeys.includes(v.value))
			: options.find(v => v.value == activeKey);
	}

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			background: state.isDisabled
				? 'var(--base15)'
				: lightweight
				? 'transparent'
				: 'var(--base0)',
			height: isMulti ? 'auto' : large ? '2rem' : '1.7rem',
			minHeight: 'auto',
			borderColor: state.isDisabled ? 'var(--base30)' : borderColor,
			borderWidth: lightweight ? 0 : provided.borderWidth,
			':hover': {
				borderColor: scssVar.borderColorBase,
				background: lightweight
					? inverse
						? 'var(--accent55)'
						: 'var(--accent05)'
					: provided.backgroundColor,
			},
		}),
		clearIndicator: (provided, state) => ({
			...provided,
			padding: '0 .25rem',
			paddingRight: lightweight ? '.1rem' : '.25rem',
			cursor: 'pointer',
			color:
				state.isFocused || lightweight
					? 'var(--accent50)'
					: scssVar.iconColorSecondary,
			':hover': {
				color: 'var(--base90)',
			},
		}),
		container: provided => ({
			...provided,
			fontSize: large ? '1rem' : '.8rem',
			borderRadius: '0.15rem',
		}),
		dropdownIndicator: (provided, state) => {
			let color = scssVar.iconColorSecondary;
			let hoverColor = 'var(--base90)';
			if (state.isFocused) {
				color = 'var(--accent60)';
			}
			if (state.isDisabled) {
				color = 'var(--base35)';
			}
			if (lightweight) {
				color = 'var(--accent50)';
				hoverColor = 'var(--accent60)';
				if (inverse) {
					color = 'var(--accent20)';
					hoverColor = 'var(--accent10)';
				}
			}

			return {
				...provided,
				padding: '0 .25rem',
				paddingRight: lightweight ? '.15rem' : '.25rem',
				cursor: 'pointer',
				color,
				':hover': {
					color: hoverColor,
				},
			};
		},
		indicatorSeparator: provided => ({
			...provided,
			width: 0,
		}),
		input: provided => ({
			...provided,
			fontWeight: '400',
			padding: 0,
		}),
		placeholder: (provided, state) => ({
			...provided,
			color: state.isDisabled ? 'var(--base35)' : provided.color,
		}),
		singleValue: provided => ({
			...provided,
			color: lightweight
				? inverse
					? 'var(--base0)'
					: 'var(--accent50)'
				: provided.color,
			fontWeight: '500',
		}),
		multiValue: provided => {
			return {
				...provided,
				color: 'var(--base90)',
				background: 'var(--base15)',
			};
		},
		multiValueLabel: (provided, {data}) => ({
			...provided,
			...(data.color &&
				!disableColors && {
					...dot(data.color),
				}),
			color: 'var(--base70)',
			fontSize: '0.8rem',
			padding: '3px 5px 1px',
		}),
		multiValueRemove: provided => ({
			...provided,
			color: 'var(--base50)',
			':hover': {
				backgroundColor: 'var(--base50)',
				color: 'var(--base0)',
				cursor: 'pointer',
			},
		}),
		valueContainer: provided => ({
			...provided,
			padding: isMulti ? '3px 3px' : '2px 5px 0px',
			paddingRight: lightweight ? 0 : '6px',
			cursor: 'text',
		}),
		option: (provided, state) => {
			return {
				...provided,
				cursor: 'pointer',
				padding: '8px 8px',
				fontSize: large ? '1rem' : '.8rem',
				color: state?.isSelected ? 'var(--base0)' : 'var(--base80)',
			};
		},
		menu: provided => ({
			...provided,
			border: '1px solid var(--base30)',
			zIndex: 99,
		}),
		menuPortal: provided => ({...provided, zIndex: 99999}),
	};

	return (
		<ReactSelect
			isDisabled={isDisabled}
			isClearable={isClearable}
			className={classes}
			theme={theme => ({
				...theme,
				colors: {
					...theme.colors,
					primary25: 'var(--accent20)',
					primary50: 'var(--accent30)',
					primary: 'var(--accent50)',
					neutral0: 'var(--base10)',
					neutral20: 'var(--base40)',
					neutral80: 'var(--base80)',
					neutral90: 'var(--base90)',
				},
			})}
			styles={customStyles}
			options={options}
			value={value || null}
			isMulti={isMulti}
			onChange={onChange}
			menuPortalTarget={portaling ? document.getElementById('ptr-app') : null}
			components={{...components}}
			placeholder={placeholderText}
			formatGroupLabel={formatGroupLabel}
			formatOptionLabel={formatOptionLabel}
		/>
	);
};

Select.propTypes = {
	className: PropTypes.string,
	lightweight: PropTypes.bool,
	large: PropTypes.bool,
	inverse: PropTypes.bool,
	models: PropTypes.array,
	groupedModels: PropTypes.array,
	activeKey: PropTypes.string,
	activeKeys: PropTypes.array,
	onChange: PropTypes.func,
	portaling: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isClearable: PropTypes.bool,
	isMulti: PropTypes.bool,
	disableColors: PropTypes.bool,
	placeholderText: PropTypes.string,
	formatGroupLabel: PropTypes.func,
	formatOptionLabel: PropTypes.func,
	components: PropTypes.object,
};
export default Select;
