import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../../state/Select';
import Action from '../../../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	const layerKey = ownProps?.data?.layerKey || ownProps?.data?.key;
	return {
		layerKey: layerKey,
		layerTemplateKey: ownProps?.data?.layerTemplateKey,
		opacity: Select.unhab.layers.getLayerOpacity(
			state,
			ownProps.mapKey,
			layerKey,
			ownProps?.data?.layerTemplateKey,
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: (opacity, layerKey, layerTemplateKey) => {
			dispatch(
				Action.unhab.layers.setLayerOpacity(
					ownProps.mapKey,
					layerKey,
					layerTemplateKey,
					opacity,
				),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
