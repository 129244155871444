import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const relativeAttribute =
		Select.unhab.layers.getRelativeAttributeByIndicatorLayerKey(
			ownProps.layerKey,
		);
	const absoluteAttribute =
		Select.unhab.layers.getAbsoluteAttributeByIndicatorLayerKey(
			ownProps.layerKey,
		);

	const absoluteValue =
		ownProps[absoluteAttribute?.data?.configuration?.columnName];
	const activeAreaTreeLevel = Select.unhab.getMapLevelByMapKey(
		state,
		ownProps.mapKey,
	);
	const activeAreaTreeLevelName = activeAreaTreeLevel?.data?.nameDisplay;
	return {
		activeAreaTreeLevelName,
		relativeAttribute,
		absoluteAttribute,
		absoluteValue,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
