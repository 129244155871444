export default [
	// Attribute sets for map and analytics/statistics sections
	{
		key: '4c9aa8c4-3d5b-4fd2-acb7-38026e074d91',
		data: {
			nameDisplay: 'Urbanization area evolution',
			attributes: [
				// WSF Evolution
				'08a819f3-d71b-4753-8929-65f67107dd22', // Land consumption progress
				'79d763c6-7d3f-11ee-b962-0242ac120002', // Total increase absolute
				'79d7629a-7d3f-11ee-b962-0242ac120002', // Total increase relative
				'79d76862-7d3f-11ee-b962-0242ac120002', // Average increase absolute
				'79d7674a-7d3f-11ee-b962-0242ac120002', // Average increase relative
				'79d77064-7d3f-11ee-b962-0242ac120002', // Minimal increase absolute
				'79d76f56-7d3f-11ee-b962-0242ac120002', // Minimal increase relative
				'79d777f8-7d3f-11ee-b962-0242ac120002', // Maximal increase absolute
				'79d773b6-7d3f-11ee-b962-0242ac120002', // Maximal increase relative
				'ca15b319-d332-49ca-870c-b9240bd340a2', // Land consumption rate

				// GHS Built-up
				'a0cd644a-9e43-4820-aa2d-7a9efa6e0f6f', // Land consumption progress
				'79d7615a-7d3f-11ee-b962-0242ac120002', // Total increase absolute
				'79d75e58-7d3f-11ee-b962-0242ac120002', // Total increase relative
				'79d7661e-7d3f-11ee-b962-0242ac120002', // Average increase absolute
				'79d764f2-7d3f-11ee-b962-0242ac120002', // Average increase relative
				'79d76e3e-7d3f-11ee-b962-0242ac120002', // Minimal increase absolute
				'79d76d08-7d3f-11ee-b962-0242ac120002', // Minimal increase relative
				'79d77294-7d3f-11ee-b962-0242ac120002', // Maximal increase absolute
				'79d77190-7d3f-11ee-b962-0242ac120002', // Maximal increase relative
				'c09bac02-f276-4ea4-a5f6-e090a8313454', // Land consumption rate
			],
		},
	},
	{
		key: '59b06cf6-278f-4d4b-8de7-c929ba94ebef',
		data: {
			nameDisplay: 'Urban area',
			attributes: [
				// WSF 2019
				'be396866-7981-4c2c-a90a-f9b23ce9e158', // Urban area, total, absolute
				'b0c2f72d-3977-46a9-bad4-983e7af73e2e', // Urban area, total, relative

				// WSF Evolution
				'2f6aa6e8-7e22-11ee-b962-0242ac120002', // Urban extent area, minimum absolute
				'2f6aa60c-7e22-11ee-b962-0242ac120002', // Urban extent area, minimum relative
				'2f6aaf4e-7e22-11ee-b962-0242ac120002', // Urban extent area, maximum absolute
				'2f6aac2e-7e22-11ee-b962-0242ac120002', // Urban extent area, maximum relative,

				// GHS Built-up
				'2f6aa508-7e22-11ee-b962-0242ac120002', // Urban extent area, minimum absolute
				'2f6aa260-7e22-11ee-b962-0242ac120002', // Urban extent area, minimum relative
				'2f6aa8be-7e22-11ee-b962-0242ac120002', // Urban extent area, maximum absolute
				'2f6aa7ce-7e22-11ee-b962-0242ac120002', // Urban extent area, maximum relative

				// WSF 2019 next gen (4 countries)
				'ae9e3200-5d4c-4802-ac66-d27110c7f1d2', // Urban area, total, absolute
				'528b0767-669d-465a-a2b7-484fb0da5309', // Urban area, total, relative
			],
		},
	},
	{
		key: '731cdec7-27cd-483e-895f-4eb6d3bf312f',
		data: {
			nameDisplay: 'Population',
			attributes: [
				'363d9e21-d608-462b-b021-d78b86e56b39',
				'ab4fe885-65db-4843-b43b-3c16693a9ac7', // Population evolution total, absolute
				'07c38b31-9fdb-463e-b917-dbbafaa01f7a', // Population evolution total, relative
				'cbd8cc55-5a1c-4492-892b-86e87ed63872', // Population evolution average, absolute
				'ff258f32-5a90-4b51-a760-17a6d6e2b704', // Population evolution average, relative
				'6da76d87-977e-4b50-9bfa-3190c28f8a20', // Population evolution minimum, absolute
				'e1e76504-3a30-48fb-936b-1830557ad812', // Population evolution minimum, relative
				'08630acd-7ecf-4e90-bcaf-b0af90ca52e4', // Population evolution maximum, absolute
				'd5e6c9c3-78a0-454d-9d0f-0cdb83060e9a', // Population evolution maximum, relative
				'fedcb00a-92dc-4a03-acf0-a63040373b9f', // Population Growth Rate
				'3483d7cc-1cde-4a98-b09a-7dddc0ee16f4', // Land Consumption Rate/Population Growth Rate
				'bdb973bf-d693-4a1d-a64a-d71ce5f7c571', // Land Consumption Per Capita (GHS)
				'86fc0cb4-cd4d-4286-bd12-68d4db1969c5', // Land Consumption Per Capita (WSF next gen)
				'76b858f6-a289-4fa2-916a-7fb0bbe13f25', // Total Population (WSF 2019 next gen)
			],
		},
	},
];
