export default [
	{
		key: 'wsfEvolution_ghsBuiltUpSurface',
		data: {
			datasets: ['wsfEvolution', 'ghsBuiltUpSurface'],
		},
	},
	{
		key: 'wsf2019_wsf2019nextGen',
		data: {
			datasets: ['wsf2019', 'wsf2019nextGen'],
		},
	},
];
