import {Action as CommonAction} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';

import {defaultExploreAreaTreeLevelKey} from '../../constants/app';
import configuration from './configuration/actions';
import datasets from './datasets/actions';
import datasetSets from './datasetSets/actions';
import layers from './layers/actions';
import features from './features/actions';
import admStructure from './admStructure/actions';
import benchmark from './benchmark/actions';
import benchmarkComparisonTypes from './benchmarkComparisonTypes/actions';
import Select from '../Select';

// App specific actions
// import Select from '../Select';

const applyAndSetActive = (viewKey, Action) => {
	return (dispatch, getState) => {
		dispatch(CommonAction.views.applyAndSetActive(viewKey, Action)).then(() => {
			const view = Select.views.getByKey(getState(), viewKey);
			if (view?.data?.state?.unhab?.configuration) {
				dispatch(
					configuration.updateStateFromView(
						view.data.state.unhab.configuration,
					),
				);
			}
		});
	};
};

function setMapSetSync(mapSetKey, sync) {
	return dispatch => {
		let syncObject = {};
		if (sync) {
			syncObject = {
				roll: true,
				tilt: true,
				range: true,
				center: true,
				heading: true,
				boxRange: true,
			};
		}
		dispatch(CommonAction.maps.setMapSetSync(mapSetKey, syncObject));
	};
}
function setMapsCount(mapSet, mapsCount) {
	return (dispatch, getState) => {
		const state = getState();
		const maps = Select.maps.getMapSetMaps(state, mapSet);
		let mapSetMapCount = (maps && maps.length) || 0;
		if (mapSetMapCount > mapsCount) {
			while (mapSetMapCount > mapsCount) {
				const lastMapKey = maps[mapSetMapCount - 1].key;
				dispatch(CommonAction.maps.removeMap(lastMapKey));
				mapSetMapCount = mapSetMapCount - 1;
			}
		} else if (mapSetMapCount < mapsCount) {
			while (mapSetMapCount < mapsCount) {
				const mapKeyToBeAdd = utils.uuid();

				const firstMapSetMapKey = Select.maps.getMapSetByKey(state, mapSet)
					.maps[0];
				const indicatorLayer =
					Select.unhab.layers.getActiveIndicatorLayerByMapKey(
						state,
						firstMapSetMapKey,
					);
				dispatch(CommonAction.maps.addMap({key: mapKeyToBeAdd}));

				if (indicatorLayer) {
					delete indicatorLayer.metadataModifiers;

					if (indicatorLayer.filterByActive) {
						indicatorLayer.filterByActive.areaTreeLevel = true;
					} else {
						indicatorLayer.filterByActive = {areaTreeLevel: true};
					}

					dispatch(
						CommonAction.maps.addMapLayers(mapKeyToBeAdd, [indicatorLayer]),
					);
				}

				dispatch(CommonAction.maps.addMapToSet(mapKeyToBeAdd, mapSet));
				dispatch(
					CommonAction.maps.setMapMetadataModifiers(mapKeyToBeAdd, {
						areaTreeLevelKey: defaultExploreAreaTreeLevelKey,
					}),
				);

				// set map background layer
				const backgroundLayer =
					Select.unhab.layers.getDefaultBackgroundLayerByAppMode(state);
				dispatch(
					CommonAction.maps.setMapBackgroundLayer(
						mapKeyToBeAdd,
						backgroundLayer,
					),
				);

				mapSetMapCount = mapSetMapCount + 1;
			}
		}
	};
}

function updateMapView(mapKey, place) {
	return dispatch => {
		if (place?.pantherMapView) {
			dispatch(
				CommonAction.maps.updateMapAndSetView(mapKey, place?.pantherMapView),
			);
		}
	};
}

function setActivePeriod(period) {
	return (dispatch, getState) => {
		const activePeriod = Select.periods.getActive(getState());
		dispatch(
			CommonAction.periods.add([
				{
					...activePeriod,
					data: {
						...activePeriod.data,
						period,
					},
				},
			]),
		);
	};
}

export default {
	applyAndSetActive,
	configuration,
	datasets,
	datasetSets,
	layers,
	features,
	admStructure,
	benchmark,
	benchmarkComparisonTypes,

	setMapsCount,
	setMapSetSync,
	updateMapView,

	setActivePeriod,
};
