import PropTypes from 'prop-types';
import DownloadChart from './DownloadChart';
import {useRef} from 'react';
import Box, {BoxSubtitle, BoxTitle} from '../Box';
import './style.scss';

const ChartWrapper = ({title, subtitle, children, data}) => {
	const chartRef = useRef();
	return (
		<Box className="unhab-Chart">
			<div className="unhab-Chart-header">
				<div className="unhab-Chart-names">
					<BoxTitle>{title}</BoxTitle>
					{subtitle ? <BoxSubtitle>{subtitle}</BoxSubtitle> : null}
				</div>
				<div className="unhab-Chart-tools">
					<DownloadChart chartRef={chartRef} data={data} label={title} />
				</div>
			</div>
			<div className="unhab-Chart-body">{children}</div>
		</Box>
	);
};

ChartWrapper.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	children: PropTypes.node,
	data: PropTypes.array,
};

export default ChartWrapper;
