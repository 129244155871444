import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
import Presentation from './presentation';
import {limassolAreasLayerKey} from '../../../../constants/app';

const mapStateToProps = state => {
	const mapSetKey = Select.maps.getActiveSetKey(state);
	const mapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);
	const layer = Select.maps.getLayerStateByLayerKeyAndMapKey(
		state,
		mapKey,
		limassolAreasLayerKey,
	);

	return {
		active: Select.components.get(state, 'AddPointControl', 'active'),
		disabled: !layer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setActive: active => {
			dispatch(Action.components.set('AddPointControl', 'active', active));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
