import PropTypes from 'prop-types';
import './style.scss';

const ColoredLabel = ({children, color}) => {
	return (
		<div
			className="ptr-ColoredLabel"
			style={{
				backgroundColor: color,
			}}
		>
			{children}
		</div>
	);
};

ColoredLabel.propTypes = {
	children: PropTypes.node,
	color: PropTypes.string,
};

export default ColoredLabel;
