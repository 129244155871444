import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		slideoverOn: Select.components.get(state, 'Maps', 'mode') === 'compare',
		noLayers:
			Select.maps.getMapLayersStateByMapKey(state, ownProps.mapKey)?.length ===
			0,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
