import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../state/Action';
import Select from '../../../state/Select';

const mapStateToProps = state => {
	return {
		activePeriod: Select.periods.getActive(state)?.data?.period,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setActivePeriod: period => {
			dispatch(Action.unhab.setActivePeriod(period));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
