import ActionTypes from '../../../constants/ActionTypes';
import Select from '../../Select';
import config from '../../../config';
import utils from '../../../utils';

function useSelectedFeatures() {
	return (dispatch, getState) => {
		const state = getState();

		// Active selection feature keys
		const selectedFeatureKeys =
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys;
		if (selectedFeatureKeys?.length) {
			selectedFeatureKeys.forEach(featureKey => dispatch(ensure(featureKey)));
		}
	};
}

function ensure(featureKey) {
	return (dispatch, getState) => {
		const state = getState();
		const existingFeature = Select.unhab.features.getByKey(state, featureKey);

		if (!existingFeature) {
			const url = `${config.featuresRepo}/${featureKey}.json`;

			return utils.request(url, 'GET', null, null).then(data => {
				if (data) {
					dispatch(actionAdd([{key: featureKey, data}]));
				} else {
					console.warn(`No data for feature ${featureKey}!`);
				}
			});
		}
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.FEATURES.ADD,
		data,
	};
};

export default {
	useSelectedFeatures,
};
