import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useState, useEffect} from 'react';
import {Button} from '@gisatcz/ptr-atoms';
import FormFooter from '../Form/FormFooter';
import Form from '../Form/Form';
import AreaPicker from '../AreaPicker';

import './style.scss';

const AddSelectedAreaControl = ({
	countries = {},
	activeSelectionKeys = [],
	onActiveFeatureChange,
	onMount,
	isOpen,
	onClose,
}) => {
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedRegion, setSelectedRegion] = useState(null);

	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	const setActiveFeature = () => {
		let featureKey = null;

		if (selectedRegion) {
			featureKey = selectedRegion?.value;
		} else if (selectedCountry) {
			featureKey = selectedCountry?.value;
		}

		// clear selection state
		setSelectedCountry(null);
		setSelectedRegion(null);

		onActiveFeatureChange([...activeSelectionKeys, featureKey]);
	};

	const disabled =
		!selectedCountry ||
		(activeSelectionKeys.includes(selectedCountry?.value) && !selectedRegion);

	const classes = classnames('unhab-AddSelectedAreaControl', {
		'is-open': isOpen,
	});

	return (
		<div className={classes}>
			<Form small>
				<AreaPicker
					countries={countries}
					selectedCountry={selectedCountry}
					selectedRegion={selectedRegion}
					onCountryChange={setSelectedCountry}
					onRegionChange={setSelectedRegion}
					keysToOmit={activeSelectionKeys}
				/>
				<FormFooter>
					<Button disabled={disabled} onClick={setActiveFeature} small primary>
						{'Add to selection'}
					</Button>
					<Button onClick={onClose} small>
						{'Close'}
					</Button>
				</FormFooter>
			</Form>
		</div>
	);
};

AddSelectedAreaControl.propTypes = {
	countries: PropTypes.object,
	onActiveFeatureChange: PropTypes.func,
	onMount: PropTypes.func,
	onClose: PropTypes.func,
	activeSelectionKeys: PropTypes.array,
	isOpen: PropTypes.bool,
};

export default AddSelectedAreaControl;
