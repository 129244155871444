import PropTypes from 'prop-types';
import LayerSettingsItem from '../LayerSettingsItem';
import {Toggle} from '@gisatcz/ptr-atoms';
import './style.scss';

const HideLegendControl = ({hidden, onChange}) => {
	return (
		<LayerSettingsItem
			title="Hide legend"
			key="hideLegend"
			onClick={() => onChange(!hidden)}
		>
			<Toggle notInteractive on={hidden} />
		</LayerSettingsItem>
	);
};

HideLegendControl.propTypes = {
	hidden: PropTypes.bool,
	onChange: PropTypes.func,
};

export default HideLegendControl;
