import PropTypes from 'prop-types';
import {useState} from 'react';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';

import './style.scss';

const DataLayersGroup = ({
	title,
	open = true,
	children,
	secondary,
	highlighted,
	closeable = true,
	classes,
}) => {
	const [isOpen, setOpen] = useState(open);
	const localClasses = classnames(
		`unhab-DataLayersGroup`,
		{
			'is-open': isOpen,
			'is-secondary': secondary,
			'is-highlighted': highlighted,
		},
		classes,
	);

	return (
		<div className={localClasses}>
			{title ? (
				<div
					className="unhab-DataLayersGroup-header"
					onClick={() => closeable && setOpen(!isOpen)}
					tabIndex={0}
				>
					<div className="unhab-DataLayersGroup-title">{title}</div>
					{closeable ? (
						<div className="unhab-DataLayersGroup-control">
							<Icon icon={isOpen ? 'ri-chevron-up' : 'ri-chevron-down'} />
						</div>
					) : null}
				</div>
			) : null}
			<div className="unhab-DataLayersGroup-content">{children}</div>
		</div>
	);
};

DataLayersGroup.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	open: PropTypes.bool,
	secondary: PropTypes.bool,
	highlighted: PropTypes.bool,
	closeable: PropTypes.bool,
	classes: PropTypes.string,
};

export default DataLayersGroup;
