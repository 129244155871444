export default {
	title: 'Total increase',
	configuration: {
		attributes: [
			'ab4fe885-65db-4843-b43b-3c16693a9ac7', // Population evolution total, absolute
			'07c38b31-9fdb-463e-b917-dbbafaa01f7a', // Population evolution total, relative
		],
		type: 'totalIncrease',
	},
};
