import PropTypes from 'prop-types';
import DataLayerControl from '../components/DataLayerControl';
import DataLayersGroup from '../components/DataLayersGroup';
import './style.scss';

const OutlinesLayersControl = ({activeLayers, availableLayers, mapKey}) => {
	return (
		<DataLayersGroup title="Outlines">
			{availableLayers?.map(layer => {
				const active = activeLayers?.find(
					activeLayer =>
						(activeLayer?.layerTemplateKey &&
							layer?.layerTemplateKey &&
							activeLayer?.layerTemplateKey === layer?.layerTemplateKey) ||
						(activeLayer?.key && layer?.key && activeLayer?.key === layer?.key),
				);

				return (
					<DataLayerControl
						key={layer.key}
						mapKey={mapKey}
						active={!!active}
						data={layer}
						type={'checkbox'}
						initialized={true} //should be false only for COG layers
					/>
				);
			})}
		</DataLayersGroup>
	);
};

OutlinesLayersControl.propTypes = {
	activeLayers: PropTypes.array,
	availableLayers: PropTypes.array,
	mapKey: PropTypes.string,
};

export default OutlinesLayersControl;
