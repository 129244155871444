export default {
	title: 'Maximal yearly increase',
	configuration: {
		attributes: [
			'08630acd-7ecf-4e90-bcaf-b0af90ca52e4', // Population evolution maximum, absolute
			'd5e6c9c3-78a0-454d-9d0f-0cdb83060e9a',
		],
		type: 'maxYearlyIncrease',
	},
};
