export default {
	// wsfKenya: {
	// 	key: 'wsfKenya',
	// 	layerTemplateKey: '5a8bb648-44b4-44fc-abaa-9982bbbb9601',
	// 	options: {
	// 		useHeatMap: true,
	// 		colorScale: [
	// 			'#D50000',
	// 			'#DD2200',
	// 			'#E54500',
	// 			'#EE6700',
	// 			'#F68900',
	// 			'#FFAD00',
	// 			'#ECBD00',
	// 			'#D9CD00',
	// 			'#C6DE00',
	// 			'#B3EE00',
	// 			'#A1FF00',
	// 			'#80F200',
	// 			'#60E500',
	// 			'#40D800',
	// 			'#30CB00',
	// 			'#00BE00',
	// 			'#00B923',
	// 			'#00B546',
	// 			'#00B069',
	// 			'#00AC8C',
	// 			'#00A8AF',
	// 			'#008AB8',
	// 			'#006CBE',
	// 			'#004ECA',
	// 			'#0030D7',
	// 			'#0012DD',
	// 			'#1910CD',
	// 			'#320ABD',
	// 			'#4B07AD',
	// 			'#64037D',
	// 			'#7E008E',
	// 		],
	// 		colorScaleValueRange: [
	// 			1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996,
	// 			1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008,
	// 			2009, 2010, 2011, 2012, 2013, 2014, 2015,
	// 		],
	// 	},
	// },
	// wsfBolivia: {
	// 	key: 'wsfBolivia',
	// 	layerTemplateKey: '03d6c2b6-4ddb-42ab-875c-41b121b23194',
	// },
	// wsfChile: {
	// 	key: 'wsfChile',
	// 	layerTemplateKey: '8fe83535-1cd0-443d-8fc2-b2930135039c',
	// },
	// wsfMyanmar: {
	// 	key: 'wsfMyanmar',
	// 	layerTemplateKey: 'c34ba3d7-efe9-49ff-af70-399a35f16995',
	// },
	populationWsf2019: {
		key: 'populationWsf2019',
		layerTemplateKey: '8cf040d6-4f85-48de-9f0d-1cd4e0b18090',
		// cog explorer link
		// https://gisat-panther.github.io/app-gisat-cog-explorer/?cogUrl=https%3A%2F%2Fgisat-gis.eu-central-1.linodeobjects.com%2FesaUtepUnHabitat%2Frasters%2Fregional%2Fzoom3-4-3_Kenya2_COGeoN.tif&lon=36.81523393731118&lat=-1.3007372749694315&boxRange=593.3436509438837&useAutoRange=false&useChannel=0&clipLow=0&clipHigh=70&useColorsBasedOnValues=false&useSingleColor=false&useHeatMap=true&useDataForOpacity=false&colorScale=%5B%22%23fadd4b%22%2C%22%23eb9736%22%2C%22%23da5e23%22%2C%22%23c34817%22%2C%22%23a93a0e%22%2C%22%238b3309%22%2C%22%23692606%22%2C%22%23381301%22%5D&alpha=100&colorScaleValueRange=%5B0%2C5%2C10%2C15%2C20%2C30%2C40%2C70%5D&blurredTexture=false
		options: {
			unit: 'inh./px',
			clipHigh: 70,
			clipLow: 0,
			colorScale: [
				'#fadd4b',
				'#eb9736',
				'#da5e23',
				'#c34817',
				'#a93a0e',
				'#8b3309',
				'#692606',
				'#381301',
			],
			colorScaleValueRange: [0, 5, 10, 15, 20, 30, 40, 70],
			useChannel: 0,
		},
	},
	buildingsHeigthWsf2019: {
		key: 'buildingsHeigthWsf2019',
		layerTemplateKey: 'eae3d186-62f9-4c09-b420-bdcd1d57516a',
		// cog explorer link
		// https://gisat-panther.github.io/app-gisat-cog-explorer/?cogUrl=https%3A%2F%2Fgisat-gis.eu-central-1.linodeobjects.com%2FesaUtepUnHabitat%2Frasters%2Fregional%2Fzoom3-4-3_Kenya2_COGeoN.tif&lon=36.8266715740551&lat=-1.2952092897910317&boxRange=2653.392825196379&useAutoRange=false&useChannel=1&clipLow=0&clipHigh=110&useColorsBasedOnValues=false&useSingleColor=false&useHeatMap=true&useDataForOpacity=false&colorScale=%22%2371abd4%22%2C%22%2393d7fe%22%2C%22%23fff156%22%2C%22%23f77f1e%22%2C%22%23e85530%22%2C%22%23be3e10%22%2C%22%23952b0b%22%2C%22%2348080b%22%5D&alpha=100&colorScaleValueRange=%5B1%2C+5%2C+10%2C+15%2C+20%2C+40%2C+60%2C+110%5D&blurredTexture=false&clippedColor=&unidentifiedColor=&nullColor=&colorsBasedOnValues=
		options: {
			unit: 'avg buildings height m',
			clipHigh: 110,
			clipLow: -1,
			colorScale: [
				'#71abd4',
				'#93d7fe',
				'#fff156',
				'#f77f1e',
				'#e85530',
				'#be3e10',
				'#952b0b',
				'#48080b',
			],
			colorScaleValueRange: [0, 5, 10, 15, 20, 40, 60, 110],
			useChannel: 1,
		},
	},
};
