import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';
import PropTypes from 'prop-types';

/**
 * @param active {boolean}
 * @param onClick {function}
 * @returns {JSX.Element}
 */
const DataLayerControlSettings = ({active, onClick}) => {
	const classes = classnames('unhab-DataLayerControlSettings', {
		'is-active': active,
	});

	const onToolClick = () => {
		onClick(!active);
	};

	return (
		<div className={classes} onClick={onToolClick}>
			<Icon icon="ri-settings" />
		</div>
	);
};

DataLayerControlSettings.propTypes = {
	active: PropTypes.bool,
	onClick: PropTypes.func,
};

export default DataLayerControlSettings;
