// import PropTypes from 'prop-types';
import ModalWindow from '../../../common/ModalWindow';
import {useState} from 'react';
import {Button} from '@gisatcz/ptr-atoms';
import LabeledItem from '../../../common/LabeledItem';
import PeriodSelector from '../../../common/PeriodSelector';
import BenchmarkConfiguration from '../BenchmarkConfiguration';
// import BenchmarkHeaderComparisonType from './BenchmarkHeaderComparisonType';
import BenchmarkHeaderAreas from './BenchmarkHeaderAreas';
import BenchmarkHeaderDatasets from './BenchmarkHeaderDatasets';
import {defaultPeriod} from '../../../../constants/app';
import './style.scss';

const BenchmarkHeader = () => {
	const [isConfigOpen, setConfigOpen] = useState(false);

	return (
		<div className="unhab-BenchmarkHeader">
			<div className="unhab-BenchmarkHeader-labels">
				{/*<LabeledItem label="Comparison">*/}
				{/*	<BenchmarkHeaderComparisonType />*/}
				{/*</LabeledItem>*/}
				<LabeledItem label="Areas">
					<BenchmarkHeaderAreas />
				</LabeledItem>
				<LabeledItem label="Datasets">
					<BenchmarkHeaderDatasets />
				</LabeledItem>
				<LabeledItem label="Period">
					<PeriodSelector narrow defaultPeriod={defaultPeriod} />
				</LabeledItem>
			</div>
			<div className="unhab-BenchmarkHeader-configButton">
				<Button
					icon="ri-settings"
					small
					ghost
					onClick={() => setConfigOpen(true)}
				>
					Configure benchmark
				</Button>
			</div>
			<ModalWindow isOpen={isConfigOpen} onClose={() => setConfigOpen(false)}>
				<BenchmarkConfiguration onWindowClose={() => setConfigOpen(false)} />
			</ModalWindow>
		</div>
	);
};

BenchmarkHeader.propTypes = {};

export default BenchmarkHeader;
