import PropTypes from 'prop-types';
import {compact as _compact} from 'lodash';
import MapConfigurationControl from '../MapConfigurationControl';
import DatasetLayersControl from './DatasetLayersControl';
import OutlinesLayersControl from './OutlinesLayersControl';
import IndicatorLayersControl from './IndicatorLayersControl';
import XCubeSortLayerComponent from './XCubeSortLayerComponent';
import XCubeAreasControl from './XCubeAreasControl';
import XCubeLayersControl from './XCubeLayersControl';
import SortLayersControl from './SortLayersControl';
import './style.scss';

const getContentByView = (view, mapKey) => {
	switch (view) {
		case 'explore':
		case 'benchmark':
		case 'report':
			return (
				<>
					<SortLayersControl mapKey={mapKey} />
					<OutlinesLayersControl mapKey={mapKey} />
					<IndicatorLayersControl mapKey={mapKey} />
					<DatasetLayersControl mapKey={mapKey} />
				</>
			);
		case 'xcube':
			return (
				<>
					<SortLayersControl
						mapKey={mapKey}
						SortLayerComponent={XCubeSortLayerComponent}
					/>
					<XCubeAreasControl mapKey={mapKey} />
					<XCubeLayersControl mapKey={mapKey} />
				</>
			);
		default:
			return null;
	}
};

const DataLayersControl = ({
	activeAppView,
	activeLayerNames,
	horizontalPosition,
	mapKey,
}) => {
	return (
		<MapConfigurationControl
			icon="ri-database"
			name="Data"
			horizontalPosition={horizontalPosition}
			detail={
				activeLayerNames?.length
					? _compact(activeLayerNames).reverse().join(', ')
					: null
			}
		>
			<div className="unhab-DataLayersControl">
				{getContentByView(activeAppView, mapKey)}
			</div>
		</MapConfigurationControl>
	);
};

DataLayersControl.propTypes = {
	activeAppView: PropTypes.string,
	activeLayerNames: PropTypes.array,
	activeLayers: PropTypes.array,
	horizontalPosition: PropTypes.string,
	mapKey: PropTypes.string,
};

export default DataLayersControl;
