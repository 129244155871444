/**
 * DEFAULT CONFIG VALUES FOR ALL APP INSTANCES
 *
 * Default values only.
 * Do not use this file for development, per-instance config, etc.
 */

export default {
	featuresRepo:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaUtepUnHabitat/data/features',
	admStructureUrl:
		'https://gisat-gis.eu-central-1.linodeobjects.com/esaUtepUnHabitat/data/structure.json',
};
