import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';
import {areaNameColumns} from '../../../../../constants/app';
import utils from '../../../../../utils';
import SelectedFeatureLabel from '../../../../common/SelectedFeatureLabel';

const mapStateToProps = (state, ownProps) => {
	const selectionData = Select.selections.getActive(state)?.data;
	const feature = Select.unhab.features.getByKey(state, ownProps.featureKey);
	const name =
		feature?.data?.[areaNameColumns[0]] ||
		feature?.data?.[areaNameColumns[1]] ||
		ownProps.featureKey;

	const color = utils.getSelectedFeaturePrimaryColor(
		feature?.key,
		selectionData,
	);
	return {
		name,
		color,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SelectedFeatureLabel);
