import PropTypes from 'prop-types';
import {createElement, forwardRef} from 'react';
import helpers from '../helpers';
import {compact as _compact} from 'lodash';
import './style.scss';

/**
 * @param ChartComponent {React.FunctionComponent}
 * @param chartComponentSettings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param data {Array} following nivo line chart input data format (https://nivo.rocks/line/)
 * @param metadata {Object}
 * @param metadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param [Tooltip] {React.FunctionComponent}
 * @param startDate {string} ISO 8601 date
 * @param endDate {string} ISO 8601 date
 * @param onHover {function} Callback on hover chart node.
 * @param displayTimeFormat {string} format od displayed time range
 * @returns {JSX.Element|null}
 */
// eslint-disable-next-line react/display-name
const TimeRangeMainChart = forwardRef(
	(
		{
			ChartComponent,
			chartComponentSettings,
			data,
			metadata,
			startDate,
			endDate,
			onHover,
			displayTimeFormat,
		},
		ref,
	) => {
		const dataByTimeRange = helpers.getDataByTimeRange(
			data,
			startDate,
			endDate,
			metadata?.extendLines,
		);

		const minValues =
			dataByTimeRange && metadata?.settings?.enableArea
				? _compact(
						dataByTimeRange.map(
							value =>
								value?.data.reduce((a, b) => {
									if (a.y < b.y) {
										return a;
									} else {
										return b;
									}
								}).y,
						),
				  )
				: null;

		return (
			<div className="ptr-TimeRangeMainChart" ref={ref}>
				{createElement(ChartComponent, {
					data: dataByTimeRange,
					metadata: metadata,
					onHover,
					customSettings: {
						...(chartComponentSettings ? {...chartComponentSettings} : {}),
						axisBottom: {
							format: helpers.getDateFormatBasedOnTimeRangeForAxisX(
								startDate,
								endDate,
								displayTimeFormat,
							),
							tickValues: 6,
						},
						enableArea: minValues?.length,
						areaBaselineValue:
							metadata.settings.enableArea && minValues?.length > 0
								? Math.min(...minValues)
								: undefined,
						xScale: {
							...metadata?.settings?.xScale,
							min: startDate,
							max: endDate,
						},
					},
				})}
			</div>
		);
	},
);

TimeRangeMainChart.propTypes = {
	ChartComponent: PropTypes.func,
	chartComponentSettings: PropTypes.object,
	data: PropTypes.array,
	metadata: PropTypes.object,
	Tooltip: PropTypes.func,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onHover: PropTypes.func,
	displayTimeFormat: PropTypes.string,
};

export default TimeRangeMainChart;
