import {distinctColours, limassolAreasSelectionKey} from '../../constants/app';

export default {
	key: limassolAreasSelectionKey,
	data: {
		colourPalette: distinctColours,
		distinctItems: true,
		featureKeysFilter: {
			keys: [],
		},
		style: {
			outlineWidth: 3,
			outlineColor: '#00ffff',
			fill: '#00ffff',
		},
	},
};
