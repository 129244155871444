import PropTypes from 'prop-types';
import AttribuSetLayers from '../components/AttribuSetLayers';
import DataLayersGroup from '../components/DataLayersGroup';
import './style.scss';

const DatasetLayerControls = ({title, sections, mapKey, datasetKey}) => {
	return (
		<DataLayersGroup title={title}>
			{sections?.map(section => {
				return (
					<AttribuSetLayers
						key={section.key}
						attributeSetKey={section.key}
						mapKey={mapKey}
						datasetKey={datasetKey}
						attributes={section.data.attributes}
					/>
				);
			})}
		</DataLayersGroup>
	);
};

DatasetLayerControls.propTypes = {
	activeLayers: PropTypes.array,
	sections: PropTypes.array,
	mapKey: PropTypes.string,
	title: PropTypes.string,
	datasetKey: PropTypes.string,
};

export default DatasetLayerControls;
