import PropTypes from 'prop-types';
import {uniqBy as _uniqBy} from 'lodash';
import CogBitmapLegend from './legends/CogBitmapLegend';
import VectorLegend from './legends/VectorLegend';
import XCubeLegend from './legends/XCubeLegend';
import WMSLegend from './legends/WMSLegend';
import './style.scss';

const getLegendByType = (layerDefinition, layerState) => {
	const {type, legendType} = layerDefinition;
	const props = {layerDefinition, layerState};

	if (legendType) {
		switch (legendType) {
			case 'xcube':
				return <XCubeLegend key={props.layerState.key} {...props} />;
			default:
				return 'No legend for this layer available';
		}
	} else {
		switch (type) {
			case 'wms':
				return <WMSLegend key={props.layerState.key} {...props} />;
			case 'cogBitmap':
				return <CogBitmapLegend key={props.layerState.key} {...props} />;
			case 'mvt':
			case 'vector':
				return <VectorLegend key={props.layerState.key} {...props} />;
			default:
				return 'No legend for this layer available';
		}
	}
};

const MapLegend = ({mapLayersDefinitions, mapLayersStateEnhanced}) => {
	if (mapLayersDefinitions?.length) {
		// TODO get unique layers by layer.layerKey property for now. We suppose that there is same style for layers with same layerKey
		const uniqueLayers = _uniqBy(
			mapLayersDefinitions,
			layer => layer.layerKey,
		).reverse();
		const uniqueMapLayersStateEnhanced = _uniqBy(
			mapLayersStateEnhanced,
			layer => layer.key,
		).reverse();
		return (
			<div className="ptr-MapLegend">
				{uniqueLayers?.map((layer, index) => {
					const layerState = uniqueMapLayersStateEnhanced?.[index];
					if (layerState?.hideLegend) {
						return null;
					} else {
						return getLegendByType(
							layer,
							uniqueMapLayersStateEnhanced?.[index],
						);
					}
				})}
			</div>
		);
	} else {
		return null;
	}
};

MapLegend.propTypes = {
	mapLayersDefinitions: PropTypes.array,
	mapLayersStateEnhanced: PropTypes.array,
};

export default MapLegend;
