import PropTypes from 'prop-types';
import DataLayerControl from '../DataLayerControl';
import DataLayersGroup from '../DataLayersGroup';
import './style.scss';

const AuLayersControl = ({
	activeLayers,
	availableLayers = [],
	mapKey,
	attributeSet,
}) => {
	return (
		<DataLayersGroup
			title={attributeSet?.data?.nameDisplay}
			closeable={false}
			secondary
		>
			{availableLayers?.map(layer => {
				const active = activeLayers?.find(
					activeLayer =>
						(activeLayer?.layerTemplateKey &&
							activeLayer?.layerTemplateKey === layer?.layerTemplateKey) ||
						activeLayer?.key === layer?.key,
				);

				return (
					<DataLayerControl
						removeable={true}
						type={'radio'}
						key={layer.key}
						mapKey={mapKey}
						active={!!active}
						data={layer}
						activeLayers={activeLayers}
						attributeKey={layer?.options?.attributes?.relative}
						initialized={false} //should be false only for COG layers
					/>
				);
			})}
		</DataLayersGroup>
	);
};

AuLayersControl.propTypes = {
	activeLayers: PropTypes.array,
	availableLayers: PropTypes.array,
	mapKey: PropTypes.string,
	attributeSet: PropTypes.object,
};

export default AuLayersControl;
