export default {
	title: 'Total increase',
	configuration: {
		attributes: [
			'79d763c6-7d3f-11ee-b962-0242ac120002',
			'79d7629a-7d3f-11ee-b962-0242ac120002',
			'79d7615a-7d3f-11ee-b962-0242ac120002',
			'79d75e58-7d3f-11ee-b962-0242ac120002',
		],
		type: 'totalIncrease',
	},
};
