import {ResponsivePie} from '@nivo/pie';
import PropTypes from 'prop-types';
import './style.scss';

const CenteredMetric = ({dataWithArc, centerX, centerY, style, value}) => {
	return (
		<text
			x={centerX}
			y={centerY}
			textAnchor="middle"
			dominantBaseline="central"
			style={style}
		>
			{value ? value : dataWithArc?.[0]?.formattedValue}
		</text>
	);
};

CenteredMetric.propTypes = {
	dataWithArc: PropTypes.array,
	centerX: PropTypes.number,
	centerY: PropTypes.number,
	style: PropTypes.object,
	value: PropTypes.string,
};

const DonutChart = ({data, metadata, centeredMetricValue}) => {
	const {settings} = metadata;
	return (
		<div style={{width: '99%', height: '99%'}}>
			<ResponsivePie
				data={data}
				{...settings}
				// tooltip={data => {
				// 	return (
				// 		<div className="ptr-popup">
				// 			<div className="xCubeApp-nivo-tooltip">
				// 				<div className="xCubeApp-nivo-tooltip-content">
				// 					<h4>{data.datum.data.label}</h4>
				// 					<div className="xCubeApp-nivo-tooltip-item-alternative">
				// 						<span className="xCubeApp-nivo-tooltip-value">
				// 							{data.datum.data.originalValue?.toFixed(1)}
				// 						</span>
				// 						<span className="xCubeApp-nivo-tooltip-unit">sqkm</span>
				// 					</div>
				// 				</div>
				// 			</div>
				// 		</div>
				// 	);
				// }}
				layers={
					centeredMetricValue
						? [
								'arcs',
								'legends',
								'arcLabels',
								props => [
									<CenteredMetric
										key={'label'}
										style={{
											fontSize: '15px',
											fontWeight: 700,
											fontFamily: 'Roboto, sansSerif',
											fill: 'var(--base70)',
										}}
										value={centeredMetricValue}
										{...props}
									/>,
								],
						  ]
						: undefined
				}
				tooltip={() => {}}
			/>
		</div>
	);
};

DonutChart.propTypes = {
	data: PropTypes.array,
	metadata: PropTypes.object,
	centeredMetricValue: PropTypes.string,
};

export default DonutChart;
