import {Select as CommonSelect} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';
import configuration from './configuration/selectors';
import datasets from './datasets/selectors';
import datasetSets from './datasetSets/selectors';
import layers from './layers/selectors';
import features from './features/selectors';
import admStructure from './admStructure/selectors';
import benchmarkComparisonTypes from './benchmarkComparisonTypes/selectors';
import charts from './charts/selectors';
import indicators from './indicators/selectors';
import moment from 'moment/moment';

const getSubstate = state => state.unhab;

const getMapLevelByMapKey = createSelector(
	[
		CommonSelect.maps.getMapByKey,
		CommonSelect.areas.areaTreeLevels.getAllAsObject,
	],
	(map, levels) => {
		const activeMapLevelKey = map?.data?.metadataModifiers?.areaTreeLevelKey;
		return levels?.[activeMapLevelKey];
	},
);

const getCurrentDateRange = createSelector(
	[CommonSelect.periods.getActive],
	period => {
		if (period?.data?.period) {
			const [start, end] = period.data.period.split('/');
			const startString = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
			const endString = moment(end).format('YYYY-MM-DDTHH:mm:ssZ');
			return [startString, endString];
		} else {
			return null;
		}
	},
);

const hasMapSetSyncedLocation = createSelector(
	[CommonSelect.maps.getMapSetByKey],
	mapSet => {
		if (mapSet) {
			return mapSet.sync.center && mapSet.sync.boxRange;
		} else {
			return false;
		}
	},
);

export default {
	getSubstate,
	getViews: CommonSelect.views.getAll,
	getMapLevelByMapKey,
	getCurrentDateRange,
	hasMapSetSyncedLocation,

	configuration,
	datasets,
	datasetSets,
	layers,
	features,
	charts,
	indicators,
	admStructure,
	benchmarkComparisonTypes,
};
