import PropTypes from 'prop-types';
import DataLayerControl from '../components/DataLayerControl';
import DataLayersGroup from '../components/DataLayersGroup';
import './style.scss';

const DatasetLayersControl = ({
	activeLayers,
	globalLayers,
	localLayers,
	mapKey,
}) => {
	const isLayerActive = layer => {
		return activeLayers?.find(
			activeLayer =>
				(activeLayer?.layerTemplateKey &&
					layer?.layerTemplateKey &&
					activeLayer?.layerTemplateKey === layer?.layerTemplateKey) ||
				(activeLayer?.key && layer?.key && activeLayer?.key === layer?.key),
		);
	};

	return (
		<DataLayersGroup title="Dataset layers">
			<DataLayersGroup title="Global data">
				{globalLayers?.map(layer => {
					const active = isLayerActive(layer);

					return (
						<DataLayerControl
							key={layer.key}
							mapKey={mapKey}
							active={!!active}
							data={layer}
							type={'checkbox'}
							initialized={false} //should be false only for COG layers
						/>
					);
				})}
			</DataLayersGroup>
			<DataLayersGroup title="Future global data / 4 countries [FIXME]">
				{localLayers?.map(layer => {
					const active = isLayerActive(layer);

					return (
						<DataLayerControl
							key={layer.key}
							mapKey={mapKey}
							active={!!active}
							data={layer}
							type={'checkbox'}
							initialized={false} //should be false only for COG layers
						/>
					);
				})}
			</DataLayersGroup>
		</DataLayersGroup>
	);
};

DatasetLayersControl.propTypes = {
	mapKey: PropTypes.string,
	activeLayers: PropTypes.array,
	globalLayers: PropTypes.array,
	localLayers: PropTypes.array,
};

export default DatasetLayersControl;
