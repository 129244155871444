import './style.scss';
import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import PointControl from './PointControl';
import {
	limassolAreasNameColumnName,
	limassolAreasFidColumnName,
} from '../../../../../constants/app';
import {SidePanelHeaderItem} from '../../../../common/SidePanel';

const XCubePointsControl = ({features, onDeleteFeature}) => {
	const pointFeatures = features.filter(
		feature => feature?.properties?.[limassolAreasNameColumnName] === 'Point',
	);

	return (
		<SidePanelHeaderItem title={'Points'}>
			<div className="unhab-XCubePointsControl">
				{pointFeatures?.map(feature => {
					const title = feature?.properties?.[limassolAreasNameColumnName];
					const featureKey = feature?.properties?.[limassolAreasFidColumnName];
					return (
						<PointControl
							key={featureKey}
							name={`${title} (${featureKey})`}
							featureKey={featureKey}
							onRemove={() => onDeleteFeature('map-1', featureKey)}
						/>
					);
				})}
				{pointFeatures?.length > 1 ? (
					<Button
						small
						invisible
						icon="delete"
						onClick={() => onDeleteFeature('map-1', null)}
					>
						Delete all
					</Button>
				) : null}
			</div>
		</SidePanelHeaderItem>
	);
};

XCubePointsControl.propTypes = {
	FeatureControl: PropTypes.object,
	features: PropTypes.object,
	onDeleteFeature: PropTypes.func,
	selectionData: PropTypes.array,
};

export default XCubePointsControl;
