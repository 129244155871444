import PropTypes from 'prop-types';
import MapConfigurationControl from '../MapConfigurationControl';
import './style.scss';
import {useEffect} from 'react';

const FocusControlLevel = ({value, children, active, onChange}) => {
	return (
		<div className="unhab-FocusControlLevel">
			<input
				onChange={() => onChange(value)}
				type="radio"
				id={value}
				name={value}
				value={value}
				checked={active}
			/>
			<label htmlFor={value}>{children}</label>
		</div>
	);
};

FocusControlLevel.propTypes = {
	active: PropTypes.bool,
	children: PropTypes.node,
	onChange: PropTypes.func,
	value: PropTypes.string,
};

const FocusControl = ({
	activeLevel,
	levels,
	horizontalPosition,
	onLevelChange,
	mapKey,
}) => {
	useEffect(() => {
		if (!activeLevel && levels?.length) {
			onLevelChange(mapKey, levels[0].key);
		}
	}, []);

	return (
		<MapConfigurationControl
			icon="ri-focus"
			name="Focus"
			horizontalPosition={horizontalPosition}
			detail={activeLevel?.data?.nameDisplay}
		>
			<form className="unhab-FocusControl">
				{levels?.map(level => {
					return (
						<FocusControlLevel
							key={level.key}
							value={level.key}
							active={level.key === activeLevel?.key}
							onChange={levelKey => onLevelChange(mapKey, levelKey)}
						>
							{level.data?.nameDisplay}
						</FocusControlLevel>
					);
				})}
			</form>
		</MapConfigurationControl>
	);
};

FocusControl.propTypes = {
	activeLevel: PropTypes.object,
	horizontalPosition: PropTypes.string,
	levels: PropTypes.array,
	onLevelChange: PropTypes.func,
	mapKey: PropTypes.string,
};

export default FocusControl;
