import PropTypes from 'prop-types';
import Chart from '../Chart';

// Components
import StatisticsSection from './StatisticsSection';
import IndicatorBox from '../IndicatorBox';
import IndicatorBoxSet from '../IndicatorBox/IndicatorBoxSet';
import IndicatorFromTimeSerie from '../IndicatorBox/IndicatorFromTimeSerie';

// Indicators
import urbanizationAreaEvolution_total from '../../../data/indicators/urbanizationAreaEvolution_total';
import urbanizationAreaEvolution_average from '../../../data/indicators/urbanizationAreaEvolution_average';
import urbanizationAreaEvolution_minimumIncrease from '../../../data/indicators/urbanizationAreaEvolution_minimumIncrease';
import urbanizationAreaEvolution_maximumIncrease from '../../../data/indicators/urbanizationAreaEvolution_maximumIncrease';
import landConsumptionRate from '../../../data/indicators/landConsumptionRate';
import totalAreaOfUrbanExtent_minimum from '../../../data/indicators/totalAreaOfUrbanExtent_minimum';
import totalAreaOfUrbanExtent_maximum from '../../../data/indicators/totalAreaOfUrbanExtent_maximum';
import urbanArea_2019 from '../../../data/indicators/urbanArea_2019';
import populationEvolution_total from '../../../data/indicators/populationEvolution_total';
import populationEvolution_average from '../../../data/indicators/populationEvolution_average';
import populationEvolution_minimumIncrease from '../../../data/indicators/populationEvolution_minimumIncrease';
import populationEvolution_maximumIncrease from '../../../data/indicators/populationEvolution_maximumIncrease';
import populationGrowthRate from '../../../data/indicators/populationGrowthRate';
import landConsumptionRate_populationGrowthRate from '../../../data/indicators/landConsumptionRate_populationGrowthRate';
import landConsumptionPerCapita from '../../../data/indicators/landConsumptionPerCapita';
import totalPopulation_2019 from '../../../data/indicators/totalPopulation_2019';

// Charts
import urbanizationAreaEvolution from '../../../data/charts/urbanizationAreaEvolution';
import urbanizationAreaEvolutionShare from '../../../data/charts/urbanizationAreaEvolutionShare';
import urbanAreaShare from '../../../data/charts/urbanAreaShare';
import populationGrowth from '../../../data/charts/populationGrowth';
import populationDensityGrowth from '../../../data/charts/populationDensityGrowth';

import './style.scss';

const getContentBySectionKey = key => {
	switch (key) {
		// Urbanization area evolution
		case '4c9aa8c4-3d5b-4fd2-acb7-38026e074d91':
			return (
				<>
					<IndicatorBoxSet>
						<IndicatorFromTimeSerie {...urbanizationAreaEvolution_total} />
						<IndicatorFromTimeSerie {...urbanizationAreaEvolution_average} />
						<IndicatorFromTimeSerie
							{...urbanizationAreaEvolution_minimumIncrease}
						/>
						<IndicatorFromTimeSerie
							{...urbanizationAreaEvolution_maximumIncrease}
						/>
						<IndicatorFromTimeSerie {...landConsumptionRate} />
					</IndicatorBoxSet>
					<Chart {...urbanizationAreaEvolution} />
					<Chart {...urbanizationAreaEvolutionShare} />
				</>
			);

		// Urban area
		case '59b06cf6-278f-4d4b-8de7-c929ba94ebef':
			return (
				<>
					<IndicatorBoxSet>
						<IndicatorFromTimeSerie {...totalAreaOfUrbanExtent_minimum} />
						<IndicatorFromTimeSerie {...totalAreaOfUrbanExtent_maximum} />
						<IndicatorBox {...urbanArea_2019} />
					</IndicatorBoxSet>
					<Chart {...urbanAreaShare} />
				</>
			);

		// Population
		case '731cdec7-27cd-483e-895f-4eb6d3bf312f':
			return (
				<>
					<IndicatorBoxSet>
						<IndicatorFromTimeSerie {...populationEvolution_total} />
						<IndicatorFromTimeSerie {...populationEvolution_average} />
						<IndicatorFromTimeSerie {...populationEvolution_minimumIncrease} />
						<IndicatorFromTimeSerie {...populationEvolution_maximumIncrease} />
						<IndicatorFromTimeSerie {...populationGrowthRate} />
						<IndicatorFromTimeSerie
							{...landConsumptionRate_populationGrowthRate}
						/>
						<IndicatorFromTimeSerie {...landConsumptionPerCapita} />
						<IndicatorBox {...totalPopulation_2019} />
					</IndicatorBoxSet>
					<Chart {...populationGrowth} />
					<Chart {...populationDensityGrowth} />
				</>
			);

		default:
			return null;
	}
};

const AnalyticsPanelStatistics = ({sections}) => {
	return (
		<div className="unhab-AnalyticsPanelStatistics">
			<div className="unhab-AnalyticsPanelStatistics-body">
				<div className="unhab-AnalyticsPanelStatistics-sections">
					{sections?.map(section => {
						const {key, data} = section;
						const chart = getContentBySectionKey(key);

						return chart ? (
							<StatisticsSection key={key} open title={data.nameDisplay}>
								{chart}
							</StatisticsSection>
						) : null;
					})}
				</div>
			</div>
		</div>
	);
};

AnalyticsPanelStatistics.propTypes = {
	sections: PropTypes.array,
};

export default AnalyticsPanelStatistics;
