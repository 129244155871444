import {createSelector} from 'reselect';
import {
	forIn as _forIn,
	groupBy as _groupBy,
	isArray as _isArray,
} from 'lodash';
import {Select} from '@gisatcz/ptr-state';
import utils from '../../../utils';
import selectorHelpers from '../selectorHelpers';
import featuresSelectors from '../features/selectors';
import calculations from './calculations';

/**
 * Get attribute metadata (with original value) together with original feature data grouped by feature
 * @param state {Object}
 * @param configuration {Object} Indicator configuration
 */
const getAttributesByFeatures = createSelector(
	[
		featuresSelectors.getSelectedFeatures,
		selectorHelpers.getFilteredAttributesByActiveDatasets,
	],
	(features, attributes) => {
		if (features?.length && attributes.length) {
			const data = [];
			features.forEach(feature => {
				const attributeData = [];
				attributes.forEach(attribute => {
					attributeData.push({
						...attribute,
						value: feature.data?.[attribute?.key],
						originalValue: feature.data?.[attribute?.key],
					});
				});
				data.push({
					originalFeatureData: feature,
					attributeData,
				});
			});
			return data;
		} else {
			return null;
		}
	},
);

/**
 * General selector for IndicatorBox (it doesn't count value from timeserie)
 * @param state {Object}
 * @param configuration {Object} Indicator configuration
 */
const getIndicatorData = createSelector(
	[getAttributesByFeatures, Select.selections.getActive],
	(features, activeSelection) => {
		return prepareDataForIndicatorBox(features, activeSelection?.data);
	},
);

/**
 * Calculate indicator data from timeserie for active period
 * @param state {Object}
 * @param configuration {Object} Indicator configuration
 */
const getIndicatorDataForActivePeriod = createSelector(
	[
		getAttributesByFeatures,
		Select.selections.getActive,
		Select.periods.getActive,
		(state, configuration) => configuration,
	],
	(features, activeSelection, period, configuration) => {
		if (features?.length && period) {
			const updatedFeatures = features.map(feature => {
				const {attributeData, originalFeatureData} = feature;
				const updatedAttributeData = attributeData.map(attribute => {
					if (
						attribute.sourceColumnNameTemplate ||
						attribute.sourceColumnNameTemplates
					) {
						let values;
						if (attribute.sourceColumnNameTemplates) {
							values = attribute.sourceColumnNameTemplates.map(template =>
								selectorHelpers.getPropertyValuesByTemplateAndPeriod(
									originalFeatureData?.data,
									template,
									period?.data?.period,
								),
							);
						} else {
							values = selectorHelpers.getPropertyValuesByTemplateAndPeriod(
								originalFeatureData?.data,
								attribute.sourceColumnNameTemplate,
								period?.data?.period,
							);
						}

						let realValues, periods;
						if (_isArray(values?.[0]?.[0])) {
							realValues = values?.map(value => value[0]);
							periods = values?.[0]?.[1];
						} else {
							realValues = values?.[0];
							periods = values?.[1];
						}

						let normalizationValue = null;
						if (attribute.normalizationColumnName) {
							normalizationValue =
								originalFeatureData.data[attribute.normalizationColumnName];
						}

						const calculatedValue = calculations.getValue(
							realValues,
							configuration.type,
							attribute.type,
							normalizationValue,
							periods,
						);

						let value, periodToShow;
						if (_isArray(calculatedValue)) {
							value = calculatedValue[0];
							periodToShow = calculatedValue[1];
						} else {
							value = calculatedValue;
						}

						return {
							...attribute,
							value,
							periodToShow,
						};
					} else {
						return attribute;
					}
				});

				return {
					originalFeatureData,
					attributeData: updatedAttributeData,
				};
			});

			return prepareDataForIndicatorBox(
				updatedFeatures,
				activeSelection?.data,
				period?.data?.period,
			);
		} else {
			return null;
		}
	},
);

// helpers ------------------------------------------------------------------------------------------------------

/**
 * Prepares data for the Indicator Box based on the given features,
 * selection data, and period.
 *
 * @param {Array} features - An array of features to prepare data for.
 * @param {Object} selectionData - The selection data to use for color picking.
 * @param {number} [period] - Current period
 * @returns {null|Array} - Returns an array of prepared data or null if no data is available.
 */
function prepareDataForIndicatorBox(features, selectionData, period) {
	const data = [];
	if (features?.length > 1) {
		features.forEach(feature => {
			const {attributeData, originalFeatureData} = feature;
			data.push({
				id: originalFeatureData?.key,
				name: selectorHelpers.getAreaName(originalFeatureData?.data),
				color: utils.getSelectedFeaturePrimaryColor(
					originalFeatureData?.key,
					selectionData,
				),
				data: attributeData,
			});
		});
		return data;
	} else if (features?.length === 1) {
		const attributeGroups = _groupBy(features[0].attributeData, 'name');

		_forIn(attributeGroups, (attributes, name) => {
			data.push({
				id: attributes?.[0].key,
				name,
				color: attributes?.[0].color,
				period: attributes?.[0].period,
				currentPeriod: period,
				data: attributes,
			});
		});

		return data;
	} else {
		return null;
	}
}

export default {
	getIndicatorData,

	getIndicatorDataForActivePeriod,
};
