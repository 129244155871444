import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../../../state/Action';

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		removeLayer: () => {
			dispatch(Action.maps.removeMapLayer(ownProps.mapKey, ownProps.layerKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
