// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import {ButtonSwitch, ButtonSwitchOption, Icon} from '@gisatcz/ptr-atoms';
import LabeledItem from '../../LabeledItem';

import './style.scss';

const MapLayoutControl = ({mapsCount, onActiveOptionChange, mode}) => {
	return (
		<LabeledItem label="Maps">
			<ButtonSwitch small ghost onClick={onActiveOptionChange}>
				<ButtonSwitchOption
					key={'one'}
					value={1}
					active={mapsCount === 1}
					disabled={mode === 'compare'}
					icon="ri-square-filled"
					className="ptr-MapLayoutControl-button"
				/>
				<ButtonSwitchOption
					key={'two'}
					value={2}
					active={mapsCount === 2}
					disabled={mode === 'compare'}
					className="ptr-MapLayoutControl-button"
				>
					<Icon icon="ri-square-filled" />
					<Icon icon="ri-square-filled" />
				</ButtonSwitchOption>
			</ButtonSwitch>
		</LabeledItem>
	);
};

MapLayoutControl.propTypes = {
	onActiveOptionChange: PropTypes.func,
	mapsCount: PropTypes.number,
	mode: PropTypes.string,
};

export default MapLayoutControl;
