import PropTypes from 'prop-types';
import DatasetLayerControls from '../DatasetLayerControls/';
import DataLayersGroup from '../components/DataLayersGroup';

const IndicatorLayersControl = ({mapKey, globalDatasets, localDatasets}) => {
	return (
		<DataLayersGroup title={'Indicator layers'}>
			<DataLayersGroup title={'Global data'}>
				{globalDatasets.map(dataset => {
					return (
						<DatasetLayerControls
							key={dataset.key}
							datasetKey={dataset.key}
							title={dataset.data.nameDisplay}
							attributes={dataset.data.attributes}
							mapKey={mapKey}
						/>
					);
				})}
			</DataLayersGroup>
			<DataLayersGroup title={'Future global data / 4 countries [FIXME]'}>
				{localDatasets.map(dataset => {
					return (
						<DatasetLayerControls
							key={dataset.data.nameDisplay}
							datasetKey={dataset.key}
							title={dataset.data.nameDisplay}
							attributes={dataset.data.attributes}
							mapKey={mapKey}
						/>
					);
				})}
			</DataLayersGroup>
		</DataLayersGroup>
	);
};

IndicatorLayersControl.propTypes = {
	mapKey: PropTypes.string,
	globalDatasets: PropTypes.array,
	localDatasets: PropTypes.array,
};

export default IndicatorLayersControl;
