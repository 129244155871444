import './style.scss';
import PropTypes from 'prop-types';
import {uniq as _uniq, compact as _compact} from 'lodash';
// import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import TimeRangeChart from '../../../TimeRangeChart';
import LineChart from '../../../LineChart';
import {useEffect, useState} from 'react';
import ChartWrapper from '../../ChartWrapper';
import NivoChartHoverWrapper from '../../../NivoChartHover/NivoChartHoverWrapper';
import moment from 'moment/moment';

const getTooltipContent = ({points}) => {
	if (points?.length) {
		return (
			<div className="unhab-TimeProgressChart-tooltip">
				<div className="unhab-TimeProgressChart-tooltip-content">
					<span className="unhab-TimeProgressChart-tooltip-time">
						{moment(points?.[0]?.data?.x).format('YYYY')}
					</span>
					{points.map(point => {
						return (
							<div
								className="unhab-TimeProgressChart-tooltip-item"
								key={point?.id}
							>
								<span
									className="unhab-TimeProgressChart-tooltip-color"
									style={{
										background: point?.color,
									}}
								/>
								<span className="unhab-TimeProgressChart-tooltip-value">
									{point?.data?.y?.toLocaleString(undefined, {
										maximumFractionDigits: 2,
									})}
								</span>
							</div>
						);
					})}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

const filterDataByAttribute = (data, attribute) => {
	if (data?.length && attribute) {
		return data?.filter(item => item.attribute === attribute);
	} else {
		return data;
	}
};

const TimeProgressChart = ({
	data,
	title,
	subtitle,
	configuration,
	multipleFeatures,
	currentDateRange,
}) => {
	const [dateRange, setDateRange] = useState(null);
	// const [attributeOptions, setAttributeOptions] = useState([]);
	const [activeAttributeOption, setActiveAttributeOption] = useState(null);

	// adjust chart date range by selected period
	useEffect(() => {
		if (currentDateRange) {
			setDateRange(currentDateRange);
		}
	}, [currentDateRange]);

	useEffect(() => {
		if (data?.length) {
			const attributes = _uniq(_compact(data.map(item => item.attribute)));
			// setAttributeOptions(attributes);
			setActiveAttributeOption(attributes[0]);
		}
	}, [data]);

	const filteredData = filterDataByAttribute(data, activeAttributeOption);

	return filteredData ? (
		<ChartWrapper title={title} subtitle={subtitle} data={filteredData}>
			{/*{attributeOptions.length ? (*/}
			{/*	<ButtonSwitch*/}
			{/*		className="unhab-TimeProgressChart-attributeSwitch"*/}
			{/*		small*/}
			{/*		ghost*/}
			{/*		onClick={setActiveAttributeOption}*/}
			{/*	>*/}
			{/*		{attributeOptions.map(option => (*/}
			{/*			<ButtonSwitchOption*/}
			{/*				key={option}*/}
			{/*				value={option}*/}
			{/*				active={option === activeAttributeOption}*/}
			{/*			>*/}
			{/*				{option}*/}
			{/*			</ButtonSwitchOption>*/}
			{/*		))}*/}
			{/*	</ButtonSwitch>*/}
			{/*) : null}*/}
			<div className="unhab-TimeProgressChart">
				<NivoChartHoverWrapper
					getHoverContent={props => {
						return getTooltipContent(props);
					}}
					tooltipPosition="corner"
					wrapperRefQuery={
						multipleFeatures
							? 'svg>g>g:nth-of-type(1)'
							: 'svg>g>g:nth-of-type(4)'
					}
				>
					<TimeRangeChart
						ChartComponent={LineChart}
						data={filteredData}
						metadata={configuration.metadata}
						overviewChartMetadata={configuration.overviewChartMetadata}
						onHover={() => {}}
						dateRange={dateRange}
						onDateRangeChange={setDateRange}
						displayTimeFormat={configuration.displayTimeFormat}
						hideLegend={configuration.hideLegend}
					/>
				</NivoChartHoverWrapper>
			</div>
		</ChartWrapper>
	) : null;
};

TimeProgressChart.propTypes = {
	data: PropTypes.array,
	configuration: PropTypes.object,
	currentDateRange: PropTypes.array,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	multipleFeatures: PropTypes.bool,
};

export default TimeProgressChart;
