import {auSelectionKey, distinctColours} from '../../constants/app';

export default {
	key: auSelectionKey,
	data: {
		colourPalette: distinctColours,
		distinctItems: true,
		featureKeysFilter: {
			keyColourIndexPairs: {65: 0},
			keys: [65],
		},
		style: {
			outlineWidth: 3,
			outlineColor: '#00FF00',
		},
	},
};
