import SidePanel, {
	SidePanelBody,
	SidePanelHeader,
	SidePanelHeaderItem,
} from '../../../common/SidePanel';
import './style.scss';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import SelectedFeaturesControl from '../../../common/SelectedFeaturesControl';
import XCubeSelectedFeatureLabel from './XCubeSelectedFeatureLabel';
import XCubeProgressChart from './XCubeProgressChart';
import xCubeProgressChart from '../../../../data/charts/xCubeProgressChart';
import XCubePointsControl from './XCubePointsControl';

const XCubePanel = ({
	defaultOpen,
	open,
	setOpen,
	selectedFeatureKeys,
	onUnmount,
	allFeatures,
}) => {
	useEffect(() => {
		if (typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	useEffect(() => {
		if (setOpen && defaultOpen) {
			setOpen(true);
		}
	}, []);

	useEffect(() => {
		if (!open && selectedFeatureKeys) {
			setOpen(true);
		}
	}, [selectedFeatureKeys]);

	const pointsActive = allFeatures.find(
		feature => feature?.properties?.address === 'Point',
	);
	const selectionActive = selectedFeatureKeys?.length;

	return selectionActive || pointsActive ? (
		<SidePanel open={open} setOpen={setOpen} className="unhab-XCubePanel">
			<SidePanelHeader>
				{pointsActive ? <XCubePointsControl /> : null}
				{selectionActive ? (
					<SidePanelHeaderItem title="Selected areas & points">
						<SelectedFeaturesControl
							showAddButton={false}
							FeatureLabel={XCubeSelectedFeatureLabel}
						/>
					</SidePanelHeaderItem>
				) : null}
			</SidePanelHeader>
			<SidePanelBody>
				<XCubeProgressChart {...xCubeProgressChart} />
			</SidePanelBody>
		</SidePanel>
	) : null;
};

XCubePanel.propTypes = {
	onUnmount: PropTypes.func,
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	defaultOpen: PropTypes.bool,
	selectedFeatureKeys: PropTypes.array,
	allFeatures: PropTypes.array,
};

export default XCubePanel;
