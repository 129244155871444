import PropTypes from 'prop-types';
import MapConfigurationControl from '../MapConfigurationControl';
import MapLegend from '../MapLegend';
import './style.scss';

const MapLegendControl = ({
	horizontalPosition,
	mapKey,
	verticalPosition,
	slideoverOn,
	noLayers,
}) => {
	return !noLayers ? (
		<MapConfigurationControl
			icon="ri-info"
			name="Legend"
			horizontalPosition={
				slideoverOn
					? horizontalPosition === 'right'
						? 'left'
						: 'right'
					: horizontalPosition
			}
			verticalPosition={verticalPosition}
			closeContentOnFocusOut={false}
		>
			<MapLegend mapKey={mapKey} />
		</MapConfigurationControl>
	) : null;
};

MapLegendControl.propTypes = {
	horizontalPosition: PropTypes.string,
	mapKey: PropTypes.string,
	slideoverOn: PropTypes.bool,
	verticalPosition: PropTypes.string,
	noLayers: PropTypes.bool,
};

export default MapLegendControl;
