import Header from '../../common/Header';
import AppContent from '../../common/AppContent';
import Map from '../../common/Map';
import AnalyticsPanel from './AnalyticsPanel';

import './style.scss';

const Explorer = () => {
	return (
		<>
			<Header />
			<AppContent>
				<Map />
				<AnalyticsPanel
				//defaultOpen
				/>
			</AppContent>
		</>
	);
};

Explorer.propTypes = {};

export default Explorer;
