export default {
	title: 'Land consumption rate',
	configuration: {
		valuesFormat: {
			multiplier: 100,
		},
		attributes: [
			'ca15b319-d332-49ca-870c-b9240bd340a2',
			'c09bac02-f276-4ea4-a5f6-e090a8313454',
		],
		type: 'growthRate',
	},
};
