import {Action as CommonAction} from '@gisatcz/ptr-state';
import {map} from '@gisatcz/ptr-utils';
import ActionTypes from '../../../constants/ActionTypes';
import unhabUtils from '../../../utils';
import config from '../../../config/';
import Select from '../../Select';

// App specific actions
const actionAdd = structure => {
	return {
		type: ActionTypes.UNHAB.ADMSTRUCTURE.ADD,
		structure,
	};
};

function ensureAreas() {
	return (dispatch, getState) => {
		const admStructure = Select.unhab.admStructure.getAdmStructure(getState());

		if (!admStructure) {
			const url = new URL(config.admStructureUrl);

			return unhabUtils
				.request(url, 'GET')
				.then(data => {
					if (data) {
						dispatch(actionAdd(data));
					}
				})
				.catch(err => new Error(err));
		}
	};
}

/**
 * Set zoom level for map key of given features
 * @param {Object} features Features whitch will be used for calculation of new extent
 * @param {string} mapKey MapKey which will get new extent (calculated zoom level)
 * @returns
 */
function fitMapToFeatures(features, mapKey) {
	return dispatch => {
		const extents = [...features.map(a => a.data.extent)];

		const combinedExtents = unhabUtils.extentOfExtents(extents);

		const view = map.view.getViewFromBoundingBox(combinedExtents, true);

		// const mapSetKey = Select.maps.getActiveSetKey(state);
		// dispatch(CommonAction.maps.updateSetView(mapSetKey, view));
		return dispatch(CommonAction.maps.updateMapAndSetView(mapKey, view));
	};
}

/**
 * Filter features by level
 * @param {Object} state
 * @param {Array.<string>} features Array of featureKeys
 * @param {number} level
 * @returns
 */
function filterFeaturesByLevel(state, features, level) {
	return features
		.map(f => Select.unhab.admStructure.getAdmByKey(state, f))
		.filter(a => unhabUtils.getAdmLevelByKey(a.key) === level);
}

/**
 * Fit all maps in active mapSet to administration feature
 * @param {string} admKey
 * @returns
 */
function fitActiveMapSetToAdmKey(admKey) {
	return (dispatch, getState) => {
		const state = getState();
		const activeMapSetKey = Select.maps.getActiveSetKey(state);
		const mapSet = Select.maps.getMapSetByKey(state, activeMapSetKey);

		const admFeature = Select.unhab.admStructure.getAdmByKey(state, admKey);
		if (admFeature && activeMapSetKey) {
			dispatch(fitMapToFeatures([admFeature], mapSet?.maps[0]));

			if (mapSet?.maps[1]) {
				dispatch(fitMapToFeatures([admFeature], mapSet?.maps[1]));
			}
		}
	};
}
/**
 * Compare current maps with newly selected feature and set correct zoom for maps.
 * @param {Array.<string>} selected Keys of already selected features xxx or xxx_yyy
 * @param {string} newToSelection key of newly selected feature
 * @returns
 */
function fitZoomToSelected(selected, newToSelection) {
	return (dispatch, getState) => {
		const state = getState();

		const activeMapSetKey = Select.maps.getActiveSetKey(state);
		const mapSet = Select.maps.getMapSetByKey(state, activeMapSetKey);
		const mapsCount = mapSet?.maps?.length ? mapSet?.maps?.length : 0;
		const linked = (mapSet?.sync?.boxRange && mapSet?.sync?.center) || false;
		const map1level = Select.unhab.getMapLevelByMapKey(state, mapSet?.maps[0]);
		const map2level = mapSet?.maps[1]
			? Select.unhab.getMapLevelByMapKey(state, mapSet?.maps[1])
			: null;
		const newSelectionAdmLevel = unhabUtils.getAdmLevelByKey(newToSelection);
		const newToSelectionAdm = Select.unhab.admStructure.getAdmByKey(
			state,
			newToSelection,
		);
		const mapsHasSameLevel = map1level.data.level === map2level?.data?.level;
		//only for one map
		if (mapsCount === 1) {
			// new feature is same level as map level
			if (newSelectionAdmLevel === map1level.data.level) {
				const featuresWithLevelLikeMap1 = filterFeaturesByLevel(
					state,
					selected,
					map1level.data.level,
				);

				const features = [...featuresWithLevelLikeMap1, newToSelectionAdm];

				return dispatch(fitMapToFeatures(features, mapSet?.maps[0]));
			}
		} else {
			//only for two maps

			//only for linked maps
			if (linked) {
				//maps display same level
				if (mapsHasSameLevel) {
					//set zoom on only features with same level like map level
					const featuresWithLevelLikeMap1 = filterFeaturesByLevel(
						state,
						selected,
						map1level.data.level,
					);

					const features = [...featuresWithLevelLikeMap1, newToSelectionAdm];

					return dispatch(fitMapToFeatures(features, mapSet?.maps[0]));
				} else {
					// each map has different level
					// zoom on every features
					const features = [
						...selected.map(f =>
							Select.unhab.admStructure.getAdmByKey(state, f),
						),
						newToSelectionAdm,
					];
					return dispatch(fitMapToFeatures(features, mapSet?.maps[0]));
				}
			} else {
				//maps are not linked

				//both maps has same level and new feature has same level like map
				if (mapsHasSameLevel && newSelectionAdmLevel === map1level.data.level) {
					const featuresWithLevelLikeMap1 = filterFeaturesByLevel(
						state,
						selected,
						map1level.data.level,
					);

					const features = [...featuresWithLevelLikeMap1, newToSelectionAdm];

					dispatch(fitMapToFeatures(features, mapSet?.maps[0]));
					dispatch(fitMapToFeatures(features, mapSet?.maps[1]));
				} else if (!mapsHasSameLevel) {
					// maps are not linked and has different levels
					mapSet?.maps.forEach(mapKey => {
						const mapLevel = Select.unhab.getMapLevelByMapKey(state, mapKey);
						//set zoom only for map with same level like new feature
						if (mapLevel.data.level === newSelectionAdmLevel) {
							//set zoom on only features with same level like map level
							const featuresWithLevelLikeMap = filterFeaturesByLevel(
								state,
								selected,
								mapLevel.data.level,
							);

							const features = [...featuresWithLevelLikeMap, newToSelectionAdm];

							return dispatch(fitMapToFeatures(features, mapKey));
						}
					});
					newSelectionAdmLevel;
				}
			}
		}
	};
}

export default {
	ensureAreas,
	fitZoomToSelected,
	fitMapToFeatures,
	fitActiveMapSetToAdmKey,
};
