import {auSelectionKey, benchmarkSelectionKey} from '../../constants/app';

export default {
	border_outlines: {
		nameDisplay: 'Administrative boundaries',
		key: 'border_outlines',
		filterByActive: {
			application: true,
			areaTreeLevel: true,
		},
		options: {
			// selectable: true,
			selected: {
				[auSelectionKey]: {},
				[benchmarkSelectionKey]: {},
			},
			customLegend: {
				attributeValues: {
					'Featured areas': {
						color: '#32516b',
						outlineWidth: 2,
					},
				},
			},
			style: {
				rules: [
					{
						styles: [
							{
								outlineColor: '#888888',
							},
							{
								attributeKey: 'ADM0_CODE',
								attributeValues: {
									33: {
										outlineColor: '#32516b',
										outlineWidth: 2,
									},
									51: {
										outlineColor: '#32516b',
										outlineWidth: 2,
									},
									133: {
										outlineColor: '#32516b',
										outlineWidth: 2,
									},
									171: {
										outlineColor: '#32516b',
										outlineWidth: 2,
									},
								},
							},
						],
					},
				],
			},
		},
	},
};
