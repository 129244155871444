import PropTypes from 'prop-types';
import './style.scss';

const IndicatorBoxSet = ({children}) => {
	return <div className="ptr-IndicatorBoxSet">{children}</div>;
};

IndicatorBoxSet.propTypes = {
	children: PropTypes.node,
};

export default IndicatorBoxSet;
