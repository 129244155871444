import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';
import PropTypes from 'prop-types';
import Action from '../../../../../state/Action';
import {useEffect} from 'react';

const mapStateToProps = state => {
	return {
		models: Select.unhab.datasets.getActiveModels(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => dispatch(Action.unhab.datasets.add()),
	};
};

const BenchmarkHeaderDatasets = ({models, onMount}) => {
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	const names = models?.map(model => model?.data?.nameDisplay);

	return names?.length ? names.join(', ') : '';
};

BenchmarkHeaderDatasets.propTypes = {
	models: PropTypes.array,
	onMount: PropTypes.func,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BenchmarkHeaderDatasets);
