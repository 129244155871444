import {benchmarkSelectionKey, distinctColours} from '../../constants/app';

export default {
	key: benchmarkSelectionKey,
	data: {
		colourPalette: distinctColours,
		distinctItems: true,
		featureKeysFilter: {
			keyColourIndexPairs: {'133_51331': 0, '133_51327': 1},
			keys: ['133_51331', '133_51327'],
		},
		style: {
			outlineWidth: 3,
		},
	},
};
