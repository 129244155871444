import PropTypes from 'prop-types';
import AbsoluteAttribute from '../../../../Indicators/Indicator/AttributeSets/AttributeSet/Attribute/AbsoluteAttribute';
import RelativeAttribute from '../../../../Indicators/Indicator/AttributeSets/AttributeSet/Attribute/RelativeAttribute';
import './style.scss';

const IndicatorLayerTooltip = ({
	attributeValue,
	activeAreaTreeLevelName,
	ADM0_NAME,
	ADM1_NAME,
	relativeAttribute,
	absoluteValue,
	absoluteAttribute,
}) => {
	return (
		<>
			<div className="unhab-MapTooltip">
				<div className="unhab-MapTooltip-title">
					{activeAreaTreeLevelName !== 'Countries' ? ADM1_NAME : ADM0_NAME}
				</div>
				{activeAreaTreeLevelName !== 'Countries' ? (
					<div className="unhab-MapTooltip-subtitle">{ADM0_NAME}</div>
				) : null}
				<div className="unhab-MapTooltip-attributeSet">
					{relativeAttribute?.data?.nameDisplay}
					<AbsoluteAttribute
						value={absoluteValue}
						unit={absoluteAttribute?.data?.unit}
					/>
					<RelativeAttribute
						value={attributeValue}
						unit={relativeAttribute?.data?.unit}
					/>
				</div>
			</div>
		</>

		// 			<span>{activeIndicator?.data?.name}: </span>
		// 			{activeAttributeSet?.data?.name}
		// 		</div>
		// 		<div className="unhab-MapTooltip-attributeSetValues">
		// 			{yearAttributeValue ? (
		// 				<div className="unhab-MapTooltip-year">
		// 					{yearAttributeValue}:
		// 				</div>
		// 			) : null}
		// 			<AbsoluteAttribute
		// 				value={absAttributeValue}
		// 				unit={absAttributeUnit}
		// 			/>
		// 			<RelativeAttribute
		// 				value={relAttributeValue}
		// 				unit={relAttributeUnit}
		// 			/>
		// 		</div>
		// 	</div>
		// </>
	);
};

IndicatorLayerTooltip.propTypes = {
	attributeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	absoluteValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	attributeKey: PropTypes.string,
	ADM0_NAME: PropTypes.string,
	ADM1_NAME: PropTypes.string,
	activeAreaTreeLevelName: PropTypes.string,
	relativeAttribute: PropTypes.object,
	absoluteAttribute: PropTypes.object,
};

export default IndicatorLayerTooltip;
