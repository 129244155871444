import {Action as CommonAction} from '@gisatcz/ptr-state';
import Select from '../Select';
import utils from '../../utils';
import ActionTypes from '../../constants/ActionTypes';
import featuresActions from '../xCubeFeatures/actions';
import timeSeriesActions from '../xCubeTimeSeries/actions';
import {
	alphabet,
	limassolAreasFidColumnName,
	limassolAreasLayerKey,
	limassolAreasNameColumnName,
} from '../../constants/app';

function use() {
	return dispatch => {
		dispatch(ensure());
	};
}

function useColorBars() {
	return dispatch => {
		dispatch(ensureColorBars());
	};
}

function ensure() {
	return (dispatch, getState) => {
		const localConfig = Select.app.getCompleteLocalConfiguration(getState());

		const url = new URL(
			'be-interface-xcube/query/cube-layers/rest',
			`${localConfig.apiBackendProtocol}://${localConfig.apiBackendHost}`,
		).toString();

		return utils
			.request(url, 'GET', null, null)
			.then(data => {
				if (data) {
					dispatch(add(data));
				}
			})
			.catch(err => new Error(err));
	};
}

function ensureColorBars() {
	return (dispatch, getState) => {
		const localConfig = Select.app.getCompleteLocalConfiguration(getState());
		const url = `${localConfig.apiBackendProtocol}://${localConfig.apiBackendHost}/be-interface-xcube/query/cube-colorbar`;
		return utils
			.request(url, 'GET', null, null)
			.then(data => {
				if (data?.length) {
					dispatch(addColorBars(data));
				}
			})
			.catch(err => new Error(err));
	};
}

function add(data) {
	return dispatch => {
		const models = data.map(item => {
			const {
				nameDisplay,
				nameInternal,
				description,
				urlTemplateRest,
				timeDimensions,
				colorBar,
			} = item;
			const name = description.split(' - ');
			const [groupKey, variable] = nameDisplay.split(' - ');
			const group = name?.[0];
			const title = name?.[1];

			// TODO API structure may change
			let url = `${urlTemplateRest}?crs=EPSG:3857`;
			if (colorBar) {
				const {min, max, name} = colorBar;
				const time = timeDimensions?.[timeDimensions?.length - 1];
				if (name) {
					url = `${url}&cbar=${name}`;
				}
				if (min || min === 0) {
					url = `${url}&vmin=${min}`;
				}
				if (max || max === 0) {
					url = `${url}&vmax=${max}`;
				}
				if (time) {
					url = `${url}&time=${time}`;
				}
			}

			return {
				key: nameInternal,
				layerKey: nameInternal,
				metadata: {
					group,
					groupKey,
					variable,
					layerTemplate: {
						data: {
							nameDisplay: title || nameDisplay,
							nameInternal,
						},
					},
					legend: {
						name: colorBar.name,
						min: colorBar.min,
						max: colorBar.max,
					},
				},
				type: 'wmts',
				legendType: 'xcube',
				times: timeDimensions,
				radioGroup: 'xcube',
				options: {
					url,
				},
			};
		});

		dispatch(actionAdd(models));
	};
}

function addColorBars(data) {
	return dispatch => {
		// TODO API may change
		const colorBars = {};
		const groups = [];
		data.map(group => {
			const [name, description, bars] = group;
			if (bars) {
				const barNames = [];
				bars.map(bar => {
					const [name, image] = bar;
					colorBars[name] = {
						name,
						image,
					};
					barNames.push(name);
				});

				let type = 'range';
				if (name === 'Qualitative') {
					type = 'classes';
				}
				groups.push({name, description, colorBars: barNames, type});
			}
		});

		dispatch(actionAddColorBars(colorBars, groups));
	};
}

function handleMapClick(mapKey, coordinates) {
	return (dispatch, getState) => {
		if (coordinates) {
			const isActive = Select.components.get(
				getState(),
				'AddPointControl',
				'active',
			);

			// If tool is active
			if (isActive) {
				const currentFeatureKeyIndex =
					Select.xCubeFeatures.getCurrentPointFeatureIndex(getState());
				// prepare point
				const featureKey = alphabet[currentFeatureKeyIndex];
				const point = {
					type: 'Feature',
					properties: {OBJECTID: featureKey, address: `Point`, point: 1},
					geometry: {
						coordinates,
						type: 'Point',
					},
				};

				const previousLayerState =
					Select.maps.getMapLayerStateByMapKeyAndLayerKey(
						getState(),
						mapKey,
						limassolAreasLayerKey,
					);

				// If there is layer for adding active
				if (previousLayerState) {
					// Add point to feature store
					dispatch(featuresActions.addAsObject([point], 'OBJECTID'));

					// Update map layer
					const nextFeatures = [...previousLayerState.options.features, point];
					dispatch(
						CommonAction.maps.setMapLayerOption(
							mapKey,
							limassolAreasLayerKey,
							'features',
							nextFeatures,
						),
					);

					// Update selection
					const previousSelectedFeatureKeys = Select.selections.getActive(
						getState(),
					)?.data?.featureKeysFilter?.keys;
					const nextSelectedFeatureKeys = previousSelectedFeatureKeys?.length
						? [...previousSelectedFeatureKeys, featureKey]
						: [featureKey];
					dispatch(
						CommonAction.selections.setActiveSelectionFeatureKeysFilterKeys(
							nextSelectedFeatureKeys,
						),
					);

					// Load time serie
					dispatch(timeSeriesActions.load(mapKey, point));

					// Increment index
					dispatch(featuresActions.incrementIndex());
				}
			}
		}
	};
}

const onLayerChange = (type, data, mapKey, timeout) => {
	return (dispatch, getState) => {
		const state = getState();

		const activeLayers =
			Select.maps.getMapLayersStateByMapKey(state, mapKey) || [];

		return new Promise(resolve => {
			if (type === 'radio') {
				// if radio, then remove layer from same group first
				let layerFromSameGroupIndex =
					activeLayers?.length && activeLayers?.length - 1;
				if (activeLayers?.length) {
					const group = data?.radioGroup;
					if (group) {
						const layerFromSameGroup = activeLayers?.find(
							layer => layer.radioGroup === data?.radioGroup,
						);
						layerFromSameGroupIndex = activeLayers?.findIndex(
							layer => layer.radioGroup === data?.radioGroup,
						);
						if (layerFromSameGroup) {
							dispatch(
								CommonAction.maps.removeMapLayer(
									mapKey,
									layerFromSameGroup.key,
								),
							);
						}
					}
				}
				if (layerFromSameGroupIndex > -1) {
					dispatch(
						CommonAction.maps.addMapLayerToIndex(
							mapKey,
							data,
							layerFromSameGroupIndex,
						),
					);
				} else {
					dispatch(CommonAction.maps.addMapLayers(mapKey, [data]));
				}
				resolve();
			} else {
				dispatch(CommonAction.maps.addMapLayers(mapKey, [data]));
				// TODO
				// Data dont render because renderLayers inside cogLayer is executed twice.
				if (timeout) {
					setTimeout(() => {
						if (data?.layerTemplateKey) {
							dispatch(
								CommonAction.maps.removeMapLayersByLayerTemplateKey(
									mapKey,
									data.layerTemplateKey,
								),
							);
						} else {
							dispatch(CommonAction.maps.removeMapLayer(mapKey, data.key));
						}
						setTimeout(() => {
							dispatch(CommonAction.maps.addMapLayers(mapKey, [data]));
							resolve();
						}, timeout);
					}, timeout);
				}
			}
		});
	};
};
const removeLayer = (layerTemplateKey, layerKey, mapKey) => {
	return dispatch => {
		if (layerTemplateKey) {
			dispatch(
				CommonAction.maps.removeMapLayersByLayerTemplateKey(
					mapKey,
					layerTemplateKey,
				),
			);
		} else {
			dispatch(CommonAction.maps.removeMapLayer(mapKey, layerKey));
		}
	};
};

function changeMapLayerTime(mapKey, layerKey, time) {
	return (dispatch, getState) => {
		const layer = Select.maps.getMapLayerStateByMapKeyAndLayerKey(
			getState(),
			mapKey,
			layerKey,
		);
		const oldUrl = layer?.options?.url;
		if (oldUrl) {
			const url = utils.updateQueryParam(oldUrl, 'time', time);
			const updatedLayer = {
				...layer,
				options: {
					...layer.options,
					url,
				},
			};

			dispatch(onLayerChange('radio', updatedLayer, mapKey));
		}
	};
}

const deleteFeature = (mapKey, featureKey) => {
	return (dispatch, getState) => {
		const previousLayerState = Select.maps.getMapLayerStateByMapKeyAndLayerKey(
			getState(),
			mapKey,
			limassolAreasLayerKey,
		);

		const activeSelectionFeatureKeys = Select.selections.getActive(getState())
			?.data?.featureKeysFilter?.keys;

		if (activeSelectionFeatureKeys) {
			let updatedSelectionKeys = [];

			if (featureKey) {
				updatedSelectionKeys = activeSelectionFeatureKeys.filter(
					key => key !== featureKey,
				);
			} else {
				updatedSelectionKeys = activeSelectionFeatureKeys.filter(
					key => typeof key !== 'string',
				);
			}

			dispatch(
				CommonAction.selections.setActiveSelectionFeatureKeysFilterKeys(
					updatedSelectionKeys,
				),
			);
		}

		if (previousLayerState) {
			let nextFeatures = [];

			if (featureKey) {
				nextFeatures = previousLayerState.options.features.filter(
					feature =>
						feature?.properties?.[limassolAreasFidColumnName] !== featureKey,
				);
			} else {
				nextFeatures = previousLayerState.options.features.filter(
					feature =>
						feature?.properties?.[limassolAreasNameColumnName] !== 'Point',
				);
			}

			dispatch(
				CommonAction.maps.setMapLayerOption(
					mapKey,
					limassolAreasLayerKey,
					'features',
					nextFeatures,
				),
			);

			dispatch(featuresActions.update(nextFeatures, 'OBJECTID'));
		}
	};
};

const actionAdd = data => {
	return {
		type: ActionTypes.XCUBE_LAYERS.ADD,
		data,
	};
};

const actionAddColorBars = (colorBars, groups) => {
	return {
		type: ActionTypes.XCUBE_LAYERS.ADD_COLORBARS,
		colorBars,
		groups,
	};
};

export default {
	add,
	changeMapLayerTime,
	handleMapClick,
	use,
	useColorBars,
	onLayerChange,
	removeLayer,
	deleteFeature,
};
