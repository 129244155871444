import Action from '../../Action';
import Select from '../../Select';
import {countryLevel, regionLevel} from '../../../constants/app';
import features from '../../../utils/features';

/**
 * Apply benchmark configuration
 * @param comparisonTypeKey {'datasets' | 'areas'}
 * @param areaKeys {Array} List of area keys
 * @param datasetKeys {Array} List of dataset keys
 * @param datasetSetKey {string} Dataset set key
 * @param period {string} Period in YYYY/YYYY format
 */
function set(comparisonTypeKey, areaKeys, datasetKeys, datasetSetKey, period) {
	return (dispatch, getState) => {
		const state = getState();
		const mapSetKey = Select.maps.getActiveSetKey(state);
		const maps = Select.maps.getMapSetMapKeys(state, mapSetKey);
		const featureKey1 = features.getKeyFormatted(areaKeys[0]);
		const feature1 = Select.unhab.admStructure.getAdmByKey(state, featureKey1);

		dispatch(
			Action.unhab.benchmarkComparisonTypes.setActiveKey(comparisonTypeKey),
		);
		dispatch(Action.unhab.setActivePeriod(period));

		// TODO enable picking from map
		if (comparisonTypeKey === 'datasets') {
			dispatch(
				Action.selections.setActiveSelectionFeatureKeysFilterKeys([
					featureKey1,
				]),
			);
			dispatch(Action.unhab.datasetSets.setActive(datasetSetKey));
			dispatch(Action.unhab.setMapSetSync(mapSetKey, true));
			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[0]));
			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[1]));
			dispatch(setMapLevelByFeature(feature1, maps[0]));
			dispatch(setMapLevelByFeature(feature1, maps[1]));
		} else {
			const featureKey2 = features.getKeyFormatted(areaKeys[1]);
			dispatch(
				Action.selections.setActiveSelectionFeatureKeysFilterKeys([
					featureKey1,
					featureKey2,
				]),
			);
			dispatch(Action.unhab.datasets.setActiveKeys(datasetKeys));
			dispatch(Action.unhab.setMapSetSync(mapSetKey, false));
			const feature2 = Select.unhab.admStructure.getAdmByKey(
				state,
				featureKey2,
			);
			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature1], maps[0]));
			dispatch(Action.unhab.admStructure.fitMapToFeatures([feature2], maps[1]));
			dispatch(setMapLevelByFeature(feature1, maps[0]));
			dispatch(setMapLevelByFeature(feature2, maps[1]));
		}
	};
}

function setMapLevelByFeature(feature, mapKey) {
	return (dispatch, getState) => {
		const state = getState();
		const map = Select.maps.getMapByKey(state, mapKey);
		const currentLevelKey = map?.data?.metadataModifiers?.areaTreeLevelKey;
		const areaLevels = Select.areas.areaTreeLevels.getAllAsObject(state);
		const level = areaLevels?.[currentLevelKey].data.level;
		const featureIsRegion = feature?.key?.toString().split('_')?.length === 2;
		if (level === 0 && featureIsRegion) {
			dispatch(
				Action.maps.setMapMetadataModifiers(mapKey, {
					areaTreeLevelKey: regionLevel,
				}),
			);
		} else if (level === 1 && !featureIsRegion) {
			dispatch(
				Action.maps.setMapMetadataModifiers(mapKey, {
					areaTreeLevelKey: countryLevel,
				}),
			);
		}
	};
}

// TODO get featureKeyFormatted global util function - for whatever reason the feature key in 123 must be a number

// helpers -------------------------------------------------------------------------------------

export default {
	set,
};
