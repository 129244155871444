import ActionTypes from '../../../constants/ActionTypes';
import {commonReducers} from '@gisatcz/ptr-state';

const INITIAL_STATE = {
	activeKey: 'areas',
	byKey: {
		areas: {
			key: 'areas',
			data: {
				nameDisplay: 'Areas',
			},
		},
		datasets: {
			key: 'datasets',
			data: {
				nameDisplay: 'Datasets',
			},
		},
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNHAB.BENCHMARK_COMPARISON_TYPES.SET_ACTIVE_KEY:
			return commonReducers.setActive(state, action);
		default:
			return state;
	}
};
