// import PropTypes from 'prop-types';
import './style.scss';
import PropTypes from 'prop-types';
import TimeProgressChart from './charts/TimeProgressChart';
import AreaShareChart from './charts/AreaShareChart';

const getChartByType = (type, props) => {
	switch (type) {
		case 'timeProgressChart':
			return <TimeProgressChart {...props} />;
		case 'areaShareChart':
			return <AreaShareChart {...props} />;
		default:
			return null;
	}
};

const Chart = ({type, ...props}) => {
	return getChartByType(type, props);
};

Chart.propTypes = {
	type: PropTypes.string,
};

export default Chart;
