import {createSelector} from 'reselect';
import {Select} from '@gisatcz/ptr-state';
import featuresSelectors from '../features/selectors';
import utils from '../../../utils';
import selectorHelpers from '../selectorHelpers';

/**
 * For now, just get normalization attribute columnName
 * @param configuration {Object} Chart configuration
 */
const getNormalizationDefinition = createSelector(
	[
		Select.attributes.getAllAsObject,
		(state, configuration) => configuration?.normalization,
	],
	(attributes, normalization) => {
		if (normalization && attributes?.[normalization.attribute]) {
			const attribute = attributes?.[normalization.attribute];
			return {
				...normalization,
				attribute: attribute?.data?.configuration?.columnName,
			};
		} else {
			return null;
		}
	},
);

// Charts selectors
const getTimeProgressData = createSelector(
	[
		featuresSelectors.getSelectedFeatures,
		Select.selections.getActive,
		selectorHelpers.getFilteredAttributesByActiveDatasets,
		getNormalizationDefinition,
	],
	(features, activeSelection, attributes, normalization) => {
		if (features?.length && attributes?.length) {
			const data = [];

			if (features?.length > 1) {
				features.forEach(feature => {
					const {data: properties} = feature;
					const normValue = selectorHelpers.getNormalizationAttributeValue(
						properties,
						normalization,
					);

					attributes.forEach(attribute => {
						const filteredDataAsObject =
							selectorHelpers.filterPropertiesByTemplate(
								properties,
								attribute.template,
							);
						const timeSerie = selectorHelpers.getFormattedTimeSerie(
							filteredDataAsObject,
							normValue,
						);
						data.push({
							id: feature?.key,
							name: selectorHelpers.getAreaName(properties),
							data: timeSerie,
							color: utils.getSelectedFeaturePrimaryColor(
								feature?.key,
								activeSelection?.data,
							),
							attribute: attribute?.name,
						});
					});
				});
				return data;
			} else if (attributes?.length === 1) {
				features.forEach(feature => {
					const {data: properties} = feature;
					const attribute = attributes[0];

					const normValue = selectorHelpers.getNormalizationAttributeValue(
						properties,
						normalization,
					);

					const filteredDataAsObject =
						selectorHelpers.filterPropertiesByTemplate(
							properties,
							attribute.template,
						);

					const timeSerie = selectorHelpers.getFormattedTimeSerie(
						filteredDataAsObject,
						normValue,
					);
					data.push({
						id: attribute.template,
						name: attribute.name,
						data: timeSerie,
						color: attribute.color,
					});
				});
				return data;
			} else if (features.length === 1) {
				const feature = features[0];
				const {data: properties} = feature;
				const normValue = selectorHelpers.getNormalizationAttributeValue(
					properties,
					normalization,
				);
				attributes.forEach(attribute => {
					const filteredDataAsObject =
						selectorHelpers.filterPropertiesByTemplate(
							properties,
							attribute.template,
						);
					const timeSerie = selectorHelpers.getFormattedTimeSerie(
						filteredDataAsObject,
						normValue,
					);
					data.push({
						id: attribute?.template,
						name: attribute?.name,
						data: timeSerie,
						color: attribute?.color,
					});
				});

				return data;
			}

			return null;
		} else {
			// TODO add another ways how to define attributes for time serie
			return null;
		}
	},
);

const getAreaShareData = createSelector(
	[
		featuresSelectors.getSelectedFeatures,
		Select.selections.getActive,
		selectorHelpers.getFilteredAttributesByActiveDatasets,
		getNormalizationDefinition,
	],
	(features, activeSelection, attributes, normalization) => {
		if (features?.length && attributes?.length) {
			const [themeAttribute] = attributes;
			const areaAttribute = normalization.attribute;
			if (themeAttribute && areaAttribute) {
				const data = [];
				features.forEach(feature => {
					const properties = feature.data;
					const themeValue = properties?.[themeAttribute.key];
					const areaValue = properties?.[areaAttribute];
					data.push({
						name: selectorHelpers.getAreaName(properties),
						share: themeValue / areaValue,
						data: [
							{
								id: themeAttribute.key,
								label: themeAttribute.name,
								originalValue: themeValue,
								value: themeValue,
								color: utils.getSelectedFeaturePrimaryColor(
									feature?.key,
									activeSelection?.data,
								),
							},
							{
								id: areaAttribute,
								label: areaAttribute,
								originalValue: areaValue - themeValue,
								value: areaValue - themeValue,
								color: '#bbbbbb',
							},
						],
					});
				});
				return data;
			} else {
				return null;
			}
		} else {
			return null;
		}
	},
);

export default {
	getTimeProgressData,
	getAreaShareData,
};
