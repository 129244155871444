export default {
	title: 'Minimal yearly increase',
	configuration: {
		attributes: [
			'6da76d87-977e-4b50-9bfa-3190c28f8a20', // Population evolution minimum, absolute
			'e1e76504-3a30-48fb-936b-1830557ad812',
		],
		type: 'minYearlyIncrease',
	},
};
