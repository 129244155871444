export const appKey = 'esaUtepUnHabitat';

export const gaId = window?.runtimeConfig?.googleAnalyticsKey;

// UNHAB
export const defaultViewKey = '6f59d02b-7055-4292-ba4a-939d616f9ea9';

export const defaultPeriodKey = 'b8de4ca6-b457-4649-b266-dcb9287e4a23';
export const defaultPeriod = '1985/2020';

export const featuredCountryKeys = [33, 51, 133, 171];

export const mapGlobalDatasets = [
	'ghsBuiltUpSurface',
	'wsfEvolution',
	'wsf2019',
	'ghsPopulationGrid',
];

export const mapLocalDatasets = ['wsf2019nextGen'];

export const statisticsGroupsAttributeSets = [
	'4c9aa8c4-3d5b-4fd2-acb7-38026e074d91',
	'59b06cf6-278f-4d4b-8de7-c929ba94ebef',
	'731cdec7-27cd-483e-895f-4eb6d3bf312f',
];

export const areaNameColumns = ['ADM1_NAME', 'ADM0_NAME'];

// uniqe key for only indicators layers
// used for indicator layer identification
export const unhabIndicatorLayersRadioGroupKey = 'unhab-indicator-layers';

// Explore
export const auSelectionKey = 'auSelection';

// Benchmark
export const benchmarkViewKey = 'a068c9c2-3dbf-4818-87e6-03418bc467a8';

export const benchmarkSelectionKey = 'benchmarkSelection';

// XCUBE --------------------------------------------------
export const xCubeViewKey = '439cfb29-adc1-487e-9d4f-ff5e87755b96';

export const limassolAreasLayerKey = 'publicGreenAreas';
export const limassolAreasSelectionKey = 'limassolAreasSelection';
export const limassolAreasFidColumnName = 'OBJECTID';
export const limassolAreasNameColumnName = 'address';

export const alphabet = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];

export const distinctColours = [
	'#42d4f4',
	'#e15dda',
	'#ffe119',
	'#aaffc3',
	'#9A6324',
	'#dcbeff',
	'#800000',
	'#fffac8',
	'#ff9ec1',
	'#f58231',
	'#11bda3',
	'#25b537',
	'#e6194B',
	'#911eb4',
	'#bfef45',
	'#808000',
	'#ffd8b1',
	'#4363d8',
	'#282892',
];

// Config fallbacks
export const defaultLinks = {
	visat: 'https://visat-dev.gisat.cz/',
	unhab: 'https://esautepunhabitat-dev.gisat.cz/',
	sdg: 'https://visat-sdg-dev.gisat.cz/',
	xcube: 'https://visat-dev.gisat.cz/',
};

export const countryLevel = 'a53a54dd-8a0b-4e28-b7aa-aa566cd2ba47';
export const regionLevel = 'b8a2f8d0-41b6-4afc-ba3f-a0df21632975';

export const defaultExploreAreaTreeLevelKey =
	'a53a54dd-8a0b-4e28-b7aa-aa566cd2ba47';
export const defaultBenchmarkAreaTreeLevelKey =
	'b8a2f8d0-41b6-4afc-ba3f-a0df21632975';

export const xCubeApp = false;
