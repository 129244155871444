import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import ComponentRenderer from '../../ComponentRenderer';

const LockMapsControl = ({maps, unlocked, lockMaps}) => {
	return (
		<ComponentRenderer
			component={'lockMaps'}
			configurationGroupKey={'mapSetTools'}
		>
			<Button
				primary={!unlocked}
				disabled={maps?.length !== 2}
				onClick={() => lockMaps(unlocked)}
				icon="ri-link"
				small
				floating
			>
				Link location
			</Button>
		</ComponentRenderer>
	);
};

LockMapsControl.propTypes = {
	maps: PropTypes.array,
	unlocked: PropTypes.bool,
	lockMaps: PropTypes.func,
};

export default LockMapsControl;
