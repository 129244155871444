import PropTypes from 'prop-types';
import './style.scss';

const FormItem = ({children}) => {
	return <div className="ptr-FormItem">{children}</div>;
};

FormItem.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FormItem;
