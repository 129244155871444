import PropTypes from 'prop-types';
import classnames from 'classnames';
import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';
import './style.scss';
import {Icon} from '@gisatcz/ptr-atoms';

const mapStateToProps = (state, ownProps) => {
	return {
		model: Select.unhab.datasets.getByKey(state, ownProps.datasetKey),
	};
};

const DatasetItemPresentation = ({model}) => {
	return <div className="unhab-DatasetItem">{model?.data?.nameDisplay}</div>;
};

DatasetItemPresentation.propTypes = {
	model: PropTypes.object,
};

const DatasetItem = connect(mapStateToProps)(DatasetItemPresentation);

const DatasetSetSelectorItem = ({
	datasetSetKey,
	datasets,
	active,
	onChange,
}) => {
	const classes = classnames('unhab-DatasetSetSelectorItem', {
		'is-active': active,
	});

	return (
		<div className={classes} onClick={() => onChange(datasetSetKey)}>
			{datasets?.map(datasetKey => (
				<DatasetItem key={datasetKey} datasetKey={datasetKey} />
			))}
			<div className="unhab-DatasetSetSelectorItem-icon">
				<Icon icon="ri-check" />
			</div>
		</div>
	);
};

DatasetSetSelectorItem.propTypes = {
	datasetSetKey: PropTypes.string,
	datasets: PropTypes.array,
	active: PropTypes.bool,
	onChange: PropTypes.func,
};

const DatasetSetSelector = ({models, activeKey, onChange}) => {
	return (
		<div className="unhab-DatasetSetSelector">
			{models?.map(model => (
				<DatasetSetSelectorItem
					key={model.key}
					datasets={model.data.datasets}
					datasetSetKey={model.key}
					active={model.key === activeKey}
					onChange={onChange}
				/>
			))}
		</div>
	);
};

DatasetSetSelector.propTypes = {
	models: PropTypes.array,
	activeKey: PropTypes.string,
	onChange: PropTypes.func,
};

export default DatasetSetSelector;
